import "aframe";
import { isMobileOrVR } from "./utils";

let DEFAULT_POSITION = { x: 0.9, y: 1.6, z: 1.3 };
let DEFAULT_ROTATION = {
  x: THREE.MathUtils.degToRad(-15),
  y: THREE.MathUtils.degToRad(40),
  z: THREE.MathUtils.degToRad(0),
};

AFRAME.registerComponent("gydence-controls", {
  dependencies: ["position", "rotation"],
  schema: {
    /*
			WASD: standard forward/left/backward/right movement
			QE: turn left/right
			ZX: move up/down
			CV: look up/down
      R: reset scene/animation
      Shift: speed up movement
      1: set camera to HMD
      2: set camera to editor default
		*/
    moveForwardKey: { type: "string", default: "W" },
    moveBackwardKey: { type: "string", default: "S" },
    moveLeftKey: { type: "string", default: "A" },
    moveRightKey: { type: "string", default: "D" },
    moveUpKey: { type: "string", default: "Z" },
    moveDownKey: { type: "string", default: "X" },
    turnLeftKey: { type: "string", default: "Q" },
    turnRightKey: { type: "string", default: "E" },
    lookUpKey: { type: "string", default: "C" },
    lookDownKey: { type: "string", default: "V" },
    slowDownKey: { type: "string", default: "Shift" },

    firstPersonKey: { type: "string", default: "1" },
    defaultCameraKey: { type: "string", default: "2" },
    reloadKey: { type: "string", default: "R" },

    moveSpeed: { type: "number", default: 4 }, // A-Frame units/second
    turnSpeed: { type: "number", default: 60 }, // degrees/second
    lookSpeed: { type: "number", default: 60 }, // degrees/second
    slowDownAmount: { type: "number", default: 0.25 },
  },

  updateTransform: function (position, rotation) {
    this.el.object3D.position.set(position.x, position.y, position.z);
    this.el.object3D.rotation.set(
      rotation.x,
      rotation.y,
      rotation.z,
      rotation.order
    );
  },

  init: function () {
    this.moveVector = new THREE.Vector3(0, 0, 0);
    this.movePercent = new THREE.Vector3(0, 0, 0);

    this.rotateVector = new THREE.Vector2(0, 0);
    this.rotatePercent = new THREE.Vector2(0, 0);

    this.upVector = new THREE.Vector3(0, 1, 0);

    this.turnAngle = 0;
    this.lookAngle = 0;

    if (!isMobileOrVR) {
      this.el.object3D.position.set(
        DEFAULT_POSITION.x,
        DEFAULT_POSITION.y,
        DEFAULT_POSITION.z
      );
      this.el.object3D.rotation.order = "YXZ";
      this.el.object3D.rotation.set(
        DEFAULT_ROTATION.x,
        DEFAULT_ROTATION.y,
        DEFAULT_ROTATION.z
      );
    }

    this.mode = "third";

    // Activate either the desktop or AR camera and remove the other
    this.configureCameras();
  },

  tick: function (time, timeDelta) {
    if (
      document.isKeyPressed(this.data.firstPersonKey) &&
      this.mode !== "first"
    ) {
      this.mode = "first";
      const targetEntity = document.querySelector("#person");
      let position = new THREE.Vector3();
      let rotation = new THREE.Quaternion();
      targetEntity.object3D.getWorldPosition(position);
      targetEntity.object3D.getWorldQuaternion(rotation);
      this.updateTransform(position, rotation);
      if (!isMobileOrVR) {
        targetEntity.setAttribute("parent", "camera");
      }
    } else if (
      document.isKeyPressed(this.data.defaultCameraKey) &&
      this.mode !== "third"
    ) {
      this.mode = "third";
      if (!isMobileOrVR) {
        const targetEntity = document.querySelector("#person");
        targetEntity.removeAttribute("parent");
      }
      this.updateTransform(DEFAULT_POSITION, DEFAULT_ROTATION);
    } else if (document.isKeyPressed(this.data.reloadKey)) {
      const event = new CustomEvent("resetScene", {});
      document.dispatchEvent(event);
    }

    let moveAmount = (timeDelta / 1000) * this.data.moveSpeed;
    let turnAmount =
      (timeDelta / 1000) * THREE.MathUtils.degToRad(this.data.turnSpeed);
    let lookAmount =
      (timeDelta / 1000) * THREE.MathUtils.degToRad(this.data.lookSpeed);

    if (document.isKeyPressed(this.data.slowDownKey)) {
      moveAmount *= this.data.slowDownAmount;
      lookAmount *= this.data.slowDownAmount;
      turnAmount *= this.data.slowDownAmount;
    }

    // Rotation
    this.lookAngle = 0;
    this.turnAngle = 0;

    this.rotatePercent.set(0, 0);

    if (document.isKeyPressed(this.data.lookUpKey)) {
      this.rotatePercent.x += 1;
    }
    if (document.isKeyPressed(this.data.lookDownKey)) {
      this.rotatePercent.x -= 1;
    }
    if (document.isKeyPressed(this.data.turnLeftKey)) {
      this.rotatePercent.y += 1;
    }
    if (document.isKeyPressed(this.data.turnRightKey)) {
      this.rotatePercent.y -= 1;
    }

    this.lookAngle += this.rotatePercent.x * lookAmount;
    this.el.object3D.rotation.x += this.lookAngle;
    this.turnAngle += this.rotatePercent.y * turnAmount;
    this.el.object3D.rotation.y += this.turnAngle;

    // Position
    const finalTurnAngle = this.el.object3D.rotation.y;
    const c = Math.cos(finalTurnAngle);
    const s = Math.sin(finalTurnAngle);

    this.movePercent.set(0, 0, 0);

    if (document.isKeyPressed(this.data.moveForwardKey)) {
      this.movePercent.z += 1;
    }
    if (document.isKeyPressed(this.data.moveBackwardKey)) {
      this.movePercent.z -= 1;
    }
    if (document.isKeyPressed(this.data.moveRightKey)) {
      this.movePercent.x += 1;
    }
    if (document.isKeyPressed(this.data.moveLeftKey)) {
      this.movePercent.x -= 1;
    }
    if (document.isKeyPressed(this.data.moveUpKey)) {
      this.movePercent.y += 1;
    }
    if (document.isKeyPressed(this.data.moveDownKey)) {
      this.movePercent.y -= 1;
    }

    // forward(z) direction: [ -s,  0, -c ]
    //   right(x) direction: [  c,  0, -s ]
    //      up(y) direction: [  0,  1,  0 ]
    // multiply each by (maximum) movement amount and percentages (how much to move in that direction)
    this.moveVector
      .set(
        -s * this.movePercent.z + c * this.movePercent.x,
        1 * this.movePercent.y,
        -c * this.movePercent.z - s * this.movePercent.x
      )
      .multiplyScalar(moveAmount);

    this.el.object3D.position.add(this.moveVector);
  },

  configureCameras: function () {
    // Initialize follow components
    const followEntities = document.querySelectorAll("[follow]");
    for (const entity of followEntities) {
      entity.components.follow.configure();
    }

    // Activate either the desktop or AR camera and remove the other
    // /Mobi|Android/i.test(navigator.userAgent)
    if (isMobileOrVR) {
      const entities = document.querySelectorAll(".removeOnMobile");
      for (const entity of entities) {
        entity.parentNode.removeChild(entity);
      }

      const stats = document.querySelector("[stats]");
      if (stats) {
        stats.removeAttribute("stats");
      }
    } else {
      const reflection = document.querySelector("[reflection]");
      if (reflection) {
        reflection.removeAttribute("reflection");
      }

      let imageTargets = document.querySelectorAll("[mindar-image-target]");
      for (const imageTarget of imageTargets) {
        imageTarget.removeAttribute("mindar-image-target");
        // FIXME: mindar modifies these in init and never undoes it
        imageTarget.object3D.visible = true;
        imageTarget.object3D.matrixAutoUpdate = true;
      }
    }

    const cameraToActivate = document.querySelector(
      isMobileOrVR ? "#ar-camera" : "#camera"
    );
    if (cameraToActivate) {
      cameraToActivate.setAttribute("camera", "active", true);
    }
    const cameraToRemove = document.querySelector(
      isMobileOrVR ? "#camera" : "#ar-camera"
    );
    if (cameraToRemove) {
      cameraToRemove.parentNode.removeChild(cameraToRemove);
    }

    // Remove the A-Frame injected camera, which will be at the end of the list
    const cameras = document.querySelectorAll("[camera]");
    if (cameras.length > 1) {
      const defaultCamera = cameras[cameras.length - 1];
      defaultCamera.parentNode.removeChild(defaultCamera);
    }

    const event = new CustomEvent("camerasConfigured", {});
    document.dispatchEvent(event);
  },
});
