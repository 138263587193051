import { useState, useEffect, useRef } from "react";

import { supabase } from "./signin";
import { ControlledInput, ControlledTextArea } from "../components/controlledInput";

import styles from "./index.module.css";

function UploadCheckbox({ id, name, checkMap, setCheckMap, onChange, ...rest }) {
  const key = id ?? name;
  return (
    <div
      {...rest}
    >
      <label
        className={styles.unselectable}
        htmlFor={name}
      >{name}</label>
      <input
        id={name}
        className={styles.unselectable}
        type="checkbox"
        checked={checkMap[key] ?? true}
        onChange={(e) => {
          let currMap = {...checkMap};
          currMap[key] = e.target.checked;
          if (onChange) {
            onChange(e.target.checked, currMap);
          }
          setCheckMap(currMap);
        }}
      ></input>
    </div>
  )
}

const uncheckHierarchy = (entity, entityParentMap, checkMap) => {
  const children = entityParentMap[entity];
  for (let child of children) {
    checkMap[child] = false;
    if (child in entityParentMap) {
      uncheckHierarchy(child, entityParentMap, checkMap);
    }
  }
};

function EntityList({ entity, entityPropertyMap, entityParentMap, checkMap, setCheckMap }) {
  return (
    <>
      {entityParentMap?.[entity]?.map((child) => {
        return (
          <div
            key={["div_", child].join("")}
            style={{
              paddingLeft: entity ? 10 : 0,
            }}>
            <UploadCheckbox
              id={child}
              name={entityPropertyMap?.[child]?.name ?? child}
              checkMap={checkMap}
              setCheckMap={setCheckMap}
              onChange={(checked, map) => {
                if (!checked && (child in entityParentMap)) {
                  uncheckHierarchy(child, entityParentMap, map);
                }
              }}
            />
            <EntityList
              key={child}
              entity={child}
              entityPropertyMap={entityPropertyMap}
              entityParentMap={entityParentMap}
              checkMap={checkMap}
              setCheckMap={setCheckMap}
            />
          </div>
        )
      })}
    </>
  );
}

function ItemList({ items, name, checkMap, setCheckMap, privateMap, setPrivateMap }) {
  return (
    <>
      <UploadCheckbox name={name} checkMap={checkMap} setCheckMap={setCheckMap} onChange={(checked, map) => {
        for (let item of items) {
          map[item.id] = checked;
        }
      }} />
      <div
        style={{
          paddingLeft: "10px"
        }}
      >
        {items.map((item) => {
          return <div
            key={"div" + item.id}
          >
            {
              privateMap ?
                <UploadCheckbox
                  style={{
                    float: "right",
                    marginRight: "300px"
                  }}
                  key={"private" + item.id}
                  id={item.id}
                  name={"Private?"}
                  checkMap={privateMap}
                  setCheckMap={setPrivateMap}
                />
              : <></>
            }
            <UploadCheckbox
              key={"upload" + item.id}
              id={item.id}
              name={item.name ?? item.id}
              checkMap={checkMap}
              setCheckMap={setCheckMap}
            />
          </div>
        })}
      </div>
    </>
  )
}

const modes = ["Entities", "Scripts", "Assets", "Apps", "Description"];
const privateModes = ["Scripts", "Apps"];

export default function UploadManager({ user, currentSite, setEditorModeHandler, setTargetListingHandler,
  refreshOwnedItemsHandler, scenePropertyMap, entityPropertyMap, entityParentMap, overlayElements,
  cssProps, scripts, apps, getPublicURL }) {
  const targetListingRef = useRef(undefined);

  const isChecked = (key) => {
    return !(key in checkMap) || checkMap[key];
  };

  const isPrivate = (key) => {
    return !(key in privateMap) || privateMap[key];
  };

  const [mode, setMode] = useState(modes[0]);

  const [assets, setAssets] = useState([]);
  const [listings, setListings] = useState([]);

  const NEW_LISTING_NAME = "New Listing";
  const [listingName, setListingName] = useState(NEW_LISTING_NAME);
  const [listingTags, setListingTags] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");

  const [checkMap, setCheckMap] = useState({});
  const [privateMap, setPrivateMap] = useState({});
  const [selectedType, setSelectedType] = useState("Template");

  useEffect(() => {
    if (isChecked("Scene") || isChecked("HTML") || isChecked("CSS")) {
      setSelectedType("Template");
      return;
    }

    let targetType = undefined;
    for (let entity of Object.keys(entityPropertyMap)) {
      if (isChecked(entity)) {
        // Environments can have multiple entities
        targetType = "Environment";
      }
    }

    for (let script of scripts) {
      if (isChecked(script.id)) {
        if (targetType) {
          setSelectedType("Template");
          return;
        }
        targetType = "Script";
      }
    }

    for (let asset of assets) {
      if (isChecked(asset.id)) {
        if (targetType) {
          setSelectedType("Template");
          return;
        }
        targetType = "Asset";
      }
    }

    for (let app of apps) {
      if (isChecked(app.id)) {
        if (targetType) {
          setSelectedType("Template");
          return;
        }
        targetType = "App";
      }
    }

    setSelectedType(targetType);
  }, [checkMap, assets]);

  useEffect(() => {
    const fetchData = async () => {
      {
        const NUM_ITEMS_PER_REQUEST = 50;

        let page = 0;
        let data = undefined;
        let results = [];
        do {
          data = (await supabase.storage.from("storage").list("sites/" + currentSite + "/private/", {
            limit: NUM_ITEMS_PER_REQUEST,
            offset: page * NUM_ITEMS_PER_REQUEST
          })).data;
          if (data && data.length > 0) {
            results = results.concat(data);
            page++;
          }
        } while (data && data.length > 0);

        if (results.length > 0) {
          setAssets(results);
        } else {
          setAssets([]);
        }
      }

      {
        const { data } = await supabase.from("marketplace_listings").select("*")
          .eq("creator", user?.user?.id).order("created_at", { ascending: false });
        if (data) {
          data.unshift({ name: NEW_LISTING_NAME, id: "new" });
          setListings(data);
        } else {
          setListings([]);
        }
      }
    }
    fetchData();
  }, []);

  const upload = () => {
    let data = {};

    // TODO: warn if anything references asset link that's not included, or any asset that is included
    // is never referenced?
    if (isChecked("Scene") && scenePropertyMap && Object.values(scenePropertyMap).length > 0) {
      data.scene = scenePropertyMap;
    }

    // Minify HTML, CSS, + JS + replace asset URLs
    // TODO: actually minify
    if (isChecked("HTML") && overlayElements && overlayElements.length > 0) {
      data.overlay = overlayElements;
    }

    if (isChecked("CSS") && cssProps && cssProps.length > 0) {
      data.css = cssProps;
    }

    // add_entities accepts parents by relative index, so we make sure that:
    // - parents come before children
    // - childrens' parent property is replaced by the difference in index
    // created_at and id are also removed because they get replaced
    if (entityParentMap && undefined in entityParentMap) {
      const entitiesData = [];
      const populateEntities = (entity) => {
        for (let child of entityParentMap[entity]) {
          if (isChecked(child)) {
            entitiesData.push({...entityPropertyMap[child]});
          }
          if (child in entityParentMap) {
            populateEntities(child);
          }
        }
      };
      populateEntities(undefined);

      for (let i = 0; i < entitiesData.length; i++) {
        let entity = entitiesData[i];

        if ("parent" in entity) {
          const parentID = entity.parent;
          const parentIndex = entitiesData.findIndex((e) => { return e.id == parentID });
          if (parentIndex === -1) {
            delete entity.parent;
          } else {
            entity.parent = parentIndex - i;
          }
        }

        delete entity.created_at;
      }

      for (let entity of entitiesData) {
        delete entity.id;
      }

      if (entitiesData.length > 0) {
        data.entities = entitiesData;
      }
    }

    const scriptsData = [];
    for (const script of scripts) {
      if (isChecked(script.id)) {
        let scriptProps = {...script};
        if (isPrivate(script.id)) {
          scriptProps.private = true;
        }
        delete scriptProps.id;
        delete scriptProps.created_at;
        scriptsData.push(scriptProps);
      }
    }

    if (scriptsData.length > 0) {
      data.scripts = scriptsData;
    }

    const appsData = [];
    for (const app of apps) {
      if (isChecked(app.id)) {
        let appProps = {...app};
        if (isPrivate(app.id)) {
          appProps.private = true;
        }
        delete appProps.id;
        delete appProps.created_at;
        appsData.push(appProps);
      }
    }

    const assetsData = [];
    for (const asset of assets) {
      if (isChecked(asset.id)) {
        assetsData.push(asset.name);
      }
    }

    if (appsData.length > 0) {
      data.apps = appsData;
    }

    if (selectedType === "Script") {
      data = data.scripts[0];
    } else if (selectedType === "App") {
      data = data.apps[0];
    } else if (selectedType === "Environment") {
      data = {
        "environment": data.entities
      }
    } else if (selectedType === "Asset") {
      data = {
        "asset": getPublicURL(assetsData[0])
      }
    }

    const copyAssets = async (listing) => {
      const { data } = await supabase.from("marketplace_listings").select("versions").eq("id", listing).limit(1);
      if (data && data[0]?.versions?.length > 0) {
        const newItemID = data[0].versions[data[0].versions.length - 1];
        const promises = assetsData.map((asset) => {
          return supabase.storage.from("storage")
                  .copy("sites/" + currentSite + "/private/" + asset,
                        "marketplace/" + newItemID + "/" + asset)
        });

        const results = await Promise.allSettled(promises);
        console.log(results);
        for (let result of results) {
          if (result?.value?.error) {
            console.log(result.value.error);
          }
        }
      }
    }

    if (targetListingRef.current.value === "new") {
      supabase.rpc("add_listing", {
        site: currentSite,
        type: selectedType.toLowerCase(),
        listingname: listingName,
        listingtags: listingTags,
        itemname: itemName,
        itemdescription: itemDescription,
        data: data,
      }).then(async (result) => {
        console.log(result);
        if (!result.error) {
          copyAssets(result.data);
          refreshOwnedItemsHandler();
          setEditorModeHandler("Marketplace");
          setTargetListingHandler(result.data);
        }
      });
    } else {
      supabase.rpc("update_listing", {
        site: currentSite,
        listing: targetListingRef.current.value,
        itemtype: selectedType.toLowerCase(),
        listingname: listingName,
        listingtags: listingTags,
        itemname: itemName,
        itemdescription: itemDescription,
        data: data,
      }).then(async (result) => {
        console.log(result);
        if (!result.error) {
          copyAssets(targetListingRef.current.value);
          refreshOwnedItemsHandler();
          setEditorModeHandler("Marketplace");
          setTargetListingHandler(targetListingRef.current.value);
        }
      });
    }
  };

  const lists = {
    "Scripts": scripts,
    "Assets": assets,
    "Apps": apps
  };

  return (
    <div
      className={styles.ui}
      style={{
        width: "700px",
        height: "600px",
        color: "#CCCCCC",
        background: "rgba(50, 50, 50, 0.7)",
        overflow: "auto",
        position: "relative"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className={[styles.topRight, styles.unselectable].join(" ")}
        onClick={() => {
          setEditorModeHandler("Entities");
          setTargetListingHandler(undefined);
        }}
      >
        x
      </button>

      <p className={styles.unselectable}>Upload</p>
      <label className={styles.unselectable} htmlFor="listing">Listing:</label>
      <select
        id="listing"
        ref={targetListingRef}
        onChange={async (e) => {
          let listing = listings.find((listing) => listing.id === e.target.value);
          setListingName(listing.name ?? "");
          setListingTags(listing.tags ?? "");

          if (listing.versions?.length > 0) {
            const { data } = await supabase.from("marketplace_items").select("*")
              .eq("id", listing.versions[listing.versions.length - 1]).limit(1);
            if (data) {
              setItemName(data[0].name);
              setItemDescription(data[0].description);
            } else {
              setItemName("");
              setItemDescription("");
            }
          } else {
            setItemName("");
            setItemDescription("");
          }
        }}
      >
        {listings.map((listing) => {
          return (
            <option
              key={listing.id}
              className={styles.unselectable}
              value={listing.id}
            >
              {listing.name}
            </option>
          )
        })}
      </select>
      <br />
      <br />
      <label className={styles.unselectable}>Listing Name:</label>
      <ControlledInput
        key={"listing" + targetListingRef.current?.value}
        value={listingName}
        onChange={(e) => setListingName(e.target.value)}
      />
      <br />
      <label className={styles.unselectable}>Version Name:</label>
      <ControlledInput
        key={"item" + targetListingRef.current?.value}
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
      />
      <br />
      <label className={styles.unselectable}>Tags:</label>
      <ControlledInput
        key={"tags" + targetListingRef.current?.value}
        value={listingTags}
        onChange={(e) => setListingTags(e.target.value)}
      />
      <br />
      <br />
      {modes.map((modeValue) => {
          return <span
            key={modeValue}
            className={styles.unselectable}
            style={{
              padding: "2px 10px",
              backgroundColor: mode === modeValue ? "#444444" : undefined,
              borderRadius: mode === modeValue ? "10px" : undefined
            }}
            onClick={() => setMode(modeValue)}
          >
            {modeValue}
          </span>
        })
      }
      <div
        style={{
          width: "100%",
          height: "300px",
          overflow: "auto",
          textAlign: "left"
        }}
      >
        {mode === "Entities" ?
          <>
            <UploadCheckbox name="Scene" checkMap={checkMap} setCheckMap={setCheckMap} />
            <UploadCheckbox name="HTML" checkMap={checkMap} setCheckMap={setCheckMap} />
            <UploadCheckbox name="CSS" checkMap={checkMap} setCheckMap={setCheckMap} />
            {Object.keys(entityPropertyMap).length > 0 ?
              <>
                <UploadCheckbox name="Entities" checkMap={checkMap} setCheckMap={setCheckMap} onChange={(checked, map) => {
                  for (let entity of Object.keys(entityPropertyMap)) {
                    map[entity] = checked;
                  }
                }} />
                <div
                  style={{
                    paddingLeft: "10px"
                  }}
                >
                  <EntityList
                    entity={undefined}
                    entityPropertyMap={entityPropertyMap}
                    entityParentMap={entityParentMap}
                    checkMap={checkMap}
                    setCheckMap={setCheckMap}
                  />
                </div>
              </>
              : <></>
            }
          </>
          : mode === "Description" ?
            <ControlledTextArea
              key={"description" + targetListingRef.current?.value}
              style={{
                width: "100%",
                height: "300px"
              }}
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
            />
          : <ItemList
            items={lists[mode]}
            name={mode}
            checkMap={checkMap}
            setCheckMap={setCheckMap}
            privateMap={privateModes.indexOf(mode) !== -1 ? privateMap : undefined}
            setPrivateMap={privateModes.indexOf(mode) !== -1 ? setPrivateMap : undefined}
          />
        }
      </div>
      <p>Selected type: {selectedType ?? "Nothing selected"}</p>
      <button
        disabled={selectedType ? undefined : true}
        onClick={() => { upload() }}
      >
        Upload!
      </button>
    </div>
  )
}