import "aframe";
import { MeshRefractionMaterial } from "@react-three/drei/materials/MeshRefractionMaterial";
import { MeshBVH, MeshBVHUniformStruct, SAH } from "three-mesh-bvh";

import { rematerial } from "./utils";

function RoomEnvironment() {
  const scene = new THREE.Scene();

  const geometry = new THREE.BoxGeometry();
  geometry.deleteAttribute("uv");

  const roomMaterial = new THREE.MeshStandardMaterial({ side: THREE.BackSide });
  const boxMaterial = new THREE.MeshStandardMaterial();

  const mainLight = new THREE.PointLight(0xffffff, 5.0, 28, 2);
  mainLight.position.set(0.418, 16.199, 0.3);
  scene.add(mainLight);

  const room = new THREE.Mesh(geometry, roomMaterial);
  room.position.set(-0.757, 13.219, 0.717);
  room.scale.set(31.713, 28.305, 28.591);
  scene.add(room);

  const box1 = new THREE.Mesh(geometry, boxMaterial);
  box1.position.set(-10.906, 2.009, 1.846);
  box1.rotation.set(0, -0.195, 0);
  box1.scale.set(2.328, 7.905, 4.651);
  scene.add(box1);

  const box2 = new THREE.Mesh(geometry, boxMaterial);
  box2.position.set(-5.607, -0.754, -0.758);
  box2.rotation.set(0, 0.994, 0);
  box2.scale.set(1.97, 1.534, 3.955);
  scene.add(box2);

  const box3 = new THREE.Mesh(geometry, boxMaterial);
  box3.position.set(6.167, 0.857, 7.803);
  box3.rotation.set(0, 0.561, 0);
  box3.scale.set(3.927, 6.285, 3.687);
  scene.add(box3);

  const box4 = new THREE.Mesh(geometry, boxMaterial);
  box4.position.set(-2.017, 0.018, 6.124);
  box4.rotation.set(0, 0.333, 0);
  box4.scale.set(2.002, 4.566, 2.064);
  scene.add(box4);

  const box5 = new THREE.Mesh(geometry, boxMaterial);
  box5.position.set(2.291, -0.756, -2.621);
  box5.rotation.set(0, -0.286, 0);
  box5.scale.set(1.546, 1.552, 1.496);
  scene.add(box5);

  const box6 = new THREE.Mesh(geometry, boxMaterial);
  box6.position.set(-2.193, -0.369, -5.547);
  box6.rotation.set(0, 0.516, 0);
  box6.scale.set(3.875, 3.487, 2.986);
  scene.add(box6);

  // -x right
  const light1 = new THREE.Mesh(geometry, createAreaLightMaterial(50));
  light1.position.set(-16.116, 14.37, 8.208);
  light1.scale.set(0.1, 2.428, 2.739);
  scene.add(light1);

  // -x left
  const light2 = new THREE.Mesh(geometry, createAreaLightMaterial(50));
  light2.position.set(-16.109, 18.021, -8.207);
  light2.scale.set(0.1, 2.425, 2.751);
  scene.add(light2);

  // +x
  const light3 = new THREE.Mesh(geometry, createAreaLightMaterial(17));
  light3.position.set(14.904, 12.198, -1.832);
  light3.scale.set(0.15, 4.265, 6.331);
  scene.add(light3);

  // +z
  const light4 = new THREE.Mesh(geometry, createAreaLightMaterial(43));
  light4.position.set(-0.462, 8.89, 14.52);
  light4.scale.set(4.38, 5.441, 0.088);
  scene.add(light4);

  // -z
  const light5 = new THREE.Mesh(geometry, createAreaLightMaterial(20));
  light5.position.set(3.235, 11.486, -12.541);
  light5.scale.set(2.5, 2.0, 0.1);
  scene.add(light5);

  // +y
  const light6 = new THREE.Mesh(geometry, createAreaLightMaterial(100));
  light6.position.set(0.0, 20.0, 0.0);
  light6.scale.set(1.0, 0.1, 1.0);
  scene.add(light6);

  function createAreaLightMaterial(intensity) {
    const material = new THREE.MeshBasicMaterial();
    material.color.setScalar(intensity);
    return material;
  }

  return scene;
}

const setModelFileOnElement = (el, file) => {
  el.removeAttribute("model");

  let onLoad = function () {
    el.removeEventListener("componentinitialized", onLoad);
    let modelComponent = el.components.model;
    if (modelComponent) {
        modelComponent.parse(file);
    }
  };
  el.addEventListener("componentinitialized", onLoad);

  el.setAttribute("model", { url: null, format: null });
};

const USE_REFRACTION_MATERIAL = true;

AFRAME.registerComponent("ring", {
  schema: {
    material: {
      default: "gold",
      oneOf: ["gold", "roseGold", "silver"],
    },
    posY: {
      default: 0.6,
    },
    bandSize: {
      default: 1,
    },
    headSize: {
      default: 1,
    },
    headOffsetY: {
      default: 10,
    },
    joint1: {
      type: "string",
      value: undefined
    },
    joint2: {
      type: "string",
      value: undefined
    },
    aberrationStrength: {
      default: 0.01,
    },
    bounces: {
      default: 2
    },
    fresnel: {
      default: 0
    },
    ior: {
      default: 2.4
    },
    correctMips: {
      default: true
    },
    color: {
      default: "white"
    },
    envMapGold: {
      default: ""
    },
    envMapRoseGold: {
      default: ""
    },
    envMapSilver: {
      default: ""
    },
  },

  setRefractionEnvMap: function (material, texture) {
    material.envMap = texture;
    material.uniforms.envMap.value.needsUpdate = true;

    const isCubeMap = texture.isCubeTexture === true;
    const w = isCubeMap ? texture.image[0].width : texture.image.width;
    const cubeSize = 0.25 * w;
    const _lodMax = Math.floor(Math.log2(cubeSize));
    const _cubeSize = Math.pow(2, _lodMax);
    const width = 3 * Math.max(_cubeSize, 16 * 7);
    const height = 4 * _cubeSize;

    material.defines = {
      ENVMAP_TYPE_CUBEM: isCubeMap,
      CUBEUV_MAX_MIP: `${_lodMax}.0`,
      CUBEUV_TEXEL_WIDTH: 1.0 / width,
      CUBEUV_TEXEL_HEIGHT: 1.0 / height,
      CHROMATIC_ABERRATIONS: material.uniforms.aberrationStrength.value > 0.0,
      FAST_CHROMA: false
    };

    material.needsUpdate = true;
  },

  init: function () {
    let self = this;
    let scene = this.el.sceneEl;
    let renderer = scene.renderer;
    const environment = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    scene.environment = pmremGenerator.fromScene(environment).texture;

    this.listeners = [];
    this.activeGemMaterials = [];
    this.gemMaterials = {};
    const colors = {
      "ruby": "#9B111E",
      "emerald": "#50C878",
      "sapphire": "#0F52BA",
    };
    const iors = {
      "ruby": 1.76,
      "emerald": 1.58,
      "sapphire": 1.76,
    };
    if (USE_REFRACTION_MATERIAL) {
      this.gemMaterials = {
        "diamond": new MeshRefractionMaterial(),
        "ruby": new MeshRefractionMaterial(),
        "emerald": new MeshRefractionMaterial(),
        "sapphire": new MeshRefractionMaterial(),
      };

      for (let material of Object.keys(colors)) {
        this.gemMaterials[material].uniforms.color.value = new THREE.Color(colors[material]);
        this.gemMaterials[material].uniforms.ior.value = iors[material];
      }

      this.listeners.push(window.addEventListener("resize", () => { self.resize(); }));
      this.resize();
    } else {
      let material = new THREE.MeshStandardMaterial({
        roughness: 0.15,
        metalness: 0.825,
        fog: false,
        envMap: this.el.sceneEl.environment,
        envMapIntensity: 1,
        opacity: 0.8,
        transparent: true
      });

      this.gemMaterials = {
        "diamond": material.clone(),
        "ruby": material.clone(),
        "emerald": material.clone(),
        "sapphire": material.clone(),
      };

      for (let material of Object.keys(colors)) {
        this.gemMaterials[material].color = new THREE.Color(colors[material]);
      }
    }

    this.envMaps = {};
    this.metalMaterials = {
      "gold": new THREE.MeshStandardMaterial({
        color: "#FED687",
        emissive: "#CAEA5E",
        emissiveIntensity: 0.15,
        envMap: scene.environment,
        envMapIntensity: 0.4,
        fog: false,
        roughness: 0.15,
        metalness: 0.5,
      }),
      "roseGold": new THREE.MeshStandardMaterial({
        color: "#FFBFA0",
        emissive: "#FFAF90",
        emissiveIntensity: 0.15,
        envMap: scene.environment,
        envMapIntensity: 0.4,
        fog: false,
        roughness: 0.15,
        metalness: 0.5,
      }),
      "silver": new THREE.MeshStandardMaterial({
        color: "#FFFFFF",
        emissive: "#FFFFFF",
        emissiveIntensity: 0.1,
        envMap: scene.environment,
        envMapIntensity: 1,
        fog: false,
        roughness: 0.15,
        metalness: 0.825,
      })
    };

    this.el.addEventListener("model-loaded", function () { self.updateMesh(self); });
    this.el.addEventListener("gltf-loaded", function () { self.updateMesh(self); });

    this.offsetRotations = [new THREE.Quaternion(), new THREE.Quaternion(), new THREE.Quaternion()];
    this.offsetRotations[0].setFromAxisAngle(new THREE.Vector3(0, 0, -1), THREE.MathUtils.degToRad(15.0));
    this.offsetRotations[1].setFromAxisAngle(new THREE.Vector3(0, 0, -1), THREE.MathUtils.degToRad(-15.0));

    {
      const queryString = window.location.search;
      if (queryString) {
        const urlParams = new URLSearchParams(queryString);
        const modelURLParam = urlParams.get("modelURL");
        if (modelURLParam) {
          const formatParam = urlParams.get("modelFormat");
          this.setURLForPart("model", modelURLParam, formatParam);
        }

        const bandURLParam = urlParams.get("bandURL");
        if (bandURLParam) {
          const formatParam = urlParams.get("bandFormat");
          this.setURLForPart("band", bandURLParam, formatParam);
        }

        const headURLParam = urlParams.get("headURL");
        if (headURLParam) {
          const formatParam = urlParams.get("headFormat");
          this.setURLForPart("head", headURLParam, formatParam);
        }
      }
    }
  },

  updateMesh: function (self) {
    // First traversal: set metal
    const metal = self.metalMaterials[self.data.material];
    let currentLayers = undefined;
    let currentGem = undefined;
    const isGem = (node) => {
      const nameIsGem = (name) => {
        if (name.includes("diamond")) {
          currentGem = "diamond";
          return true;
        } else if (name.includes("ruby")) {
          currentGem = "ruby";
          return true;
        } else if (name.includes("emerald")) {
          currentGem = "emerald";
          return true;
        } else if (name.includes("sapphire")) {
          currentGem = "sapphire";
          return true;
        } else if (name.includes("gem")) {
          currentGem = "diamond";
          return true;
        }

        return false;
      };

      if (node.name.length > 0) {
        if (nameIsGem(node.name.toLowerCase())) {
          return true;
        }
      }

      if (currentLayers && node.userData?.attributes?.layerIndex !== undefined &&
          node.userData.attributes.layerIndex < currentLayers.length) {
        if (nameIsGem(currentLayers[node.userData.attributes.layerIndex].name.toLowerCase())) {
          return true;
        }
      }

      return false;
    };

    rematerial(self, (node) => {
      if (Object.keys(node.userData).length > 0 && node.userData.layers) {
        currentLayers = node.userData.layers;
      }
      return node.isMesh && !isGem(node);
    }, (node) => { node.material = metal; }, self);

    // Second traversal: set gem material
    // TODO: render reflection maps?
    self.activeGemMaterials = [];
    currentLayers = undefined;
    currentGem = undefined;
    rematerial(self, (node) => {
      if (Object.keys(node.userData).length > 0 && node.userData.layers) {
        currentLayers = node.userData.layers;
      }
      return node.isMesh && node.geometry && isGem(node);
    }, (node) => {
      node.material = self.gemMaterials[currentGem].clone();
      if (USE_REFRACTION_MATERIAL) {
        node.material.uniforms.bvh.value = new MeshBVHUniformStruct();
        node.material.uniforms.bvh.value.updateFrom(new MeshBVH(node.geometry.toNonIndexed(), {
          lazyGeneration: false,
          strategy: SAH
        }));
      }
      node.material.needsUpdate = true;
      self.activeGemMaterials.push(node.material);
    }, self);
  },

  update: function (oldData) {
    let needsMeshUpdate = false;
    if (USE_REFRACTION_MATERIAL) {
      if (this.data.aberrationStrength !== oldData.aberrationStrength ||
        this.data.bounces !== oldData.bounces ||
        this.data.fresnel !== oldData.fresnel ||
        this.data.ior !== oldData.ior ||
        this.data.correctMips !== oldData.correctMips ||
        this.data.color !== oldData.color) {
          for (let materialName of Object.keys(this.gemMaterials)) {
            let gemMaterial = this.gemMaterials[materialName];
            if (materialName === "diamond") {
              gemMaterial.uniforms.ior.value = this.data.ior;
              gemMaterial.uniforms.color.value = new THREE.Color(this.data.color);
            }
            gemMaterial.uniforms.bounces.value = this.data.bounces;
            gemMaterial.uniforms.aberrationStrength.value = this.data.aberrationStrength;
            gemMaterial.uniforms.fresnel.value = this.data.fresnel;
            gemMaterial.uniforms.correctMips.value = this.data.correctMips;
          }
          needsMeshUpdate = true;
        }
    } else {
      if (this.data.color !== oldData.color) {
        this.gemMaterials["diamond"].color = new THREE.Color(this.data.color);
      }
    }

    if (this.data.material !== oldData.material) {
      if (USE_REFRACTION_MATERIAL && this.envMaps?.[this.data.material]) {
        for (let gemMaterial of Object.values(this.gemMaterials)) {
          this.setRefractionEnvMap(gemMaterial, this.envMaps[this.data.material]);
        }
      }
      needsMeshUpdate = true;
    }

    if (USE_REFRACTION_MATERIAL) {
      let self = this;
      if (this.data.envMapGold && this.data.envMapGold.length > 0 && this.data.envMapGold !== oldData.envMapGold) {
        new THREE.TextureLoader().load(this.data.envMapGold,
          function (texture) {
            self.envMaps["gold"] = texture;
            if (self.data.material === "gold") {
              for (let gemMaterial of Object.values(self.gemMaterials)) {
                self.setRefractionEnvMap(gemMaterial, self.envMaps["gold"]);
              }
              self.updateMesh(self);
            }
          });
      }
      if (this.data.envMapRoseGold && this.data.envMapRoseGold.length > 0 && this.data.envMapRoseGold !== oldData.envMapRoseGold) {
        new THREE.TextureLoader().load(this.data.envMapRoseGold,
          function (texture) {
            self.envMaps["roseGold"] = texture;
            if (self.data.material === "roseGold") {
              for (let gemMaterial of Object.values(self.gemMaterials)) {
                self.setRefractionEnvMap(gemMaterial, self.envMaps["roseGold"]);
              }
              self.updateMesh(self);
            }
          });
      }
      if (this.data.envMapSilver && this.data.envMapSilver.length > 0 && this.data.envMapSilver !== oldData.envMapSilver) {
        new THREE.TextureLoader().load(this.data.envMapSilver,
          function (texture) {
            self.envMaps["silver"] = texture;
            if (self.data.material === "silver") {
              for (let gemMaterial of Object.values(self.gemMaterials)) {
                self.setRefractionEnvMap(gemMaterial, self.envMaps["silver"]);
              }
              self.updateMesh(self);
            }
          });
      }
    }

    if (needsMeshUpdate) {
      this.updateMesh(this);
    }

    if (this.bandElement) {
      this.bandElement.setAttribute("scale", this.data.bandSize + " " + this.data.bandSize + " " + this.data.bandSize);
    }
    if (this.headElement) {
      this.headElement.setAttribute("scale", this.data.headSize + " " + this.data.headSize + " " + this.data.headSize);

      let headPos = this.data.bandSize * this.data.headOffsetY;
      this.headElement.setAttribute("position", "0 " + headPos + " 0");
    }
  },

  resize: function () {
    if (USE_REFRACTION_MATERIAL) {
      for (let gemMaterial of Object.values(this.gemMaterials)) {
        gemMaterial.uniforms.resolution.value.set(window.innerWidth, window.innerHeight);
      }
      for (let gemMaterial of this.activeGemMaterials) {
        gemMaterial.uniforms.resolution.value.set(window.innerWidth, window.innerHeight);
      }
    }
  },

  tick: function () {
    if (USE_REFRACTION_MATERIAL) {
      for (let gemMaterial of this.activeGemMaterials) {
        gemMaterial.uniforms.viewMatrixInverse.value = this.el.sceneEl.camera.matrixWorld;
        gemMaterial.uniforms.projectionMatrixInverse.value = this.el.sceneEl.camera.projectionMatrixInverse;
      }
    }

    let found = false;
    let jointPairs = [["#leftHandController", "#b_l_ring1", "#b_l_ring2"], ["#rightHandController", "#b_r_ring1", "#b_r_ring2"]];
    if (this.data.joint1 && this.data.joint2) {
      jointPairs.push([undefined, this.data.joint1, this.data.joint2]);
    }
    for (let jointPairIndex = 0; jointPairIndex < jointPairs.length; jointPairIndex++) {
      const jointPair = jointPairs[jointPairIndex];
      const joint1 = document.querySelector(jointPair[1]);
      const joint2 = document.querySelector(jointPair[2]);
      if (joint1 && joint2) {
        // First joint
        let ringPosition = new THREE.Vector3();
        joint1.object3D.getWorldPosition(ringPosition);

        // Second joint
        let position2 = new THREE.Vector3();
        joint2.object3D.getWorldPosition(position2);

        // Lerp
        ringPosition.lerp(position2, this.data.posY);
        this.el.object3D.position.copy(ringPosition);
        joint1.object3D.getWorldQuaternion(this.el.object3D.quaternion);

        // FIXME: why is this necessary?
        this.el.object3D.quaternion.multiply(this.offsetRotations[jointPairIndex]);

        found = true;
        break;
      }
    }

    if (!found) {
      for (let jointPairIndex = 0; jointPairIndex < jointPairs.length; jointPairIndex++) {
        const jointPair = jointPairs[jointPairIndex];
        const hand = document.querySelector(jointPair[0]);
        if (hand) {
          let controls = hand.components["hand-tracking-controls"];
          if (controls && controls.bones) {
            const bone1 = controls.getBone(jointPair[1]);
            const bone2 = controls.getBone(jointPair[2]);

            if (bone1 && bone2) {
              // First joint
              let ringPosition = bone1.position.clone();
              ringPosition.multiplyScalar(0.01);
              ringPosition.setY(ringPosition.y + 1.5);

              // Second joint
              let position2 = bone2.position.clone();
              position2.multiplyScalar(0.01);
              position2.setY(position2.y + 1.5);

              // Lerp
              ringPosition.lerp(position2, this.data.posY);
              this.el.object3D.position.copy(ringPosition);
              this.el.object3D.quaternion.copy(bone1.quaternion);

              // FIXME: why is this necessary?
              this.el.object3D.quaternion.multiply(this.offsetRotations[jointPairIndex]);

              found = true;
              break;
            }
          }
        }
      }
    }
  },

  remove: function () {
    if (this.bandElement) {
      this.bandElement.removeFromParent();
      this.bandElement = undefined;
    }
    if (this.headElement) {
      this.headElement.removeFromParent();
      this.headElement = undefined;
    }
    if (this.modelElement) {
      this.modelElement.removeFromParent();
      this.modelElement = undefined;
    }
  },

  setupPart: function (part, callback) {
    let el = undefined;
    let newEl = false;
    switch (part) {
      case "model":
        if (this.bandElement) {
          this.bandElement.removeFromParent();
          this.bandElement = undefined;
        }
        if (this.headElement) {
          this.headElement.removeFromParent();
          this.headElement = undefined;
        }
        if (!this.modelElement) {
          this.modelElement = document.createElement("a-entity");
          newEl = true;
        }
        el = this.modelElement;
        break;
      case "band":
        if (this.modelElement) {
          this.modelElement.removeFromParent();
          this.modelElement = undefined;
        }
        if (!this.bandElement) {
          this.bandElement = document.createElement("a-entity");
          this.bandElement.setAttribute("scale", this.data.bandSize + " " + this.data.bandSize + " " + this.data.bandSize);
          newEl = true;
        }
        el = this.bandElement;
        break;
      case "head":
        if (this.modelElement) {
          this.modelElement.removeFromParent();
          this.modelElement = undefined;
        }
        if (!this.headElement) {
          this.headElement = document.createElement("a-entity");
          let headPos = this.data.bandSize * this.data.headOffsetY;
          this.headElement.setAttribute("position", "0 " + headPos + " 0");
          this.headElement.setAttribute("scale", this.data.headSize + " " + this.data.headSize + " " + this.data.headSize);
          newEl = true;
        }
        el = this.headElement;
        break;
      default:
        break;
    }

    if (el) {
     if (newEl) {
        let listener = () => {
          callback(el);
          el.removeEventListener("loaded", listener);
        };
        el.addEventListener("loaded", listener);
        this.el.appendChild(el);
      } else {
        callback(el);
      }
    }
  },

  setFileForPart: function (part, file) {
    this.setupPart(part, (el) => { setModelFileOnElement(el, file) });
  },

  setURLForPart: function (part, url, format) {
    this.setupPart(part, (el) => { el.setAttribute("model", { url: url, format: format }) });
  }
});