export default function gydenceAPI({ currentSite, isEditing, isPublic,
                                     html, css, scripts }) {
  let apiString = `
    window.GYDENCE = {};
    const GYDENCE = window.GYDENCE;
    GYDENCE.publicSiteURL = "` + window.location.origin + "/view/" + currentSite + `";
    GYDENCE.privateSiteURL = "` + window.location.origin + "/preview/" + currentSite + `";
    GYDENCE.isEditing = ` + isEditing + `;
    GYDENCE.assetURL = "https://zsrpzccdlyygmucesyzt.supabase.co/storage/v1/object/public/storage/sites/` + currentSite + (isPublic ? "/public" : "/private") + `/";
  `;

  // Some functionality is only exposed during editing
  if (isEditing) {
    apiString += `
      GYDENCE.supabase = supabase.createClient("` + process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL + '", "' + process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY + `");

      // Storage
      GYDENCE.listAssets = (options) => {
        return GYDENCE.supabase.storage.from("storage")
          .list("sites/` + currentSite + `/private/", options);
      };
      GYDENCE.listAllAssets = async () => {
        const NUM_ITEMS_PER_REQUEST = 100;

        let page = 0;
        let data = [];
        let assets = [];
        do {
          data = (await GYDENCE.listAssets({
            limit: NUM_ITEMS_PER_REQUEST,
            offset: page * NUM_ITEMS_PER_REQUEST
          })).data;
          if (data && data.length > 0) {
            assets = assets.concat(data);
            page++;
          }
        } while (data && data.length > 0);

        return assets;
      };
      GYDENCE.uploadAsset = (name, asset, options) => {
        return GYDENCE.supabase.storage.from("storage")
          .upload("sites/` + currentSite + `/private/" + name, asset, options ?? {
            cacheControl: "3600",
            upsert: true
          });
      };
      GYDENCE.renameAsset = (oldName, newName) => {
        return GYDENCE.supabase.storage.from("storage")
          .move("sites/` + currentSite + `/private/" + oldName, "sites/` + currentSite + `/private/" + newName);
      };
      GYDENCE.deleteAssets = (filenames) => {
        return GYDENCE.supabase.storage.from("storage")
          .remove(filenames.map((filename) => { return "sites/` + currentSite + `/private/" + filename; }));
      };

      // Site properties
      GYDENCE.getHTML = () => {
        return ` + JSON.stringify(html) + `;
      };
      GYDENCE.getCSS = () => {
        return ` + JSON.stringify(css) + `;
      };
      GYDENCE.getScripts = () => {
        return ` + JSON.stringify(scripts) + `;
      };
      GYDENCE.editScript = (scriptID, prop, value, forceUpdate = true) => {
        const scriptIndex = ` + JSON.stringify(scripts) + `.findIndex((el) => el.id === scriptID);
        return GYDENCE.supabase.rpc("update_script_property", {
          site: "` + currentSite + `",
          scriptindex: scriptIndex,
          property: prop,
          value: value,
          forceupdate: JSON.stringify(forceUpdate)
        });
      };`;
  }

  return apiString;
}