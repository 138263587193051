import "aframe";

AFRAME.registerComponent("selection-tool", {
  schema: {
    target: {
      type: "selector",
      default: "",
    },
  },

  init: function () {
    {
      const geometry = new THREE.BoxGeometry(1, 1, 1);
      const edges = new THREE.EdgesGeometry(geometry);
      this.box = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: 0xff0000 })
      );
      this.el.object3D.add(this.box);
    }

    this.cornerGroup = new THREE.Group();
    this.el.object3D.add(this.cornerGroup);
    this.corners = [];
    for (let i = 0; i < 8; i++) {
      const geometry = new THREE.IcosahedronGeometry(0.01);
      const corner = new THREE.Mesh(
        geometry,
        new THREE.MeshStandardMaterial({ color: 0x00ff00 })
      );
      corner.position.set(
        ((i >> 0) & 1) ? -1 : 1,
        ((i >> 1) & 1) ? -1 : 1,
        ((i >> 2) & 1) ? -1 : 1
      );
      corner.position.multiply(new THREE.Vector3(0.5, 0.5, 0.5));
      this.cornerGroup.add(corner);
      this.corners.push(corner);
    }

    this.targetPosition = new THREE.Vector3();
    this.targetQuaternion = new THREE.Quaternion();
    this.targetScale = new THREE.Vector3();
    this.cameraPos = new THREE.Vector3();
    this.cornerPos = new THREE.Vector3();

    let self = this;
    document.addEventListener("camerasConfigured", function () {
      self.configure();
    });
  },

  tick: function () {
    if (!this.data.target) {
      this.el.object3D.visible = false;
      return;
    }

    this.el.object3D.visible = true;

    this.data.target.object3D.getWorldPosition(this.targetPosition);
    this.data.target.object3D.getWorldQuaternion(this.targetQuaternion);
    this.data.target.object3D.getWorldScale(this.targetScale);

    this.box.position.copy(this.targetPosition);
    this.box.quaternion.copy(this.targetQuaternion);
    this.box.scale.copy(this.targetScale);

    this.cornerGroup.position.copy(this.targetPosition);
    this.cornerGroup.quaternion.copy(this.targetQuaternion);
    this.el.sceneEl.camera.getWorldPosition(this.cameraPos);
    for (let i = 0; i < 8; i++) {
      let corner = this.corners[i];
      corner.position.set(
        ((i >> 0) & 1) ? -1 : 1,
        ((i >> 1) & 1) ? -1 : 1,
        ((i >> 2) & 1) ? -1 : 1
      );
      corner.position.multiply(this.targetScale);
      corner.position.multiplyScalar(0.5);
      corner.getWorldPosition(this.cornerPos);
      const scale = 2.0 * this.cameraPos.distanceTo(this.cornerPos);
      corner.scale.set(scale, scale, scale);
    }
  },

  configure: function () {
    const self = this;
    document
      .querySelector("#camera-container")
      .addEventListener("click", function (evt) {
        if (document.isEditting && evt.detail.intersectedEl) {
          const entityName = evt.detail.intersectedEl.id;
          const newTarget = "#" + entityName;
          if (self.data.target && self.data.target.id === entityName) {
            self.el.setAttribute("selection-tool", "target", "");
          } else {
            self.el.setAttribute("selection-tool", "target", newTarget);
          }
        }
      });
  },
});
