import "aframe";

import { isMobileOrVR } from "./utils";

AFRAME.registerComponent("follow", {
  dependencies: ["position", "rotation"],
  schema: {
    posAllowance: { type: "number", default: 2.5 },
    rotAllowance: { type: "number", default: 80 },
    speed: { type: "number", default: 1 },
  },

  init: function () {
    this.cameraPos = new THREE.Vector3();
    this.cameraRot = new THREE.Quaternion();
    this.cameraRotInv = new THREE.Quaternion();
    this.currentPos = new THREE.Vector3();
    this.currentRot = new THREE.Quaternion();

    this.initialRelativePos = new THREE.Vector3();
    this.initialRelativeRot = new THREE.Quaternion();

    this.relativePos = new THREE.Vector3();
    this.relativeRot = new THREE.Quaternion();

    this.relativePosDelta = new THREE.Vector3();
    this.targetPos = new THREE.Vector3();
    this.targetRot = new THREE.Quaternion();

    this.FORWARD = new THREE.Vector3(0, 0, -1);
  },

  update: function () {
    this.posAllowanceSq = this.data.posAllowance * this.data.posAllowance;
    this.rotAllowanceRad = THREE.MathUtils.degToRad(this.data.rotAllowance);
  },

  tick: function () {
    if (!this.camera) {
      this.camera = document.querySelector(isMobileOrVR ? "#ar-camera" : "#camera");
    }

    if (this.camera) {
      this.el.object3D.getWorldPosition(this.currentPos);
      this.el.object3D.getWorldQuaternion(this.currentRot);
      this.camera.object3D.getWorldPosition(this.cameraPos);
      this.camera.object3D.getWorldQuaternion(this.cameraRot);

      this.cameraRotInv = this.cameraRot.clone();
      this.cameraRotInv.invert();

      this.relativePos.subVectors(this.currentPos, this.cameraPos);
      this.relativePos.applyQuaternion(this.cameraRotInv);
      this.relativeRot.multiplyQuaternions(this.currentRot, this.cameraRotInv);

      if (this.relativePos.lengthSq() > this.posAllowanceSq) {
        this.needsPosFollow = true;
      } else if (this.needsPosFollow) {
        this.relativePosDelta.subVectors(
          this.relativePos,
          this.initialRelativePos
        );
        if (this.relativePosDelta.lengthSq() < 0.005) {
          this.needsPosFollow = false;
        }
      }

      const angleTo = this.relativePos.angleTo(this.FORWARD);

      if (angleTo > this.rotAllowanceRad) {
        this.needsRotFollow = true;
      } else if (this.needsRotFollow) {
        if (angleTo < 0.15) {
          this.needsRotFollow = false;
        }
      }

      if (this.needsPosFollow || this.needsRotFollow) {
        this.targetPos = this.initialRelativePos.clone();
        this.targetPos.applyQuaternion(this.cameraRot);
        this.targetPos.add(this.cameraPos);

        const SPEED_FACTOR = 0.01;
        this.el.object3D.position.lerpVectors(
          this.currentPos,
          this.targetPos,
          SPEED_FACTOR * this.data.speed
        );

        this.targetRot = this.initialRelativeRot.clone();
        this.targetRot.multiply(this.cameraRot);

        this.el.object3D.rotation.setFromQuaternion(
          this.currentRot.slerp(this.targetRot, SPEED_FACTOR * this.data.speed)
        );
      }
    }
  },

  configure: function () {
    const camera = document.querySelector("#person").object3D;
    camera.getWorldPosition(this.cameraPos);
    camera.getWorldQuaternion(this.cameraRot);
    this.el.object3D.getWorldPosition(this.currentPos);
    this.el.object3D.getWorldQuaternion(this.currentRot);

    this.cameraRotInv = this.cameraRot.clone();
    this.cameraRotInv.invert();

    this.initialRelativePos.subVectors(this.currentPos, this.cameraPos);
    this.initialRelativePos.applyQuaternion(this.cameraRotInv);
    this.initialRelativeRot.multiplyQuaternions(
      this.currentRot,
      this.cameraRotInv
    );
  },
});
