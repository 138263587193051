import React, { useRef } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";

import { Analytics } from '@vercel/analytics/react';
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";

import DelayLink from "./components/delayLink";

import "./gydence/debug"
import SignIn from "./pages/signin";
import Editor from "./pages/editor";
import EditorView from "./pages/editorView";
import GydenceView from "./pages/view";
import GydencePreview from "./pages/preview";
import AppView from "./pages/app";

import "./index.module.css";

import styles from "./pages/index.module.css";

export default function Home() {
  const frameRef = useRef(undefined);

  return (
    <>
      <div
        style={{
          width: "100vw",
          zIndex: 1,
          top: "10px"
        }}
        className={[styles.topMiddle, styles.block].join(" ")}
      >
        <div
          className={[styles.block, styles.ui].join(" ")}
          style={{
            width: "fit-content",
            margin: "0 auto",
            backgroundColor: "rgb(255, 255, 255, 0.1)"
          }}
        >
          <DelayLink
            to="/editor"
            style={{
              fontSize: "20px",
              color: "#ddd"
            }}
            delay={750}
            onDelayStart={() => {
              frameRef?.current?.contentWindow?.postMessage({ type: "close" }, "*");
            }}
          >
            Try the Editor
          </DelayLink>
        </div>
      </div>
      <iframe
        ref={frameRef}
        className={styles.unselectable}
        title="Gydence Homepage"
        src="https://www.gydence.com/view/11859ebb-86c4-4f88-aade-29b0f1000cec"
        allow="geolocation; microphone; camera; midi; encrypted-media; xr-spatial-tracking; fullscreen"
        allowFullScreen=""
        sandbox="allow-scripts allow-modals allow-forms allow-same-origin allow-top-navigation-by-user-activation allow-downloads"
        style={{
          position: "absolute",
          width: "100dvw",
          height: "100dvh"
        }}
      />
    </>
  )
}

const root = createRoot(document.querySelector("#root"));
root.render(
  <>
  {/* <React.StrictMode> */}
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/editorView" element={<EditorView />} />
        <Route path="/view/:siteID" element={<GydenceView />} />
        <Route path="/preview/:siteID" element={<GydencePreview />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/app" element={<AppView />} />
      </Routes>
    </BrowserRouter>
    <Analytics />
  {/* </React.StrictMode> */}
  </>
);

reportWebVitals(sendToVercelAnalytics);