import "aframe";

import { isMobileOrVR } from "./utils";

AFRAME.registerComponent("container", {
  schema: {
    size: {
      type: "vec3",
      default: { x: 1, y: 1, z: 1 },
    },
    forceVisible: {
      type: "boolean",
      default: false,
    },
    majorColor: {
      type: "color",
      default: "#646464",
    },
    minorColor: {
      type: "color",
      default: "#C8C8C8",
    },
  },

  init: function () {
    this.prevScale = new THREE.Vector3();
  },

  update: function(prevData) {
    if (!this.lineGeometry || prevData.majorColor !== this.data.majorColor ||
      prevData.minorColor !== this.data.minorColor) {
      let vertices = [];
      let colors = [];

      const minorColor = new THREE.Color(this.data.minorColor);
      const majorColor = new THREE.Color(this.data.majorColor);
      const markSize = 0.1;
      for (let i = 0; i < 3; i++) {
        let newVertices = [0, 0, 0, 0, 0, 0];
        newVertices[i] = markSize;
        newVertices[i + 3] = -markSize;
        vertices = vertices.concat(newVertices);
        let newColors = [minorColor.r, minorColor.g, minorColor.b,
                         minorColor.r, minorColor.g, minorColor.b];
        colors = colors.concat(newColors);
      }

      for (let i = 0; i < 8; i++) {
        let x = ((i >> 0) & 1) ? -0.5 : 0.5;
        let y = ((i >> 1) & 1) ? -0.5 : 0.5;
        let z = ((i >> 2) & 1) ? -0.5 : 0.5;

        let newColors = [majorColor.r, majorColor.g, majorColor.b,
                         majorColor.r, majorColor.g, majorColor.b];

        for (let j = 0; j < 3; j++) {
          let newVertices = [x, y, z, x, y, z];
          newVertices[j + 3] += newVertices[j] < 0 ? markSize: -markSize;
          vertices = vertices.concat(newVertices);
          colors = colors.concat(newColors);
        }
      }

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );
      geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

      this.lineGeometry = new THREE.LineSegments(geometry, new THREE.LineBasicMaterial({
        vertexColors: true,
        toneMapped: false,
      }));
      this.lineGeometry.frustumCulled = false;
    }

    this.needsUpdate = true;
  },

  tick: function () {
    if (this.prevScale === this.el.object3D.scale && !this.needsUpdate) {
      return;
    }

    this.lineGeometry.scale.set(
      this.data.size.x / this.el.object3D.scale.x,
      this.data.size.y / this.el.object3D.scale.y,
      this.data.size.z / this.el.object3D.scale.z,
    );
    this.prevScale = this.el.object3D.scale.clone();

    if (this.el.object3D.children.length === 0 || (this.data.forceVisible && !this.hasGeometry)) {
      this.el.object3D.add(this.lineGeometry);
      this.hasGeometry = true;
    } else if (!this.data.forceVisible && (this.el.object3D.children.length > 1 && this.hasGeometry)) {
      this.el.object3D.remove(this.lineGeometry);
      this.hasGeometry = false;
    }

    this.needsUpdate = false;
  },
});