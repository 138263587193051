import "aframe";

const DEBUG_VIDEO = false;

AFRAME.registerSystem("camera-video-source", {
  schema: {
    enabled: {
      type: "boolean",
      default: false
    },
    zoom: {
      type: "number",
      default: 1.0
    }
  },

  init: function () {
    this.hasStream = false;
    this.streamFailed = false;
    this.hasResizedVideo = false;

    this.backCamera =
      localStorage.backCamera !== undefined
        ? localStorage.backCamera === "true"
        : true;

    let self = this;
    window.addEventListener("resize", () => {
        self.hasResizedVideo = false;
      }, true
    );
  },

  update: function (oldData) {
    if (this.data.enabled !== oldData.enabled) {
      if (!this.data.enabled) {
        if (this.videoElement) {
          this.videoElement.src = undefined;
          this.videoElement.srcObject = undefined;
        }
        this.videoElement = undefined;
        this.hasStream = false;
        this.streamFailed = false;
        this.hasResizedVideo = false;
      } else {
        GYDENCE.arSource = this;
      }
    }

    if (this.data.zoom !== oldData.zoom) {
      this.hasResizedVideo = false;
    }
  },

  flipCamera: async function() {
    this.backCamera = !this.backCamera;
    localStorage.backCamera = this.backCamera;

    if (this.videoElement) {
      try {
        await this.setupVideoStream();
      } catch (e) {
        if (DEBUG_VIDEO) {
          this.videoElement.src = "/assets/videoTest.mp4";
          this.hasStream = true;
          this.streamFailed = false;
        } else {
          this.streamFailed = true;
        }
        console.error(e);
      }
    }
  },

  resizeVideo: function () {
    if (!this.videoElement) {
      return;
    }

    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.windowAspect = this.windowWidth / this.windowHeight;
    this.videoWidth = this.videoElement.videoWidth;
    this.videoHeight = this.videoElement.videoHeight;
    this.videoAspect = this.videoWidth / this.videoHeight;
    if (this.windowAspect < this.videoAspect) {
      this.videoElement.width = this.data.zoom * this.windowHeight * this.videoAspect;
      this.videoElement.height = this.data.zoom * this.windowHeight;
    } else {
      this.videoElement.width = this.data.zoom * this.windowWidth;
      this.videoElement.height = this.data.zoom * this.windowWidth / this.videoAspect;
    }

    this.videoElement.style.top = 0.5 * (this.windowHeight -  this.videoElement.height) + "px";
    this.videoElement.style.left = 0.5 * (this.windowWidth - this.videoElement.width) + "px";

    if (this.videoElement.srcObject) {
      this.videoRatio = {
        x: this.videoElement.srcObject.getVideoTracks()[0].getSettings().width / this.videoElement.width,
        y: this.videoElement.srcObject.getVideoTracks()[0].getSettings().height / this.videoElement.height
      };
    } else if (this.videoElement.src) {
      this.videoRatio = { x: 1, y: 1};
    }
  },

  setupVideoStream: async function () {
    this.hasStream = false;
    this.paused = false;
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: this.backCamera ? "environment" : "user",
      },
    });
    this.hasStream = true;
    this.streamFailed = false;

    this.videoElement.style.webkitTransform = this.backCamera ? "scale(1)" : "scale(-1, 1)";
    this.videoElement.style.transform = this.backCamera ? "scale(1)" : "scale(-1, 1)";

    this.videoElement.srcObject = stream;
  },

  pauseVideo: function () {
    this.paused = true;
    this.videoElement?.pause();
  },

  playVideo: function () {
    this.paused = false;
    this.videoElement?.play();
  },

  tick: async function () {
    if (!this.data.enabled) {
      return;
    }

    if (!this.videoElement) {
      // Set up video stream
      this.videoElement = document.querySelector("#video");
      if (this.videoElement) {
        try {
          await this.setupVideoStream();
        } catch (e) {
          if (DEBUG_VIDEO) {
            this.videoElement.src = "/assets/videoTest.mp4";
            this.hasStream = true;
            this.streamFailed = false;
          } else {
            this.streamFailed = true;
          }
          console.error(e);
        }
      } else {
        return;
      }
    }

    if (!this.hasStream) {
      return;
    }

    if (this.videoElement.readyState === 0) {
      return;
    }

    if (!this.hasResizedVideo) {
      this.resizeVideo();
      this.hasResizedVideo = true;
    }
  },

  keypointToNDC: function (point) {
    // FIXME: why
    const isIOS = window.navigator.platform?.includes("iPhone");
    let pointNDCX;
    let pointNDCY;
    if (!isIOS) {
      pointNDCX = (2.0 * point.x) / this.videoElement.videoWidth - 1.0;
      pointNDCY = 1.0 - (2.0 * point.y) / this.videoElement.videoHeight;
    } else {
      pointNDCX = (2.0 * point.x * this.videoRatio.x) / this.videoElement.videoWidth - 1.0;
      pointNDCY = 1.0 - (2.0 * point.y * this.videoRatio.y) / this.videoElement.videoHeight;
    }
    pointNDCX *= this.data.zoom;
    pointNDCY *= this.data.zoom;
    if (!this.backCamera) {
      pointNDCX *= -1;
    }

    const xRatio = this.videoWidth / this.windowWidth;
    const yRatio = this.videoHeight / this.windowHeight;
    if (this.windowAspect < this.videoAspect) {
      pointNDCX *= xRatio / yRatio;
    } else {
      pointNDCY *= yRatio / xRatio;
    }

    return { x: pointNDCX, y: pointNDCY };
  }
});