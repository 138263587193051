import "aframe";

import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";

import { isMobileOrVR } from "./utils";

const NUM_FACE_KEYPOINTS = 478; // refineLandmarks gives 10 extra points.  normally 468

function setInfo(text) {
  let info = document.querySelector("#info");
  if (info) {
    if (text) {
      info.style.display = "";
      info.innerHTML = text;
    } else {
      info.style.display = "none";
      info.innerHTML = "";
    }
  }
}

AFRAME.registerSystem("tfjs-face-tracking", {
  schema: {
    enabled: {
      type: "boolean",
      default: false
    },
    numFaces: {
      type: "number",
      default: 1
    }
  },

  init: function () {
    this.uiHasLoaded = false;
    this.firstCall = true;

    this.debugPoints =
      localStorage.debugPoints !== undefined
        ? localStorage.debugPoints === "true"
        : false;

    this.denoisedKeypoints = [];
    this.points = [];

    this.p0 = new THREE.Vector3();
    this.p1 = new THREE.Vector3();

    let self = this;
    this.hasResized = false;
    window.addEventListener("resize", () => {
        self.hasResized = true;
      }, true
    );
  },

  update: function () {
    if (this.data.enabled) {
      // Start face tracking
      // FIXME: platform detection, performance comparison
      const useMediaPipe = !window.navigator.platform?.includes("iPhone");
      const detectorConfig = {
        runtime: !useMediaPipe ? "tfjs" : "mediapipe",
        maxFaces: this.data.numFaces,
        refineLandmarks: true,
        solutionPath: useMediaPipe ? "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/" : undefined
      };
      let self = this;
      faceLandmarksDetection.createDetector(
        faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh,
        detectorConfig
      ).then((detector) => {
        self.detector = detector;
      });

      this.firstCall = true;
    } else {
      // Stop face tracking
      this.detector = undefined;
      this.videoSource = undefined;

      // Cleanup bones
      for (let pointIndex = 0; pointIndex < this.points.length; pointIndex++) {
        const point = this.points[pointIndex];
        point.parentNode.removeChild(point);
      }
      this.points = [];

      // Cleanup debug view
      this.cleanupDebugFace();
    }
  },

  toggleDebug: function () {
    this.debugPoints = !this.debugPoints;
    localStorage.debugPoints = this.debugPoints;
  },

  cleanupDebugFace: function () {
    if (this.debugFace) {
      this.debugFace.parentNode.removeChild(this.debugFace);
      this.debugFace = undefined;
    }
    this.hasSetupDebugView = false;
  },

  tick: async function () {
    if (!this.detector) {
      return;
    }

    if (!this.uiHasLoaded) {
      const queryString = window.location.search;
      let debugButton = document.querySelector("#debugButton");
      if (queryString && debugButton) {
        const urlParams = new URLSearchParams(queryString);
        const debugParam = urlParams.get("debug");
        if (debugParam) {
          debugButton.style.display = "block";
        }
        this.uiHasLoaded = true;
      }
    }

    if (!this.videoSource?.videoElement) {
      this.videoSource = GYDENCE.arSource;
      if (this.videoSource?.videoElement) {
        setInfo("Initializing Face Tracking...");
      } else {
        return;
      }
    }

    if (this.videoSource.streamFailed && !this.hasSetupDebugView) {
      if (!GYDENCE.isEditing) {
        this.hasSetupDebugView = true;
        setInfo(null);
        return;
      }

      this.debugFace = document.createElement("a-entity");
      this.debugFace.setAttribute("gltf-model", "/assets/hand.glb");
      this.debugFace.setAttribute("position", "0 1.6 0.5");
      this.debugFace.setAttribute("rotation", "0 180 0");
      this.debugFace.setAttribute("scale", "0.75 0.75 0.75");

      // TODO: set up debug points
      // let joint1 = document.createElement("a-entity");
      // joint1.setAttribute("id", "b_r_ring1");
      // joint1.setAttribute("position", "-0.037 -0.025 -0.01");
      // joint1.setAttribute("rotation", "-90 0 0");
      // joint1.setAttribute("scale", "0.01 0.01 0.01");
      // joint1.setAttribute("visible", false);
      // // joint1.setAttribute("geometry", "primitive:cone;radiusBottom:0.5");
      // this.debugFace.appendChild(joint1);

      // let joint2 = document.createElement("a-entity");
      // joint2.setAttribute("id", "b_r_ring2");
      // joint2.setAttribute("position", "-0.045 0.01 0.01");
      // joint2.setAttribute("rotation", "-60 -10 10");
      // joint2.setAttribute("scale", "0.01 0.01 0.01");
      // joint2.setAttribute("visible", false);
      // // joint2.setAttribute("geometry", "primitive:cone;radiusBottom:0.5");
      // this.debugFace.appendChild(joint2);

      this.el.sceneEl.appendChild(this.debugFace);
      setInfo("Face Tracking Debug Initialized!");
      setTimeout(function () {
        setInfo(null);
      }, 3000);
      this.hasSetupDebugView = true;
      return;
    } else if (!this.videoSource.streamFailed && this.hasSetupDebugView) {
      this.cleanupDebugFace();
    }

    if (this.videoSource.videoElement.readyState === 0) {
      return;
    }

    if (this.videoSource.paused && !this.hasResized) {
      return;
    }
    this.hasResized = false;

    let self = this;
    this.detector.estimateFaces(this.videoSource.videoElement).then((faces) => {
      if (!self.data.enabled) {
        return;
      }

      if (self.firstCall) {
        setInfo("Face Tracking Initialized!");
        setTimeout(function () {
          setInfo(null);
        }, 3000);
        self.firstCall = false;
      }

      if (!faces || faces.length === 0) {
        self.denoisedKeypoints = [];
      }

      let pointIndex = 0;
      if (faces) {
        const getRay = function (point) {
          const pointNDC = self.videoSource.keypointToNDC(point);

          self.p0.set(pointNDC.x, pointNDC.y, 0.0);
          self.p0.unproject(self.el.camera);
          self.p1.set(pointNDC.x, pointNDC.y, 1.0);
          self.p1.unproject(self.el.camera);

          let v = self.p1.clone();
          v.sub(self.p0);
          v.normalize();

          return [self.p0, v];
        };

        for (let faceIndex = 0; faceIndex < faces.length; faceIndex++) {
          let face = faces[faceIndex];

          const bonePrefix = "label_" + faceIndex + "_";

          for (
            let keypointIndex = 0;
            keypointIndex < face.keypoints.length;
            keypointIndex++
          ) {
            let keypoint = face.keypoints[keypointIndex];
            if (keypointIndex === self.denoisedKeypoints.length) {
              self.denoisedKeypoints.push(keypoint);
            } else {
              let denoisedKeypoint = self.denoisedKeypoints[keypointIndex];
              const DISTANCE_THRESHOLD = 50;
              const factor = Math.min(1.0, Math.sqrt(Math.sqrt(Math.pow(denoisedKeypoint.x - keypoint.x, 2.0) + Math.pow(denoisedKeypoint.y - keypoint.y, 2.0)) / DISTANCE_THRESHOLD));
              denoisedKeypoint.x = (1.0 - factor) * denoisedKeypoint.x + factor * keypoint.x;
              denoisedKeypoint.y = (1.0 - factor) * denoisedKeypoint.y + factor * keypoint.y;
            }
          }

          let firstEl = null;
          let scaleFactor = 1.0;
          for (
            let keypointIndex = 0;
            keypointIndex < face.keypoints.length;
            keypointIndex++
          ) {
            let el = null;
            if (pointIndex >= self.points.length) {
              el = document.createElement("a-cone");
              const pointSize = 0.005;
              el.object3D.scale.set(pointSize, pointSize, pointSize);

              self.el.appendChild(el);
              self.points.push(el);
            } else {
              el = self.points[pointIndex];
            }

            let boneName = bonePrefix + keypointIndex;
            el.setAttribute("id", boneName);
            el.setAttribute("visible", self.debugPoints);
            el.setAttribute("color", "#0000FF");

            if (el) {
              const keypoint = self.denoisedKeypoints[keypointIndex + NUM_FACE_KEYPOINTS * faceIndex];
              if (keypointIndex == 0) {
                console.log(keypoint.z);
              }
              let pv = getRay(keypoint);
              el.object3D.position.set(
                pv[0].x + pv[1].x * 1.0,
                pv[0].y + pv[1].y * 1.0,
                pv[0].z + pv[1].z * 1.0
              );

              // const keypoint3D0 = face.keypoints3D[0];
              // if (keypointIndex === 0) {
              //   const keypoint0 = self.denoisedKeypoints[0 + NUM_HAND_KEYPOINTS * handIndex];
              //   let pv0 = getRay(keypoint0);
              //   let point0 = pv0[0].clone().add(pv0[1]);
              //   const point0NDC = self.videoSource.keypointToNDC(keypoint0);
              //   const rootPoint = point0.clone();
              //   point0.project(self.el.camera);

              //   for (
              //     let nextKeypointIndex = 1;
              //     nextKeypointIndex < hand.keypoints.length;
              //     nextKeypointIndex++
              //   ) {
              //     const nextKeypoint = self.denoisedKeypoints[nextKeypointIndex + NUM_HAND_KEYPOINTS * handIndex];
              //     const nextKeypoint3D = hand.keypoints3D[nextKeypointIndex];
              //     let point1 = rootPoint.clone();
              //     point1.setX(point1.x + (nextKeypoint3D.x - keypoint3D0.x));
              //     point1.setY(point1.y - (nextKeypoint3D.y - keypoint3D0.y));
              //     point1.setZ(point1.z - (nextKeypoint3D.z - keypoint3D0.z));
              //     point1.project(self.el.camera);

              //     const point1NDC = self.videoSource.keypointToNDC(nextKeypoint);
              //     let denom =
              //       Math.pow(point1NDC.x - point0NDC.x, 2) +
              //       Math.pow(point1NDC.y - point0NDC.y, 2);
              //     scaleFactor = Math.sqrt(
              //       (Math.pow(point1.x - point0.x, 2) +
              //         Math.pow(point1.y - point0.y, 2)) /
              //         denom
              //     );
              //     // FIXME: keep searching until we find a good denom?
              //     break;
              //   }

              //   el.object3D.position.set(
              //     pv0[0].x + pv0[1].x * scaleFactor,
              //     pv0[0].y + pv0[1].y * scaleFactor,
              //     pv0[0].z + pv0[1].z * scaleFactor
              //   );

              //   firstEl = el;
              // } else {
              //   const keypoint3D1 = hand.keypoints3D[keypointIndex];
              //   el.object3D.position.copy(firstEl.object3D.position);
              //   el.object3D.position.setX(
              //     el.object3D.position.x + (keypoint3D1.x - keypoint3D0.x)
              //   );
              //   el.object3D.position.setY(
              //     el.object3D.position.y - (keypoint3D1.y - keypoint3D0.y)
              //   );
              //   el.object3D.position.setZ(
              //     el.object3D.position.z - (keypoint3D1.z - keypoint3D0.z)
              //   );

              //   // Project onto the ray
              //   // FIXME: why?  scaleFactor must be wrong?
              //   {
              //     const keypoint = self.denoisedKeypoints[keypointIndex + NUM_HAND_KEYPOINTS * handIndex];
              //     let pv = getRay(keypoint);
              //     let point = pv[0].clone();
              //     let ray = pv[1].clone();

              //     let toObject = el.object3D.position.clone().sub(pv[0]);
              //     el.object3D.position.copy(
              //       point.add(
              //         ray.multiplyScalar(toObject.dot(ray) / ray.dot(ray))
              //       )
              //     );
              //   }
              // }
            }

            pointIndex++;
          }

          // for (let neighborConnection of neighborConnections) {
          //   const jointIndex = neighborConnection[0];
          //   let el = document.querySelector(
          //     "#" + bonePrefix + JOINT_NAMES[jointIndex]
          //   );
          //   let target = document.querySelector(
          //     "#" + bonePrefix + JOINT_NAMES[neighborConnection[1]]
          //   );

          //   let neighbor1 = document.querySelector(
          //     "#" + bonePrefix + JOINT_NAMES[neighborConnection[2][0]]
          //   );
          //   let neighbor2 = document.querySelector(
          //     "#" + bonePrefix + JOINT_NAMES[neighborConnection[2][1]]
          //   );
          //   if (el && neighbor1 && neighbor2 && target) {
          //     let v1 = neighbor1.object3D.position.clone();
          //     v1.sub(el.object3D.position);

          //     let v2 = neighbor2.object3D.position.clone();
          //     v2.sub(el.object3D.position);

          //     let up = v1.cross(v2);
          //     up.normalize();

          //     if (!left) {
          //       up.multiplyScalar(-1.0);
          //     }

          //     el.object3D.quaternion.setFromRotationMatrix(
          //       lookAt(el.object3D.position, target.object3D.position, up)
          //     );

          //     if (jointIndex !== 0) {
          //       let lastQuaternion = null;
          //       for (let fingerIndex = 1; fingerIndex < 3; fingerIndex++) {
          //         let nextEl = document.querySelector(
          //           "#" + bonePrefix + JOINT_NAMES[jointIndex + fingerIndex]
          //         );
          //         let nextTarget = document.querySelector(
          //           "#" + bonePrefix + JOINT_NAMES[jointIndex + fingerIndex + 1]
          //         );
          //         if (nextEl && nextTarget) {
          //           nextEl.object3D.quaternion.setFromRotationMatrix(
          //             lookAt(
          //               nextEl.object3D.position,
          //               nextTarget.object3D.position,
          //               up
          //             )
          //           );
          //           lastQuaternion = nextEl.object3D.quaternion;
          //         }
          //       }

          //       let tipEl = document.querySelector(
          //         "#" + bonePrefix + JOINT_NAMES[jointIndex + 3]
          //       );
          //       if (tipEl) {
          //         tipEl.object3D.quaternion.copy(lastQuaternion);
          //       }
          //     }
          //   }
          // }
        }
      }

      // Clear old elements
      for (; pointIndex < self.points.length; pointIndex++) {
        const point = self.points[pointIndex];
        point?.parentNode?.removeChild(point);
      }
      self.points.length = faces ? NUM_FACE_KEYPOINTS * faces.length : 0;

      // Copy joint transforms to cylinders
      // for (let cylIndex = 0; cylIndex < self.points.length; cylIndex++) {
      //   let cyl = self.cyls[cylIndex];
      //   if (cyl) {
      //     let point = self.points[cylIndex];
      //     cyl.object3D.position.copy(point.object3D.position);
      //     cyl.object3D.quaternion.copy(point.object3D.quaternion);
      //     cyl.object3D.scale.copy(point.object3D.scale);

      //     let nextPoint = self.points[cylIndex !== 0 ? cylIndex + 1 : 9];
      //     let height =
      //       point.object3D.position.distanceTo(nextPoint.object3D.position) /
      //       point.object3D.scale.y;
      //     // Spheres are twice as big
      //     let ratio = 0.5;
      //     if (cylIndex === 0) {
      //       height *= 0.5;
      //       ratio *= 2;
      //     }
      //     cyl.children[0].object3D.scale.setY(height);
      //     cyl.children[0].object3D.position.setZ(-ratio * height);
      //   }
      // }

      // if (isMobileOrVR) {
      //   let ring = document.querySelector("[ring]");
      //   if (ring) {
      //     ring.object3D.visible = self.points.length > 0;
      //   }
      // }
    });
  }
});