import * as AFRAME from "aframe";
// import * as $ from "jquery";

// WebXR requires HTTPS, so the site doesn't work if someone manually enters
// the URL and ends up using HTTP. To work around this, force redirect from
// http to https for non-localhost addresses.
if (
  window.location.protocol === "http:" &&
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "127.0.0.1" &&
  window.location.hostname !== "[::1]"
) {
  window.location = window.location.href.replace("http:", "https:");
}

export let replaceHTMLIncludes = function () {
  let scene = document.querySelector("#scene");
  if (scene) {
    scene.addEventListener("loaded", function () {
        // HTML includes
        // const includes = $("[data-include]");
        // $.each(includes, function (index) {
        //   const element = $(this);
        //   const file = element.data("include") + ".html";
        //   $.get(file, function (data) {
        //       element.replaceWith($(data));
        //   });
        // });

        scene.renderer.sortObjects = true;
    });
  }
}

export let isMobileOrVR = AFRAME.utils.device.isMobile() || AFRAME.utils.device.isMobileVR();

// Key presses
let keyPressedSet = new Set();

function registerKeyDown(keyName) {
  if (!keyPressedSet.has(keyName)) {
    keyPressedSet.add(keyName);
  }

  if (keyName === "Control") {
    document.isEditting = true;
  }
}

function registerKeyUp(keyName) {
  keyPressedSet.delete(keyName);

  if (keyName === "Control") {
    document.isEditting = false;
  }
}

document.isKeyPressed = function (keyName) {
  return keyPressedSet.has(keyName);
};

function convertKeyName(keyName) {
  if (keyName === " ") {
    return "Space";
  } else if (keyName.length === 1) {
    return keyName.toUpperCase();
  } else {
    return keyName;
  }
}

document.addEventListener("keydown", function (eventData) {
  if (!eventData.key) {
    return;
  }
  registerKeyDown(convertKeyName(eventData.key));
});

document.addEventListener("keyup", function (eventData) {
  if (!eventData.key) {
    return;
  }
  registerKeyUp(convertKeyName(eventData.key));
});

export function removeObject3D(object3D, deleteResources) {
  let childrenToRemove = [];
  object3D.traverse(function (node) {
    if (!(node instanceof THREE.Object3D)) {
      return false;
    }

    if (deleteResources) {
      if (node.geometry) {
        node.geometry.dispose();
      }

      if (node.material) {
        let materialDispose = function (material) {
          for (const property of Object.values(material)) {
            if (property instanceof THREE.Texture) {
              property.dispose();
            }
          }
          material.dispose();
        };
        if (node.material instanceof Array) {
          node.material.forEach((material) => materialDispose(material));
        } else {
          materialDispose(node.material);
        }
      }
    }
    childrenToRemove.push(node);
  });

  for (let child of childrenToRemove) {
    child.removeFromParent();
  }

  return true;
}

export function rematerial(self, condition, rematerialFunction, rematerialSource) {
  let rematerialDepth = 0;
  self.el.object3D.traverse(function (node) {
    // Don't override other rematerials
    if (node.rematerialSource !== rematerialSource &&
        node.rematerialDepth && node.rematerialDepth < rematerialDepth) {
      return;
    } else {
      node.rematerialDepth = rematerialDepth;
      node.rematerialSource = rematerialSource;
    }

    if (condition(node)) {
      rematerialFunction(node, self);
    }
    rematerialDepth++;
  });
};

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

// From: https://www.w3schools.com/howto/howto_js_draggable.asp
export function makeElementsDraggable() {
  function keepInWindow(el) {
    el.style.top = Math.min(Math.max(parseInt(el.style.top), 0), el.parentElement.offsetHeight - el.offsetHeight) + "px";
    el.style.left = Math.min(Math.max(parseInt(el.style.left), 0), el.parentElement.offsetWidth - el.offsetWidth) + "px";
  }

  function dragElement(el) {
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

    if (document.getElementById(el.id + "header")) {
      // if present, the header is where you move the DIV from:
      document.getElementById(el.id + "header").onmousedown = dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      el.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      // e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      if (document.activeElement.nodeName === "INPUT" ||
          document.activeElement.nodeName === "SELECT" ||
          document.activeElement.nodeName === "TEXTAREA") {
        return;
      }

      e = e || window.event;
      // e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      let style = window.getComputedStyle(el);
      // set the element's new position:
      el.style.top = (el.offsetTop - parseInt(style.marginTop) - pos2) + "px";
      el.style.left = (el.offsetLeft - parseInt(style.marginLeft) - pos1) + "px";
      keepInWindow(el, style);
      localStorage[el.id + "DragPosTop"] = el.style.top;
      localStorage[el.id + "DragPosLeft"] = el.style.left;
    }

    function closeDragElement(e) {
      e = e || window.event;
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  // Make the DIV elements draggable:
  let draggables = document.querySelectorAll(".draggable");
  for (let draggable of draggables) {
    if (localStorage[draggable.id + "DragPosTop"] && localStorage[draggable.id + "DragPosLeft"]) {
      draggable.style.top = localStorage[draggable.id + "DragPosTop"];
      draggable.style.left = localStorage[draggable.id + "DragPosLeft"];
    }
    window.addEventListener("resize", () => { keepInWindow(draggable); });
    dragElement(draggable);
  }
}