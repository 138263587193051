import "aframe";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { replaceHTMLIncludes } from "../gydence/utils";
import "../gydence/anchor";
import "../gydence/animations";
import "../gydence/camera";
import "../gydence/cameraSource";
import "../gydence/container";
import "../gydence/follow";
import "../gydence/grid";
import "../gydence/imageSource";
import "../gydence/list";
import "../gydence/logo";
import "../gydence/material";
import "../gydence/model";
import "../gydence/networking";
import "../gydence/ring";
import "../gydence/selectionTool";
import "../gydence/spawner";
import "../gydence/stencil";
import "../gydence/testing";
import "../gydence/text3d";
import "../gydence/tfjs-hand-tracking";
import "../gydence/tfjs-face-tracking";

import SignIn, { supabase } from "./signin";
import EntityError from "./entityError";
import gydenceAPI from "./api";

import styles from "./index.module.css";

export default function GydencePreview() {
  const [setup, setSetup] = useState(false);
  const { siteID } = useParams();

  // Login state
  const [loggedIn, setLoggedIn] = useState(true);

  // Scene/entity state maps
  const [scenePropertyMap, setScenePropertyMap] = useState({});
  const [entityPropertyMap, setEntityPropertyMap] = useState({});
  const [entityErrorMap, setEntityErrorMap] = useState({});

  // 2D UI
  const [overlayElements, setOverlayElements] = useState(undefined);
  const [cssProps, setCSSProps] = useState(undefined);

  // Scripts
  const [scripts, setScripts] = useState([]);
  const [hasLoadedScripts, setHasLoadedScripts] = useState(false);

  let setEntityErrorHandler = (error) => {
    // Keep track of any errors
    let currentEntityErrorMap = {...entityErrorMap};
    if (!(selectedEntity in currentEntityErrorMap)) {
      currentEntityErrorMap[selectedEntity] = [];
    }
    currentEntityErrorMap[selectedEntity].push(error);
    setEntityErrorMap(currentEntityErrorMap);
  };

  useEffect(() => {
    const injectedCSSID = "injectedCSS";
    const existingStyleSheet = document.querySelector("#" + injectedCSSID);
    let stylesheet = existingStyleSheet ?? document.createElement("style");
    stylesheet.id = injectedCSSID;
    stylesheet.innerHTML = cssProps;
    if (!existingStyleSheet) {
      document.head.appendChild(stylesheet);
    }
  }, [cssProps]);

  useEffect(() => {
    if (!document.querySelector("#api")) {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.id = "api";
      scriptElement.className = "api";
      scriptElement.innerHTML = gydenceAPI({
        currentSite: siteID,
        isEditing: false,
        isPublic: false,
      });
      document.head.appendChild(scriptElement);
    }

    {
      const injectedScriptID = "injectedScript";
      for (let script of scripts) {
        let scriptElement = document.createElement("script");
        if (script.module) {
          scriptElement.type = "module";
        } else {
          scriptElement.type = "text/javascript";
        }
        scriptElement.id = script.id;
        scriptElement.className = injectedScriptID;
        if (script.url) {
          scriptElement.src = script.url;
        } else if (script.script) {
          scriptElement.innerHTML = script.script;
        }
        document.head.appendChild(scriptElement);
      }
    }

    setHasLoadedScripts(true);
  }, [scripts]);

  const fetchData = async () => {
    {
      let { data } = await supabase.auth.getUser();
      setLoggedIn(data?.user?.email);
    }

    {
      let { data } = await supabase.from("sites").select("*").eq("id", siteID);
      if (data?.length === 1) {
        let scenePropertyMapData = {};
        let entityPropertyMapData = {};

        let overlayElementsData = undefined;
        let cssPropsData = undefined;

        let scriptsData = [];

        if (data[0].data?.scene) {
          scenePropertyMapData = data[0].data.scene;
        }

        if (data[0].data?.entities) {
          for (let entity of data[0].data.entities) {
            entityPropertyMapData[entity.id] = entity;
          }
        }

        if (data[0].data?.overlay) {
          overlayElementsData = data[0].data.overlay;
        }

        if (data[0].data?.css) {
          cssPropsData = data[0].data.css;
        }

        if (data[0].data?.scripts) {
          scriptsData = data[0].data.scripts;
        }

        setScenePropertyMap(scenePropertyMapData);
        setEntityPropertyMap(entityPropertyMapData);
        setOverlayElements(overlayElementsData);
        setCSSProps(cssPropsData);

        if (scriptsData.length > 0) {
          setScripts(scriptsData);
        } else {
          setHasLoadedScripts(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!setup) {
      fetchData();

      supabase.auth.getSession().then((result) => {
        let currentSession = result?.data?.session;
        supabase.auth.onAuthStateChange((event, session) => {
          // Supabase sends a SIGNED_IN event if you've switched to a different
          // tab or window and back.  Filter those out.
          if (session?.user?.id === currentSession?.user?.id) {
            return;
          }

          currentSession = session;
          fetchData();
        });
      });

      setSetup(true);
      replaceHTMLIncludes();
    }
  }, []);

  if (!loggedIn) {
    return (
      <SignIn/>
    )
  }

  return (
    <>
      {/* TODO: handle scene error */}
      <a-scene
        {...(hasLoadedScripts ? scenePropertyMap : {})}
      >
        {/* <!-- Camera(s) + UI --> */}
        <a-entity id="camera-container" cursor="rayOrigin: mouse">
          <a-entity id="ar-camera" camera=""></a-entity>
          <a-entity id="controls" class="removeOnMobile">
            <a-entity id="camera" camera=""></a-entity>
          </a-entity>
        </a-entity>

        {/* <!-- Content --> */}
        {hasLoadedScripts ? Object.keys(entityPropertyMap).map((entity) => {
            let props = {...entityPropertyMap[entity]};
            props["id"] = "entity_" + props["id"];
            if (props["parent"]) {
              props["parent"] = "entity_" + props["parent"];
            }
            return (
              <EntityError
                key={["error_", entity].join("")}
                entityProps={entityPropertyMap[entity]}
                entityErrors={entityErrorMap[entity]}
                setEntityError={setEntityErrorHandler}
              >
                <a-entity key={entity} {...props}></a-entity>
              </EntityError>
            )
          }) : <></>
        }
      </a-scene>

      <div
        id="overlayDOM"
        className={styles.block}
      >
        {(hasLoadedScripts && overlayElements) ?
          <div
            className={styles.block}
            style={{ position: "absolute", width: "100vw", height: "100vh" }}
            dangerouslySetInnerHTML={{ __html: overlayElements }}
          />
          : <></>
        }
      </div>
    </>
  )
}