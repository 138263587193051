import "aframe";

AFRAME.registerComponent("glass", {
  init: function () {
    let self = this;
    this.el.addEventListener("model-loaded", function () { self.glass(self); });
    this.el.addEventListener("gltf-loaded", function () { self.glass(self); });
    self.glass(self);
  },

  glass: function (self) {
    self.el.object3D.traverse(function (node) {
      node.material = new THREE.MeshPhysicalMaterial({
        roughness: 0.25,
        transmission: 1,
        thickness: 0.1,
      });
    });
  },
});
