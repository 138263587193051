import "aframe";
import { useEffect, useState } from "react";

import "../gydence/utils";
import "../gydence/anchor";
import "../gydence/animations";
import "../gydence/camera";
import "../gydence/cameraSource";
import "../gydence/container";
import "../gydence/follow";
import "../gydence/grid";
import "../gydence/imageSource";
import "../gydence/list";
import "../gydence/logo";
import "../gydence/material";
import "../gydence/model";
import "../gydence/networking";
import "../gydence/ring";
import "../gydence/selectionTool";
import "../gydence/spawner";
import "../gydence/stencil";
import "../gydence/testing";
import "../gydence/text3d";
import "../gydence/tfjs-hand-tracking";
import "../gydence/tfjs-face-tracking";

import EntityError from "./entityError";

import styles from "./index.module.css";

export default function EditorView() {
  // Site/entity selection
  const [selectedObject, setSelectedObject] = useState(undefined);

  // Scene/entity state maps
  const [scenePropertyMap, setScenePropertyMap] = useState({});
  const [environmentEntities, setEnvironmentEntities] = useState(undefined);
  const [entityPropertyMap, setEntityPropertyMap] = useState({});
  const [entityErrorMap, setEntityErrorMap] = useState({});

  // 2D UI
  const [overlayElements, setOverlayElements] = useState(undefined);
  const [cssProps, setCSSProps] = useState(undefined);

  let setEntityErrorHandler = (error) => {
    // TODO: this should accept the id, which would also make it work for the environment
    // Keep track of any errors
    let currentEntityErrorMap = {...entityErrorMap};
    if (!(selectedObject in currentEntityErrorMap)) {
      currentEntityErrorMap[selectedObject] = [];
    }
    currentEntityErrorMap[selectedObject].push(error);
    setEntityErrorMap(currentEntityErrorMap);
  };

  let messageHandler = (event) => {
    if (!event.data.type) {
      return;
    }

    switch (event.data.type) {
      case "data-update":
        setScenePropertyMap(event.data.scenePropertyMap);
        setEntityPropertyMap(event.data.entityPropertyMap);
        setOverlayElements(event.data.overlayElements);
        setCSSProps(event.data.cssProps);
        break;
      case "environment-update":
        setEnvironmentEntities(event.data.environment);
      case "select-object":
        setSelectedObject(event.data.selectedObject);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const injectedCSSID = "injectedCSS";
    const existingStyleSheet = document.querySelector("#" + injectedCSSID);
    let stylesheet = existingStyleSheet ?? document.createElement("style");
    stylesheet.id = injectedCSSID;
    stylesheet.innerHTML = cssProps;
    if (!existingStyleSheet) {
      document.head.appendChild(stylesheet);
    }
  }, [cssProps]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    }
  }, []);

  return (
    <>
      {/* TODO: handle scene error */}
      <a-scene
        {...scenePropertyMap}
      >
        {/* <div data-include="views/editor" /> */}
        {/* <!-- Selection Tool Tool --> */}
        <a-entity
          id="selectionTool"
          selection-tool={(selectedObject && (selectedObject !== "Scene")) ? ("target: #entity_" + selectedObject) : "target: ;"}
        ></a-entity>

        {/* <!-- Camera(s) + UI --> */}
        <a-entity id="camera-container" gydence-controls="" cursor="rayOrigin: mouse">
          <a-entity id="ar-camera" camera=""></a-entity>
          <a-entity id="controls" class="removeOnMobile">
            <a-entity id="camera" camera=""></a-entity>
          </a-entity>
        </a-entity>

        {/* <!-- Person --> */}
        <a-entity id="person" class="removeOnMobile" position="0 1.6 1">
          <a-entity
            id="hmd"
            position="0 0 -0.25"
            scale="0.1 0.1 0.1"
            gltf-model={process.env.PUBLIC_URL + "/assets/glasses.glb"}
            rematerial="material: #C27BA0"
          ></a-entity>
          <a-entity
            id="leftHand"
            position="-0.27 -0.5 -0.4"
            rotation="0 -65 50"
            scale="1 1 -1"
            gltf-model={process.env.PUBLIC_URL + "/assets/hand.glb"}
            rematerial="material: #ffb3d8, emissive #444"
          ></a-entity>
          <a-entity
            id="rightHand"
            position="0.27 -0.5 -0.4"
            rotation="0 -115 50"
            gltf-model={process.env.PUBLIC_URL + "/assets/hand.glb"}
            rematerial="material: #ffb3d8, emissive #444"
          ></a-entity>
        </a-entity>

        {/* <!-- Environment --> */}
        {environmentEntities ?
          environmentEntities.map((entity, i) => {
            entity.id = i;
            return <a-entity key={entity.id} {...entity}></a-entity>
          })
          : <a-entity>
              <a-entity grid="minorDivisions:30;majorDivisions:5"></a-entity>
              <a-entity light="type:ambient;color:#BBB"></a-entity>
              <a-entity light="type:directional;color:#FFF;intensity:0.6" position="-0.5 1 1"></a-entity>
              <a-sky color="#FFFFFF"></a-sky>
          </a-entity>
        }

        {/* <!-- Content --> */}
        {entityPropertyMap ?
          Object.keys(entityPropertyMap).map((entity) => {
            let props = {...entityPropertyMap[entity]};
            props["id"] = "entity_" + props["id"];
            if (props["parent"]) {
              props["parent"] = "entity_" + props["parent"];
            }
            return (
              <EntityError
                key={["error_", entity].join("")}
                entityProps={entityPropertyMap[entity]}
                entityErrors={entityErrorMap[entity]}
                setEntityError={setEntityErrorHandler}
              >
                <a-entity key={entity} {...props}></a-entity>
              </EntityError>
            )
          })
          : <></>
        }
      </a-scene>

      <div
        id="overlayDOM"
        className={styles.block}
      >
        {overlayElements ?
          <div
            className={styles.block}
            style={{ position: "absolute", width: "100vw", height: "100vh" }}
            dangerouslySetInnerHTML={{ __html: overlayElements }}
          />
          : <></>
        }
      </div>
    </>
  )
}