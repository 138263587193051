import styles from "../pages/index.module.css";

export default function GridList({ selected, list, numColumns, onSelectHandler, getGridItem, ...rest }) {
  return (
    <div
      {...rest}
    >
      {[...Array(Math.floor(list.length / numColumns + 1))].map((_, i) => {
        return <div
          key={["row_", i * numColumns].join("")}
          className={styles.templateRow}
          style={{
            flex: (100 / numColumns) + "%",
            flexGrow: "initial"
          }}
        >
          {[...Array(Math.min(list.length - i * numColumns, numColumns))].map((y, j) => {
            const index = i * numColumns + j;
            const item = list[index];
            return <div
              key={["col_", index].join("")}
              className={[styles.templateColumn, styles.unselectable].join(" ")}
              style={{
                position: "relative",
                width: "100px",
                height: "100px",
                margin: "0 auto",
                backgroundColor: item.id === selected ? "rgba(70, 70, 70, 0.7)" : undefined,
                borderStyle: "solid",
                borderWidth: "2px",
                borderColor: "#444",
                borderRadius: "10px"
              }}
              onClick={() => onSelectHandler(item.id)}
            >
              {getGridItem(item)}
            </div>
          })}
        </div>
      })}
    </div>
  )
};