import "aframe";

AFRAME.registerComponent("gydence-model", {
  schema: { type: "model" },

  init: function () {
    this.model = null;
    this.loader = new THREE.ObjectLoader();
  },

  modelLoaded: function (self, model) {
    self.clearModel();

    // FIXME: handle this rotation somehow
    model.rotation.set(-0.5 * Math.PI, 0, 0);
    self.model = model;
    self.el.setObject3D("mesh", self.model);
    self.el.emit("model-loaded", { format: "3dm", model: self.model });
  },

  modelFailed: function (self, error) {
    var message =
      error && error.message ? error.message : "Failed to load 3dm model";
    console.warn(message);
    self.el.emit("model-error", { format: "3dm", src: self.data });
  },

  clearModel: function () {
    if (this.model) {
      this.el.removeObject3D("mesh");
    }
  },

  update: function () {
    if (!this.data) {
      this.clearModel();
      return;
    }

    let self = this;
    let loader = new THREE.FileLoader();
    loader.setResponseType("json");
    loader.load(
      this.data,
      (json) => { self.parse(json); },
      undefined,
      (error) => { self.modelFailed(self, error); }
    );
  },

  parse: function (data) {
    let self = this;
    switch (data.version) {
      case 1:
        this.loader.parse(
          JSON.parse(decodeURIComponent(escape(window.atob(data.data)))),
          function (model) { self.modelLoaded(self, model); }
        );
        break;
      default:
        break;
    };
  },

  remove: function () {
    this.clearModel();
  },
});