import { useEffect, useState, useRef } from "react";

import { supabase } from "./signin";
import GridList from "../components/gridList";

import styles from "./index.module.css";

export default function EditSiteUI({ newSite, sites, currentSite, siteName, isCreator, siteEditors, templates, environment, environments,
    getSitesHandler, setCurrentSiteHandler, updateSiteNameHandler, updateSiteEditorsHandler, setEditorModeHandler,
    setSelectedObjectHandler, addEntityHandler, updateScenePropHandler, setEnvironmentHandler,
    updateOverlayElementHandler, updateCSSHandler, addScriptHandler, addAppHandler, setTargetListing }) {
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [selectedEnvironment, setSelectedEnvironment] = useState(!newSite ? environment : undefined);
  const nameRef = useRef(undefined);
  const editorsRef = useRef(undefined);

  useEffect(() => {
    if (newSite && environments.length > 0) {
      const defaultEnvironment = environments.find((environment) => environment.name === "Grid (Default)");
      if (defaultEnvironment) {
        setSelectedEnvironment(defaultEnvironment.id);
      } else {
        setSelectedEnvironment(environments[0].id);
      }
    }
  }, [environments]);

  let allTemplates = templates.slice();
  allTemplates.unshift({
    id: undefined,
    name: "No Template"
  });

  const createSite = () => {
    supabase.rpc("add_site", {
      name: nameRef.current.value,
      editors: editorsRef.current.value.replaceAll(" ", ""),
    }).then(async (result) => {
      console.log(result);
      if (!result.error) {
        await getSitesHandler();

        if (allTemplates.length > 0 && selectedTemplate) {
          const template = allTemplates.find((template) => template.id === selectedTemplate).data;

          if ("entities" in template) {
            await addEntityHandler(template.entities, result.data, () => setSelectedObjectHandler(undefined));
          }

          if ("scene" in template) {
            for (const scenePropName of Object.keys(template.scene)) {
              updateScenePropHandler(scenePropName, template.scene[scenePropName], result.data);
            }
          }

          if ("overlay" in template) {
            updateOverlayElementHandler(template.overlay, result.data);
          }

          if ("css" in template) {
            updateCSSHandler(template.css, result.data);
          }

          if ("scripts" in template) {
            for (const script of template.scripts) {
              await addScriptHandler(script, () => setSelectedObjectHandler(undefined), result.data);
            }
          }

          if ("apps" in template) {
            for (const app of template.apps) {
              await addAppHandler(app, () => setSelectedObjectHandler(undefined), result.data);
            }
          }
        }

        if (environments.length > 0 && selectedEnvironment) {
          const environment = environments.find((environment) => environment.id === selectedEnvironment);
          setEnvironmentHandler(environment.id, result.data);
        }

        setCurrentSiteHandler(result.data);
        setEditorModeHandler("Entities");
      }
    });
  };

  const deleteAssets = async (folders) => {
    let assets = [];
    for (const folder of folders) {
      let { data } = await supabase.storage.from("storage").list("sites/" + currentSite + "/" + folder + "/");
      console.log(data);
      if (data) {
        data.map((asset) => {
          assets.push("sites/" + currentSite + "/" + folder + "/" + asset.name);
        });
      }
    }

    if (assets.length > 0) {
      return supabase.storage.from("storage").remove(assets);
    }

    return undefined;
  };

  const removePublicSite = async () => {
    deleteAssets(["public"]);

    await supabase.from("public").delete().eq("id", currentSite)
      .then((result) => {
        console.log(result);
      });
  };

  const deleteSite = async () => {
    let promise = deleteAssets(["public", "private"]);

    if (promise) {
      // We need to actually wait for the assets to be deleted first, otherwise
      // the site might not be in our sites list anymore and the asset delete RLS policy will
      // fail.
      promise.then(() => {
        // Deleting from sites will cascade delete from public
        supabase.from("sites").delete().eq("id", currentSite)
          .then(async (result) => {
            console.log(result);
            if (!result.error) {
              const newSites = await getSitesHandler();
              setCurrentSiteHandler(newSites?.[0]?.id);
              setEditorModeHandler("Entities");
            }
          });
        }
      );
    }
  };

  const NUM_COLUMNS = 4;

  return (
    <div
      className={styles.ui}
      style={{
        width: "700px",
        height: "600px",
        color: "#CCCCCC",
        background: "rgba(50, 50, 50, 0.7)",
        overflow: "auto",
        position: "relative"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {sites.length > 0 ?
        <button
          className={[styles.topRight, styles.unselectable].join(" ")}
          onClick={() => setEditorModeHandler("Entities")}
        >
          x
        </button>
        : <></>
      }
      <p className={styles.unselectable}>{newSite ? "Create a New Project" : "Edit Project"}</p>
      <label className={styles.unselectable} htmlFor="siteName">Name: </label>
      <input
        id="siteName"
        type="text"
        ref={nameRef}
        defaultValue={!newSite ? siteName : undefined}
        onBlur={!newSite ? () => {updateSiteNameHandler(nameRef.current.value)} : undefined}
      ></input>
      <br className={styles.unselectable} />
      <br className={styles.unselectable} />
      <label className={styles.unselectable} htmlFor="siteEditors" >Editors (Optional, comma separated): </label>
      <input
        id="siteEditors"
        type="text"
        ref={editorsRef}
        defaultValue={!newSite ? siteEditors : undefined}
        onBlur={!newSite ? () => {updateSiteEditorsHandler(editorsRef.current.value.replaceAll(" ", ""))} : undefined}
      ></input>
      {newSite ?
        <>
          <p className={styles.unselectable}>Template:</p>
          <GridList
            selected={selectedTemplate}
            list={allTemplates}
            numColumns={NUM_COLUMNS}
            onSelectHandler={setSelectedTemplate}
            getGridItem={(item) => {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {item.name}

                  {item.id ?
                    <button
                      className={[styles.bottomRight, styles.unselectable].join(" ")}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditorModeHandler("Marketplace");
                        setTargetListing(item.listing);
                      }}
                    >
                      {"Listing >"}
                    </button>
                    : <></>
                  }
                </div>
              )
            }}
            style={{
              height: "150px",
              overflow: "auto"
            }}
          />
        </>
        : <></>
      }
      <p className={styles.unselectable}>Environment:</p>
      <GridList
        selected={selectedEnvironment}
        list={environments}
        numColumns={NUM_COLUMNS}
        onSelectHandler={(id) => {
          if (!newSite) {
            setEnvironmentHandler(id);
          }
          setSelectedEnvironment(id);
        }}
        getGridItem={(item) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%"
              }}
            >
              {item.name}

              <button
                className={[styles.bottomRight, styles.unselectable].join(" ")}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditorModeHandler("Marketplace");
                  setTargetListing(item.listing);
                }}
              >
                {"Listing >"}
              </button>
            </div>
          )
        }}
        style={{
          height: "150px",
          overflow: "auto"
        }}
      />
      {newSite ?
        <button
          className={styles.unselectable}
          onClick={createSite}
        >
          Create Project
        </button>
        :
        <>
          <button
            className={styles.unselectable}
            onClick={removePublicSite}
          >
            Remove Public Project
          </button>
          <br />
          <br />
          {isCreator ?
            <button
              className={styles.unselectable}
              style={{
                backgroundColor: "red"
              }}
              onClick={deleteSite}
            >
              Delete Project
            </button>
            : <></>
          }
        </>
      }
    </div>
  )
}