import "aframe";

AFRAME.registerComponent("logo", {
  schema: {
    frac: {
      default: 1,
      type: "number"
    }
  },

  init: function () {
    let self = this;
    let forwardModelLoaded = function () {
      const event = new CustomEvent("model-loaded", {});
      self.el.dispatchEvent(event);
    };

    // Icosahedron
    {
      this.geometry = new THREE.IcosahedronGeometry(0.5);
      const originalPositions = this.geometry.getAttribute("position").array;
      const originalNormals = this.geometry.getAttribute("normal").array;
      const originalUVs = this.geometry.getAttribute("uv").array;

      let positions = [];
      let normals = [];
      let colors = [];
      let uvs = [];

      this.centers = [];

      const originalLength = originalPositions.length / 9;
      for (let i = 0; i < originalLength; i++) {
        let positionIndex = i * 9;
        let uvIndex = i * 6;

        let center = new THREE.Vector3(
          originalPositions[positionIndex + 0],
          originalPositions[positionIndex + 1],
          originalPositions[positionIndex + 2]
        );
        center.add(
          new THREE.Vector3(originalPositions[positionIndex + 3], originalPositions[positionIndex + 4], originalPositions[positionIndex + 5])
        );
        center.add(
          new THREE.Vector3(originalPositions[positionIndex + 6], originalPositions[positionIndex + 7], originalPositions[positionIndex + 8])
        );
        center.divideScalar(3);
        this.centers.push(center.x, center.y, center.z);
        this.centers.push(center.x, center.y, center.z);
        this.centers.push(center.x, center.y, center.z);
        this.centers.push(center.x, center.y, center.z);
        this.centers.push(center.x, center.y, center.z);
        this.centers.push(center.x, center.y, center.z);

        positions.push(originalPositions[positionIndex + 0], originalPositions[positionIndex + 1], originalPositions[positionIndex + 2]);
        positions.push(originalPositions[positionIndex + 3], originalPositions[positionIndex + 4], originalPositions[positionIndex + 5]);
        positions.push(originalPositions[positionIndex + 6], originalPositions[positionIndex + 7], originalPositions[positionIndex + 8]);
        normals.push(originalNormals[positionIndex + 0], originalNormals[positionIndex + 1], originalNormals[positionIndex + 2]);
        normals.push(originalNormals[positionIndex + 3], originalNormals[positionIndex + 4], originalNormals[positionIndex + 5]);
        normals.push(originalNormals[positionIndex + 6], originalNormals[positionIndex + 7], originalNormals[positionIndex + 8]);
        colors.push(originalUVs[uvIndex + 0], 0, originalUVs[uvIndex + 1]);
        colors.push(originalUVs[uvIndex + 2], 0, originalUVs[uvIndex + 3]);
        colors.push(originalUVs[uvIndex + 4], 0, originalUVs[uvIndex + 5]);
        uvs.push(originalUVs[uvIndex + 0], originalUVs[uvIndex + 1]);
        uvs.push(originalUVs[uvIndex + 2], originalUVs[uvIndex + 3]);
        uvs.push(originalUVs[uvIndex + 4], originalUVs[uvIndex + 5]);

        // Add the backface inner triangles with thier own vertex colors
        positions.push(originalPositions[positionIndex + 0], originalPositions[positionIndex + 1], originalPositions[positionIndex + 2]);
        positions.push(originalPositions[positionIndex + 6], originalPositions[positionIndex + 7], originalPositions[positionIndex + 8]);
        positions.push(originalPositions[positionIndex + 3], originalPositions[positionIndex + 4], originalPositions[positionIndex + 5]);
        normals.push(-originalNormals[positionIndex + 0], -originalNormals[positionIndex + 1], -originalNormals[positionIndex + 2]);
        normals.push(-originalNormals[positionIndex + 6], -originalNormals[positionIndex + 7], -originalNormals[positionIndex + 8]);
        normals.push(-originalNormals[positionIndex + 3], -originalNormals[positionIndex + 4], -originalNormals[positionIndex + 5]);
        colors.push(1, 0.75, 0.75);
        colors.push(1, 0.75, 0.75);
        colors.push(1, 0.75, 0.75);
        uvs.push(originalUVs[uvIndex + 0], originalUVs[uvIndex + 1]);
        uvs.push(originalUVs[uvIndex + 4], originalUVs[uvIndex + 5]);
        uvs.push(originalUVs[uvIndex + 2], originalUVs[uvIndex + 3]);
      }
      this.originalPositions = [...positions];

      this.geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(positions, 3)
      );
      this.geometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(normals, 3)
      );
      this.geometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(colors, 3)
      );
      this.geometry.setAttribute(
        "uv",
        new THREE.Float32BufferAttribute(uvs, 2)
      );

      let el = document.createElement("a-entity");
      el.addEventListener("model-loaded", forwardModelLoaded);
      el.id = "icosahedron";
      el.object3D.add(new THREE.Mesh(this.geometry, new THREE.MeshStandardMaterial({
        vertexColors: true,
        roughness: 0.3,
        metalness: 0.5
      })));
      this.el.appendChild(el);
    }

    // G
    {
      let el = document.createElement("a-entity");
      el.addEventListener("model-loaded", forwardModelLoaded);
      el.id = "g";
      el.setAttribute("text3d", { value: "g", size: 0.4, color: "#CCC" });
      el.setAttribute("billboard", {});
      this.el.appendChild(el);
    }

  },

  update: function (oldData) {
    if (oldData.frac !== this.data.frac) {
      let positionsAttribute = this.geometry.getAttribute("position");
      let positions = positionsAttribute.array;
      for (let i = 0; i < positions.length / 3; i++) {
        positions[3 * i + 0] = (1.0 - this.data.frac) * this.centers[3 * i + 0] + this.data.frac * this.originalPositions[3 * i + 0];
        positions[3 * i + 1] = (1.0 - this.data.frac) * this.centers[3 * i + 1] + this.data.frac * this.originalPositions[3 * i + 1];
        positions[3 * i + 2] = (1.0 - this.data.frac) * this.centers[3 * i + 2] + this.data.frac * this.originalPositions[3 * i + 2];
      }
      positionsAttribute.needsUpdate = true;
    }
  },
});
