import { useState, useEffect, useRef, useCallback } from "react";

import { css } from "@codemirror/lang-css";
import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";

import { ControlledInput, ControlledEditor } from "../components/controlledInput";
import { debounce } from "../gydence/utils";

import styles from "./index.module.css";

function SubPropEditor({ schema, prop, subProp, subProps, privateSubProps, updatePropHandler }) {
  const subPropChangeHandler = (subPropName, newSubPropValue) => {
    let constructedValue = "";
    for (const subPropNameAndValue of subProps) {
      if (subPropNameAndValue.name === subPropName) {
        constructedValue += subPropNameAndValue.name + ":" + newSubPropValue + ";";
      } else {
        constructedValue += subPropNameAndValue.name + ":" + subPropNameAndValue.value + ";";
      }
    }
    updatePropHandler(prop, constructedValue);
  };

  const subPropDeleteHandler = (subPropName) => {
    let constructedValue = "";
    for (const subPropNameAndValue of subProps) {
      if (subPropNameAndValue.name !== subPropName) {
        constructedValue += subPropNameAndValue.name + ":" + subPropNameAndValue.value + ";";
      }
    }
    updatePropHandler(prop, constructedValue);
  };

  const isPrivateSubProp = privateSubProps.indexOf(subProp.name) !== -1;

  let field = <></>;
  if (schema[subProp.name]?.oneOf) {
    const oneOf = schema[subProp.name].oneOf;
    const buildOption = (oneOfOption) => {
      return (
        <option
          key={["option_", prop, "_", subProp.name, "_", oneOfOption].join("")}
          className={styles.unselectable}
          value={oneOfOption}
        >
          {oneOfOption}
        </option>
      )
    };

    field = <select
      className={styles.unselectable}
      value={subProp.value}
      onChange={(e) => subPropChangeHandler(subProp.name, e.target.value)}>
        {Array.isArray(oneOf) ? oneOf.map(buildOption) : Object.keys(oneOf).map(buildOption)}
    </select>
  } else if (schema[subProp.name]?.type === "boolean") {
    field = <input
      className={styles.unselectable}
      type="checkbox"
      checked={subProp.value === "true"}
      onChange={(e) => subPropChangeHandler(subProp.name, e.target.checked)}
    />
  } else {
    field = <ControlledInput
      type="text"
      value={isPrivateSubProp ? "<Private>" : subProp.value}
      readOnly={isPrivateSubProp}
      onChange={!isPrivateSubProp ? (e) => subPropChangeHandler(subProp.name, e.target.value) : undefined}
    />
  }
  return (
    <div>
      <label className={styles.unselectable} style={{ "textAlign": "left" }}>{subProp.name}: </label>
      {field}
      <button
        className={styles.unselectable}
        onClick={() => subPropDeleteHandler(subProp.name)}>-</button>
    </div>
  )
};

function PropEditor({ props, prop, subProps, privateProps, updatePropHandler, removePropHandler, isScene,
    allComponents, allSystems, editorViewFrame }) {
  const element = editorViewFrame?.contentWindow?.document?.querySelector(isScene ? "#scene" : ("#entity_" + props["id"]));
  let schema = undefined;
  if (isScene) {
    schema = element?.systems?.[prop]?.prototype?.schema ?? allSystems[prop]?.prototype?.schema;
  }
  if (!schema) {
    schema = element?.components?.[prop]?.schema ?? allComponents[prop]?.schema;
  }

  const isAframeProp = (isScene && allSystems[prop]) || allComponents[prop];
  let isSingleProp = false;
  if (isScene && allSystems[prop]) {
    isSingleProp = allSystems[prop].isSingleProp;
  } else if (allComponents[prop]) {
    isSingleProp = allComponents[prop].isSingleProp;
  }

  let unusedSubProps = [];
  if (schema) {
    unusedSubProps = Object.keys(schema)
      .filter((subProperty) => {
        return subProperty !== "schemaChange" &&
          !subProps.some((subProp) => { return subProp.name && subProp.name === subProperty});
      });
  }

  let isPrivateProp = false;
  let privateSubProps = [];
  for (let i = 0; i < privateProps.length; i++) {
    let privateProp = privateProps[i];
    if (privateProp.length > 0 && privateProp[0] === prop) {
      isPrivateProp = true;
      if (privateProp.length > 1) {
        privateSubProps.push(privateProp[1]);
      }
    }
  }

  const readOnlyProps = ["id", "created_at"];
  const isNonDeletable = readOnlyProps.indexOf(prop) !== -1;
  const isReadOnly = isNonDeletable || isPrivateProp;

  return (
    <div>
      <label className={styles.unselectable} style={{ "textAlign": "left" }}>{prop}: </label>
      {!isNonDeletable ?
        <button
          className={styles.unselectable}
          onClick={(e) => removePropHandler(prop)}>-</button>
        : <></>
      }
      <br className={styles.unselectable}/>
      {(!isAframeProp || isSingleProp) ?
        // TODO: handle single prop checkboxes, dropdowns, etc.
        <ControlledInput
          type="text"
          value={isPrivateProp ? "<Private>" : props[prop]}
          readOnly={isReadOnly}
          onChange={!isReadOnly ? (e) => { updatePropHandler(prop, e.target.value) } : undefined}
        />
        :
        <div>
          {subProps.map((subProp) => {
            return <SubPropEditor
                      key={[prop, "_", subProp.name].join("")}
                      schema={schema}
                      prop={prop}
                      subProp={subProp}
                      subProps={subProps}
                      privateSubProps={privateSubProps}
                      updatePropHandler={updatePropHandler}
                    />
          })}
        </div>
      }

      {(isAframeProp && !isSingleProp && unusedSubProps.length > 0) ?
        <div key={["div3_", prop].join("")}>
          <label
            key={["label2_", prop].join("")}
            className={styles.unselectable}
            htmlFor={["addSubProperty_", prop].join("")}
          >
            Add sub-property:
          </label>
          <select
            key={["select_", prop].join("")}
            name={["addSubProperty_", prop].join("")}
            id={["addSubProperty_", prop].join("")}
            className={styles.unselectable}
          >
            {unusedSubProps.map((subProperty) => (
              <option
                key={[prop, "_", subProperty].join("")}
                className={styles.unselectable}
                value={subProperty}
              >
                {subProperty}
              </option>
            ))}
          </select>
          <button className={styles.unselectable} onClick={() => {
              let constructedValue = "";
              for (let subPropNameAndValue of subProps) {
                constructedValue += subPropNameAndValue.name + ":" + subPropNameAndValue.value + ";";
              }
              let newSubProp = document.querySelector("#addSubProperty_" + prop).value;
              constructedValue += newSubProp + ":" +
                schema[newSubProp]?.default + ";";
              updatePropHandler(prop, constructedValue);
            }}>+</button>
          <br key={["br2_", prop].join("")} className={styles.unselectable} />
        </div>
        : <></>
      }

      <hr key={["hr_", prop].join("")} style={{ borderTop: "3px solid #EEE", opacity: 0.5 }} />
    </div>
  )
};

export default function EntityPropertyList({ props, updatePropHandler, removePropHandler, isScene, editorViewFrame }) {
  const priority = [
    "name",
    "id",
    "parent",
    "created_at",
    "position",
    "rotation",
    "scale"
  ];

  let sortedPropKeys = Object.keys(props).sort((left, right) => {
    const index1 = priority.indexOf(left);
    const index2 = priority.indexOf(right);

    if (index1 === -1 && index2 === -1) {
      return (left < right) ? -1 : 1;
    } else if (index1 === -1) {
      return 1;
    } else if (index2 === -1) {
      return -1;
    } else {
      return (index1 < index2) ? -1 : 1;
    }
  });

  const filteredProps = ["created_at", "privateProps"];
  sortedPropKeys = sortedPropKeys.filter((prop) => { return filteredProps.indexOf(prop) === -1; });

  const privateProps = [];
  if ("privateProps" in props) {
    for (let privateProp of props.privateProps) {
      privateProps.push(privateProp.split("."));
    }
  }

  // TODO: automate this list, use component property?
  // from: https://github.com/aframevr/aframe/tree/v1.3.0/src/components/scene
  // doesn't handle scene components from imported scripts
  const sceneProps = [
    "ar-hit-test",
    "background",
    "debug",
    "device-orientation-permission-ui",
    "embedded",
    "fog",
    "inspector",
    "keyboard-shortcuts",
    "pool",
    "reflection",
    "screenshot",
    "stats",
    "vr-mode-ui"
  ];

  let allComponents = editorViewFrame?.contentWindow?.AFRAME?.components ?? AFRAME.components;
  let allSystems = editorViewFrame?.contentWindow?.AFRAME?.systems ?? AFRAME.systems;

  let unusedProps = Object.keys(allComponents).filter((prop) => {
    const isSceneComponent = sceneProps.indexOf(prop) !== -1;
    return (isScene === isSceneComponent);
  });

  if (isScene) {
    unusedProps = unusedProps.concat(Object.keys(allSystems));
  } else {
    unusedProps.push("name");
  }
  unusedProps = unusedProps.filter((prop) => {
    return (!(prop in props) || allComponents[prop]?.multiple);
  });

  return (
    <div>
      {sortedPropKeys.map((prop) => {
        const subProps = props[prop].split(";").filter((subProp) => {
          return subProp !== "";
        }).map((subPropNameAndValue) => {
          const colonIndex = subPropNameAndValue.indexOf(":");
          if (colonIndex !== -1) {
            return { name: subPropNameAndValue.slice(0, colonIndex), value: subPropNameAndValue.slice(colonIndex + 1) };
          } else {
            return { value: subPropNameAndValue };
          }
        });

        return (
          <PropEditor
            key={prop}
            props={props}
            prop={prop}
            subProps={subProps}
            privateProps={privateProps}
            updatePropHandler={updatePropHandler}
            removePropHandler={removePropHandler}
            isScene={isScene}
            allComponents={allComponents}
            allSystems={allSystems}
            editorViewFrame={editorViewFrame}
          />
        )
      })}

      {unusedProps.length > 0 ?
        <div>
          <label htmlFor="addComponent" className={styles.unselectable}>Add component:</label>
          <select name="addComponent" id="addComponent" className={styles.unselectable}>
            {unusedProps.map((component) => (
              <option key={component} className={styles.unselectable} value={component}>{component}</option>
            ))}
          </select>
          {/* TODO: Handle naming for multiple components */}
          <button
            className={styles.unselectable}
            onClick={(e) => updatePropHandler(document.querySelector("#addComponent").value, "")}
          >
            +
          </button>
        </div>
        : <></>
      }
    </div>
  )
}

export function UIEditor({ overlayElements, cssProps, updateOverlayElementHandler, updateCSSHandler }) {
  const modes = ["HTML", "CSS"];
  const [mode, setMode] = useState(modes[0]);

  return (
    <div>
      {modes.map((modeValue) => {
        return <span
          key={modeValue}
          className={styles.unselectable}
          style={{
            padding: "2px 10px",
            backgroundColor: mode === modeValue ? "#dddddd" : undefined,
            borderRadius: mode === modeValue ? "10px" : undefined
          }}
          onClick={() => setMode(modeValue)}
        >
          {modeValue}
        </span>
      })}
      <br className={styles.unselectable}/>
      <ControlledEditor
          key={mode}
          value={mode === "HTML" ? overlayElements : cssProps}
          onChange={(value) => mode === "HTML" ? updateOverlayElementHandler(value) : updateCSSHandler(value)}
          extensions={[mode === "HTML" ? html() : css()]}
          style={{
            width: "600px",
            height: "50vh",
            textAlign: "left",
            overflow: "auto"
          }}
      />
    </div>
  )
}

export function ScriptPropEditor({ props, updatePropHandler }) {
  return (
    <div key={props.id}>
      <label className={styles.unselectable} style={{ "textAlign": "left" }}>Name: </label>
      <ControlledInput
        value={props.name}
        onChange={(e) => updatePropHandler("name", e.target.value)}
      />
      <br/>
      {!props.private ?
        <div>
          <br/>
          <label className={styles.unselectable} style={{ "textAlign": "left" }}>Is Module: </label>
          <input
            className={styles.unselectable}
            type="checkbox"
            checked={props.module}
            onChange={(e) => updatePropHandler("module", e.target.checked)}
          />
          <br/>
          <br/>
          {("url" in props) ?
            <ControlledInput
              value={props.url}
              onChange={(e) => updatePropHandler("url", e.target.value)}
            />
            : <ControlledEditor
              value={props.script}
              onChange={(value) => updatePropHandler("script", value)}
              extensions={[javascript()]}
              style={{
                width: "600px",
                height: "50vh",
                textAlign: "left",
                overflow: "auto"
              }}
            />
          }
        </div>
        : <p>{props.description}</p>
      }
    </div>
  )
}

export function OwnedScriptViewer({ props, addScriptHandler, goToListingHandler }) {
  return (
    <div key={props.id}>
      <label className={styles.unselectable} style={{ "textAlign": "left" }}>Name: </label>
      <ControlledInput
        value={props.name}
        readOnly={true}
      />
      <br/>
      <p
        className={styles.unselectable}
      >
        {props.description}
      </p>
      <button
        className={styles.unselectable}
        onClick={() => {
          props.data.name = props.name;
          props.data.description = props.description;
          addScriptHandler(props.data);
        }}
      >
        Add To Scene
      </button>
      <br />
      <button
        className={styles.unselectable}
        onClick={() => goToListingHandler(props.listing) }
      >
        {"Listing >"}
      </button>
    </div>
  )
}

export function AssetPropEditor({ props, updateAssetNameHandler, addEntityFromAssetHandler,
    getPublicURL, privateURL, goToListingHandler }) {
  const url = privateURL ? props.data?.asset : getPublicURL(props.name);
  const type = privateURL ? "" : props.metadata.mimetype;

  return (
    <div key={props.id}>
      <label className={styles.unselectable} style={{ "textAlign": "left" }}>Name: </label>
      <ControlledInput
        value={props.name}
        readOnly={privateURL}
        onChange={!privateURL ? (e) => updateAssetNameHandler(props.name, e.target.value) : undefined}
      />
      <br/>
      {privateURL ?
        <p
          className={styles.unselectable}
        >
          {props.description}
        </p>
        : <br />
      }
      <button
        className={styles.unselectable}
        onClick={() => addEntityFromAssetHandler(props.name, url, type, privateURL)}
      >
        Add To Scene
      </button>
      {!privateURL ?
        <button
          className={styles.unselectable}
          onClick={() => { navigator.clipboard.writeText(url); }}
        >
          Copy URL
        </button>
        : <></>
      }
      {goToListingHandler ?
        <>
          <br />
          <button
            className={styles.unselectable}
            onClick={() => goToListingHandler(props.listing) }
          >
            {"Listing >"}
          </button>
        </>
        : <></>
      }
    </div>
  )
}

export function AppPropEditor({ props, updatePropHandler, addAppHandler, goToListingHandler, appAPI }) {
  const marketplaceApp = !!goToListingHandler;
  const propsData = marketplaceApp ? props.data : props;

  const modes = propsData.private ? ["App"] : ["App", "HTML", "CSS", "JavaScript"];
  const [mode, setMode] = useState(modes[0]);

  const appFrameRef = useRef(undefined);
  const [scriptUpdateCounter, setScriptUpdateCounter] = useState(0);
  const incrementScriptUpdateCounterDebounced = useCallback(
    debounce(() => setScriptUpdateCounter(scriptUpdateCounter + 1), 2000)
  , [scriptUpdateCounter]);

  useEffect(() => {
    incrementScriptUpdateCounterDebounced();
  }, [props, appAPI]);

  const updateAppFrameScripts = () => {
    {
      const scriptElement = appFrameRef.current.contentWindow.document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.id = "api";
      scriptElement.className = "api";
      scriptElement.innerHTML = appAPI;
      appFrameRef.current.contentWindow.document.head.appendChild(scriptElement);
    }

    {
      const injectedScriptID = "injectedScript";
      const scriptElement = appFrameRef.current.contentWindow.document.createElement("script");
      scriptElement.type = "module";
      scriptElement.id = injectedScriptID;
      scriptElement.className = injectedScriptID;
      scriptElement.innerHTML = propsData.script;
      appFrameRef.current.contentWindow.document.head.appendChild(scriptElement);
    }
  };

  const updateAppFrame = () => {
    let message = {
      type: "data-update",
      html: propsData.html,
      css: propsData.css
    };
    appFrameRef.current.contentWindow.postMessage(message);
  };

  const handleIFrameLoad = () => {
    updateAppFrameScripts();
    updateAppFrame();
  };

  return (
    <div key={props.id}>
      <label className={styles.unselectable} style={{ "textAlign": "left" }}>Name: </label>
      <ControlledInput
        value={props.name}
        readOnly={marketplaceApp}
        onChange={!marketplaceApp ? (e) => updatePropHandler("name", e.target.value) : undefined}
      />
      <br />
      <br />
      {marketplaceApp ?
        <>
          <button
            className={styles.unselectable}
            onClick={() => {
              props.data.name = props.name;
              props.data.description = props.description;
              addAppHandler(props.data);
            }}
          >
            Add To Scene
          </button>
          <br />
          <button
            className={styles.unselectable}
            onClick={() => goToListingHandler(props.listing) }
          >
            {"Listing >"}
          </button>
          <br />
          <br />
        </>
        : <></>
      }
      {modes.length > 1 ? modes.map((modeValue) => {
          return <span
            key={modeValue}
            className={styles.unselectable}
            style={{
              padding: "2px 10px",
              backgroundColor: mode === modeValue ? "#dddddd" : undefined,
              borderRadius: mode === modeValue ? "10px" : undefined
            }}
            onClick={() => setMode(modeValue)}
          >
            {modeValue}
          </span>
        })
        : <></>
      }
      <br className={styles.unselectable}/>
      <br className={styles.unselectable}/>
      {mode === "App" ?
        <iframe
          id="app"
          key={scriptUpdateCounter}
          className={styles.unselectable}
          title="Gydence App View"
          src={window.location.origin + "/app" + window.location.search}
          allow="geolocation; microphone; camera; midi; encrypted-media; xr-spatial-tracking; fullscreen"
          allowFullScreen=""
          sandbox="allow-scripts allow-modals allow-forms allow-same-origin allow-top-navigation-by-user-activation allow-downloads"
          onLoad={handleIFrameLoad}
          ref={appFrameRef}
          style={{
            width: "600px",
            height: "50vh",
            textAlign: "left",
            overflow: "auto"
          }}
        />
        : <ControlledEditor
            key={mode + props.id}
            value={mode === "HTML" ? propsData.html : mode === "CSS" ? propsData.css : propsData.script}
            readOnly={marketplaceApp}
            onChange={!marketplaceApp ? (value) => updatePropHandler(mode === "HTML" ? "html" : mode === "CSS" ? "css" : "script", value) : undefined}
            extensions={[mode === "HTML" ? html() : mode === "CSS" ? css() : javascript()]}
            style={{
              width: "600px",
              height: "50vh",
              textAlign: "left",
              overflow: "auto"
            }}
        />
      }
    </div>
  )
}