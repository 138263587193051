import { useEffect, useState, useRef, useCallback } from "react";

import { makeElementsDraggable, replaceHTMLIncludes, debounce } from "../gydence/utils";

import { EnforceSignedIn, supabase } from "./signin";
import EnforceOnboarded from "./onboard";

import EntityList from "./entityList";
import EntityPropertyList, { UIEditor, ScriptPropEditor, AssetPropEditor, OwnedScriptViewer, AppPropEditor } from "./propertyList";
import EntityMenu from "./entityMenu";
import EditSiteUI from "./editSite";
import Marketplace from "./marketplace";
import UploadManager from "./marketplaceUpload";
import { ItemListPanel, NUM_ITEMS_PER_PAGE } from "../components/itemListEditor";
import gydenceAPI from "./api";

import styles from "./index.module.css";

function deepEquals(x, y) {
  const keys = Object.keys;
  const typeX = typeof x;
  const typeY = typeof y;

  return x && y && typeX === "object" && typeX === typeY ? (
    keys(x).length === keys(y).length &&
      keys(x).every((key) => deepEquals(x[key], y[key]))
  ) : (x === y);
}

const parseSiteProperties = (siteData) => {
  let scenePropertyMapData = {};
  let environmentData = undefined;
  let entityPropertyMapData = {};
  let entityParentMapData = {};
  let entityIndexMapData = {};

  let overlayElementsData = undefined;
  let cssPropsData = undefined;

  let scriptsData = [];
  let appsData = [];

  if (siteData) {
    if (siteData.scene) {
      scenePropertyMapData = siteData.scene;
    }

    if (siteData.environment) {
      environmentData = siteData.environment;
    }

    if (siteData.entities) {
      let entityIndex = 0;
      for (const entity of siteData.entities) {
        const parentID = entity.parent;
        if (!(parentID in entityParentMapData)) {
          entityParentMapData[parentID] = [];
        }
        entityParentMapData[parentID].push(entity.id);
        entityPropertyMapData[entity.id] = entity;
        entityIndexMapData[entity.id] = entityIndex++;
      }
    }

    if (siteData.overlay) {
      overlayElementsData = siteData.overlay;
    }

    if (siteData.css) {
      cssPropsData = siteData.css;
    }

    if (siteData.scripts) {
      scriptsData = siteData.scripts;
    }

    if (siteData.apps) {
      appsData = siteData.apps;
    }
  }

  return [scenePropertyMapData, environmentData, entityPropertyMapData,
          entityParentMapData, entityIndexMapData, overlayElementsData,
          cssPropsData, scriptsData, appsData];
};

function EditorMain() {
  const [setup, setSetup] = useState(false);

  /// Login state + user data
  const [user, setUser] = useState([]);

  // Owned items
  const [ownedItems, setOwnedItems] = useState([]);
  const [ownedListings, setOwnedListings] = useState([]);
  const [ownedTemplates, setOwnedTemplates] = useState([]);
  const [ownedEnvironments, setOwnedEnvironments] = useState([]);
  const [ownedScripts, setOwnedScripts] = useState([]);
  const [ownedAssets, setOwnedAssets] = useState([]);
  const [ownedApps, setOwnedApps] = useState([]);

  const [targetListing, setTargetListing] = useState(undefined);

  useEffect(() => {
    if (ownedItems?.length > 0) {
      const fetchOwnedItems = async () => {
        const { data } = await supabase.from("marketplace_items").select("*")
          .in("id", ownedItems).limit(ownedItems.length);
        if (data) {
          let listingsData = [];
          let templatesData = [];
          let environmentsData = [];
          let scriptsData = [];
          let assetsData = [];
          let appsData = [];
          for (let item of data) {
            if (item.listing) {
              listingsData.push(item.listing);
            }
            switch (item.type) {
              case "template":
                templatesData.push(item);
                break;
              case "environment":
                environmentsData.push(item);
                break;
              case "script":
                scriptsData.push(item);
                break;
              case "asset":
                assetsData.push(item);
                break;
              case "app":
                appsData.push(item);
                break;
              default:
                break;
            }
          }
          setOwnedListings(listingsData);
          setOwnedTemplates(templatesData);
          setOwnedEnvironments(environmentsData);
          setOwnedScripts(scriptsData);
          setOwnedAssets(assetsData);
          setOwnedApps(appsData);

          return;
        }

        setOwnedListings([]);
        setOwnedTemplates([]);
        setOwnedEnvironments([]);
        setOwnedScripts([]);
        setOwnedAssets([]);
        setOwnedApps([]);
      };
      fetchOwnedItems();
    } else {
      setOwnedListings([]);
      setOwnedTemplates([]);
      setOwnedEnvironments([]);
      setOwnedScripts([]);
      setOwnedAssets([]);
      setOwnedApps([]);
    }
  }, [ownedItems]);

  const getUserDataAndRefreshOwnedItems = async () => {
    const { data } = await supabase.from("user_data_private").select("*").limit(1);
    let userData = data?.[0];
    setOwnedItems(userData?.owned_items);
    return userData;
  };

  // Site/entity/UI/script selection
  const [sites, setSites] = useState([]);
  const [currentSite, setCurrentSite] = useState(undefined);
  const [siteName, setSiteName] = useState("");
  const [siteCreator, setSiteCreator] = useState("");
  const [siteEditors, setSiteEditors] = useState("");
  const [selectedObject, setSelectedObject] = useState(undefined);

  useEffect(() => {
    const currentSites = [...sites];
    for (let site of currentSites) {
      if (site.id === currentSite) {
        site.name = siteName;
      }
    }
  }, [siteName]);

  const refreshSites = async () => {
    const { data } = await supabase.from("sites").select("*");
    if (data) {
      // Sort site names alphabetically
      data.sort((siteA, siteB) => {
        if (siteA.name === siteB.name) {
          // Use ID as tie breaker
          return siteA.id < siteB.id ? -1 : 1;
        }
        return siteA.name < siteB.name ? -1 : 1;
      });
      setSites(data);
    }
    return data;
  };

  const updateSiteNameHandler = (name) => {
    supabase.rpc("update_site_name", {
      site: currentSite,
      value: name,
    }).then(async (result) => {
      console.log(result);
      if (!result.error) {
        setSiteName(name);
      }
    });
  };

  const updateSiteEditorsHandler = (editors) => {
    supabase.rpc("update_site_editors", {
      site: currentSite,
      value: editors,
    }).then(async (result) => {
      console.log(result);
      if (!result.error) {
        setSiteEditors(editors);
      }
    });
  };

  useEffect(() => {
    updateEditorViewFrameSelection();
  }, [selectedObject]);

  // Scene/entity state maps
  const [scenePropertyMap, setScenePropertyMap] = useState({});
  const [environment, setEnvironment] = useState(undefined);
  const [environmentEntities, setEnvironmentEntities] = useState(undefined);
  const [entityPropertyMap, setEntityPropertyMap] = useState({});
  const [entityParentMap, setEntityParentMap] = useState({});
  const [entityIndexMap, setEntityIndexMap] = useState({});

  useEffect(() => {
    if (environment) {
      supabase.from("marketplace_items").select("*").eq("id", environment).limit(1)
        .then((result) => {
          if (!result.error && result.data.length > 0) {
            setEnvironmentEntities(result.data[0].data?.environment);
          }
        });
    } else {
      setEnvironmentEntities(undefined);
    }
  }, [environment]);

  useEffect(() => {
    updateEditorViewFrameEnvironment();
  }, [environmentEntities]);

  // 2D UI
  const [overlayElements, setOverlayElements] = useState(undefined);
  const [cssProps, setCSSProps] = useState(undefined);

  useEffect(() => {
    updateEditorViewFrame();
  }, [scenePropertyMap, entityPropertyMap, overlayElements, cssProps]);

  // Scripts
  const [scripts, setScripts] = useState([]);
  const [scriptUpdateCounter, setScriptUpdateCounter] = useState(0);
  const incrementScriptUpdateCounterDebounced = useCallback(
    debounce(() => setScriptUpdateCounter(scriptUpdateCounter + 1), 2000)
  , [scriptUpdateCounter]);

  useEffect(() => {
    incrementScriptUpdateCounterDebounced();
  }, [scripts]);

  // Apps
  const [apps, setApps] = useState([]);

  // Assets
  const [assets, setAssets] = useState([]);
  const [assetsOffset, setAssetsOffset] = useState(0);

  useEffect(() => {
    getAssets(currentSite);
  }, [assetsOffset]);

  const getAssets = async (site) => {
    const { data } = await supabase.storage.from("storage")
      .list("sites/" + site + "/private/", {
        limit: NUM_ITEMS_PER_PAGE + 1,
        offset: assetsOffset,
        sortBy: { column: "name", order: "asc" },
      });
    setAssets(data);
  };

  const dropAssetHandler = (event, func) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      for (const item of event.dataTransfer.items) {
        if (item.kind === "file") {
          const file = item.getAsFile();
          func(file);
        }
      }
    } else {
      for (const file of event.dataTransfer.files) {
        func(file);
      }
    }
  };

  const addAssetHandler = async (file) => {
    const { data, error } = await supabase.storage.from("storage")
      .upload("sites/" + currentSite + "/private/" + file.name, file, {
        cacheControl: "3600",
        upsert: true
    });

    if (!error) {
      getAssets(currentSite);
    } else {
      console.log(error);
    }

    return data;
  };

  const getPublicURL = (fileName) => {
    const { data } = supabase.storage.from("storage")
      .getPublicUrl("sites/" + currentSite + "/private/" + fileName);

    return data?.publicUrl;
  };

  const addEntityFromAssetHandler = async (fileName, fileURL, fileType, privateURL) => {
    let props = undefined;

    const ext = fileURL.split(/[#?]/)[0].split('.').pop().trim();
    const imageExts = ["png", "jpg", "jpeg"];
    const videoExts = ["mp4", "mov"];
    const modelExts = ["gyde", "gltf", "glb", "obj", "3dm"];

    if (fileType.startsWith("image") || imageExts.indexOf(ext) !== -1) {
      props = [{
        position: "0 0 -1",
        scale: "1 1 1",
        rotation: "0 0 0",
        geometry: "primitive:plane",
        material: "src:" + fileURL,
        name: fileName,
      }];

      if (privateURL) {
        props[0].privateProps = ["material.src"];
      }
    } else if (fileType.startsWith("video") || videoExts.indexOf(ext) !== -1) {
      props = [{
        position: "0 0 -1",
        scale: "1 1 1",
        rotation: "0 0 0",
        geometry: "primitive:plane",
        material: "src:" + fileURL,
        name: fileName,
      }];

      if (privateURL) {
        props[0].privateProps = ["material.src"];
      }
    } else if (modelExts.indexOf(ext) !== -1) {
      props = [{
        position: "0 0 -1",
        scale: "1 1 1",
        rotation: "0 0 0",
        model: "url:" + fileURL,
        name: fileName,
      }];

      if (privateURL) {
        props[0].privateProps = ["model.url"];
      }
    }

    if (props) {
      addEntityHandler(props);
    }
  };

  const addAssetAndEntityHandler = async (file) => {
    const asset = addAssetHandler(file);
    if (asset) {
      addEntityFromAssetHandler(file.name, getPublicURL(file.name), file.type, false);
    }
  };

  const updateAssetNameHandler = async (oldName, newName) => {
    supabase.storage.from("storage")
      .move("sites/" + currentSite + "/private/" + oldName, "sites/" + currentSite + "/private/" + newName)
      .then(async (result) => {
        if (result) {
          getAssets(currentSite);
        }
      });
  };

  // iFrame
  const editorViewFrameRef = useRef(undefined);

  const appAPI = gydenceAPI({
    currentSite: currentSite,
    isEditing: true,
    isPublic: false,
    html: overlayElements,
    css: cssProps,
    scripts: scripts,
  });

  const updateEditorViewFrameScripts = () => {
    if (!document.querySelector("#api")) {
      const scriptElement = editorViewFrameRef.current.contentWindow.document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.id = "api";
      scriptElement.className = "api";
      scriptElement.innerHTML = appAPI;
      editorViewFrameRef.current.contentWindow.document.head.appendChild(scriptElement);
    }

    {
      // Add new scripts
      const injectedScriptID = "injectedScript";
      for (const script of scripts) {
        let scriptElement = editorViewFrameRef.current.contentWindow.document.createElement("script");
        if (script.module) {
          scriptElement.type = "module";
        } else {
          scriptElement.type = "text/javascript";
        }
        scriptElement.id = script.id;
        scriptElement.className = injectedScriptID;
        if (script.url) {
          scriptElement.src = script.url;
        } else if (script.script) {
          scriptElement.innerHTML = script.script;
        }
        // TODO: other script properties?  async/defer?
        editorViewFrameRef.current.contentWindow.document.head.appendChild(scriptElement);
      }
    }
  };

  const updateEditorViewFrame = () => {
    let message = {
      type: "data-update",
      selectedObject: selectedObject,
      scenePropertyMap: scenePropertyMap,
      environment: environment,
      entityPropertyMap: entityPropertyMap,
      overlayElements: overlayElements,
      cssProps: cssProps
    };
    editorViewFrameRef.current.contentWindow.postMessage(message);
  };

  const updateEditorViewFrameSelection = () => {
    let message = {
      type: "select-object",
      selectedObject: selectedObject
    };
    editorViewFrameRef.current.contentWindow.postMessage(message);
  };

  const updateEditorViewFrameEnvironment = () => {
    let message = {
      type: "environment-update",
      environment: environmentEntities
    };
    editorViewFrameRef.current.contentWindow.postMessage(message);
  };

  const handleIFrameLoad = () => {
    // TODO: is there a better way to do this?  bad to depend on
    // all the different types + codemirror
    if (document.activeElement.nodeName !== "INPUT" &&
        document.activeElement.nodeName !== "SELECT" &&
        document.activeElement.nodeName !== "TEXTAREA" &&
        document.activeElement.className !== "cm-content") {
      editorViewFrameRef.current.contentWindow.focus();
    }

    // When we drag over the iframe, we need to disable pointerEvents to allow
    // our main div to receive the drop.  This is reset on dragleave/drop on the main
    // div.  ondragenter doesn't work here for some reason; it works the first time
    // but then misses every other enter event
    editorViewFrameRef.current.contentWindow.document.ondragover = (e) => {
      editorViewFrameRef.current.style.pointerEvents = "none";
    };

    updateEditorViewFrameScripts();
    updateEditorViewFrame();
    updateEditorViewFrameSelection();
    updateEditorViewFrameEnvironment();
  };

  // Mode state
  const [editorMode, setEditorMode] = useState("Entities");

  useEffect(() => {
    // TODO: can get rid of this if we can respond to asset changes
    if (editorMode === "Assets") {
      getAssets(currentSite);
    }
  }, [editorMode]);

  // Realtime data
  const [realtimeChannel, setRealtimeChannel] = useState(undefined);
  const [onlineUsers, setOnlineUsers] = useState([]);

  // The postgres_changes handler is a closure, which means the scene/entity maps
  // would be stale by the time it is called.  As a workaround, we have a separate
  // state variable for the incoming data change, which is immediately cleared.
  const [forceAcceptSiteData, setForceAcceptSiteData] = useState(true);
  const [newSiteData, setNewSiteData] = useState(undefined);

  useEffect(() => {
    if (newSiteData) {
      if (forceAcceptSiteData || newSiteData?.last_updated_by !== user?.user?.email) {
        setSiteName(newSiteData.name);
        setSiteCreator(newSiteData.creator);
        setSiteEditors(newSiteData.editors);

        const [scenePropertyMapData, environmentData, entityPropertyMapData,
               entityParentMapData, entityIndexMapData, overlayElementsData,
               cssPropsData, scriptsData, appsData] =
          parseSiteProperties(newSiteData.data);

        setScenePropertyMap(scenePropertyMapData);
        setEnvironment(environmentData);
        setEntityPropertyMap(entityPropertyMapData);
        setEntityParentMap(entityParentMapData);
        setEntityIndexMap(entityIndexMapData);

        setOverlayElements(overlayElementsData);
        setCSSProps(cssPropsData);

        // === will always return false here because they are different objects.
        // We need to check deep equality to avoid reloading the iframe everytime
        // another user makes any edit.
        if (!deepEquals(scripts, scriptsData)) {
          setScripts(scriptsData);
        }

        if (!deepEquals(apps, appsData)) {
          setApps(appsData);
        }
      }
      setNewSiteData(undefined);
      setForceAcceptSiteData(false);
    }
  }, [newSiteData]);

  const onCurrentSiteChange = async (newSite, currentEmail, siteData, siteIndex) => {
    if (realtimeChannel) {
      realtimeChannel.unsubscribe();
    }

    let email = currentEmail ?? user?.user?.email;
    if (email) {
      const channel = supabase.channel(newSite, {
        config: {
          presence: {
            key: email,
          },
        },
      });

      channel.on("presence", { event: "sync" }, () => {
        let presenceState = {...channel.presenceState()};
        console.log("Online users: ", presenceState);
        // Ignore our own email
        delete presenceState[email];
        let onlineUsersData = Object.keys(presenceState).sort((left, right) => {
          return (presenceState[left][0].online_at < presenceState[right][0].online_at) ? -1 : 1;
        });
        setOnlineUsers(onlineUsersData);
      });

      channel.on("postgres_changes", {
          event: "UPDATE",
          schema: "public",
          table: "sites",
          filter: `id=eq.${newSite}`,
        }, (payload) => { setNewSiteData(payload?.new); }
      );

      // TODO: figure out if it's possible to enable realtime for this
      // TODO: figure out the right filter here to listen to storage changes
      // and refresh assets
      // channel.on("postgres_changes", {
      //     event: "UPDATE",
      //     schema: "storage",
      //     table: "objects",
      // multiple filters aren't possible: https://github.com/supabase/supabase/issues/11190
      //     filter: `pathtokens[1]=eq.${newSite} && pathtokens[2]=eq.private`,
      //   }, (payload) => { console.log(payload); }
      // );

      channel.subscribe(async (status) => {
        if (status === "SUBSCRIBED") {
          channel.track({
            online_at: new Date().toISOString()
          });
        } else {
          console.log("Channel status: " + status);
        }
      });

      setRealtimeChannel(channel);
    }

    setSelectedObject(undefined);
    setCurrentSite(newSite);

    // TODO: refresh when other editor uploads
    getAssets(newSite);

    supabase.rpc("update_last_visited_site", {
      site: newSite
    }).then((result) => {
      console.log(result);
    });

    if (!siteData) {
      let { data } = await supabase.from("sites").select("*").eq("id", newSite).limit(1);
      siteData = data;
      siteIndex = 0;
    }
    if (siteData && siteData.length > siteIndex) {
      setForceAcceptSiteData(true);
      setNewSiteData(siteData[siteIndex]);
    }
  };

  // FIXME: a lot of these handlers should take an array and set multiple values, like addEntityHandler
  const updateScenePropHandler = (prop, value, targetSite) => {
    const site = targetSite ?? currentSite;
    supabase.rpc("update_scene_property", {
      site: site,
      property: prop,
      value: value
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // Update the scene's property in the property map
        let currentScenePropertyMap = {...scenePropertyMap};
        currentScenePropertyMap[prop] = value;
        setScenePropertyMap(currentScenePropertyMap);

        // TODO: clear scene error
      }
    });
  };

  const removeScenePropHandler = (prop) => {
    supabase.rpc("remove_scene_property", {
      site: currentSite,
      property: prop,
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // Remove this prop from the scene's property map
        let currentScenePropertyMap = {...scenePropertyMap};
        delete currentScenePropertyMap[prop];
        setScenePropertyMap(currentScenePropertyMap);

        // TODO: clear scene error
      }
    });
  };

  const setEnvironmentHandler = (environment, targetSite) => {
    const site = targetSite ?? currentSite;
    supabase.rpc("set_environment", {
      site: site,
      value: environment,
    }).then(async (result) => {
      console.log(result);
      if (!result.error) {
        setEnvironment(environment);
      }
    });
  };

  const addEntityHandler = async (propsArray, targetSite, afterFunc) => {
    const site = targetSite ?? currentSite;
    return supabase.rpc("add_entities", {
      site: site,
      propsarray: propsArray
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        let currentEntityParentMap = {...entityParentMap};
        let currentEntityPropertyMap = {...entityPropertyMap};
        let currentEntityIndexMap = {...entityIndexMap};

        for (let i = 0; i < propsArray.length; i++) {
          const id = result.data[i];
          let props = propsArray[i];
          // id is only set on the server
          props.id = id;

          // FIXME: this way of specifying relative parent by offset is kinda funky
          if ("parent" in props && props.parent < 0 && (i + props.parent) >= 0) {
            props.parent = result.data[i + props.parent];
          }

          // Add this entity to its parent's children
          let parentID = props.parent;
          if (!(parentID in currentEntityParentMap)) {
            currentEntityParentMap[parentID] = [];
          }
          currentEntityParentMap[parentID].push(props.id);

          // Add this entity to the property map
          currentEntityPropertyMap[id] = props;

          // Add this entity to the end of the index map
          let newEntityIndex = 0;
          if (Object.keys(currentEntityIndexMap).length > 0) {
            for (let entityIndex of Object.values(currentEntityIndexMap)) {
              newEntityIndex = Math.max(newEntityIndex, entityIndex);
            }
            newEntityIndex += 1;
          }
          currentEntityIndexMap[id] = newEntityIndex;
        }

        setEntityParentMap(currentEntityParentMap);
        setEntityPropertyMap(currentEntityPropertyMap);
        setEntityIndexMap(currentEntityIndexMap);

        // Set this entity to the selected entity
        setSelectedObject(result.data[0]);

        if (afterFunc) {
          afterFunc();
        }
      }
    });
  };

  const deleteEntityHandler = (entity) => {
    supabase.rpc("delete_entity", {
      site: currentSite,
      entityindex: entityIndexMap[entity],
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // Remove this entity from the parent map
        let currentEntityParentMap = {...entityParentMap};
        delete currentEntityParentMap[entity];
        // Remove this entity from its parent's children
        let parentEntity = entityPropertyMap[entity]["parent"];
        currentEntityParentMap[parentEntity] =
          currentEntityParentMap[parentEntity].filter(function(currEntity) {
            return entity !== currEntity;
          });
        setEntityParentMap(currentEntityParentMap);

        // Remove this entity from the property map
        let currentEntityPropertyMap = {...entityPropertyMap};
        delete currentEntityPropertyMap[entity];
        setEntityPropertyMap(currentEntityPropertyMap);

        // Remove this entity from the index map
        let currentEntityIndexMap = {...entityIndexMap};
        let oldIndex = currentEntityIndexMap[entity];
        delete currentEntityIndexMap[entity];
        // Decrement any higher indices
        for (let entity of Object.keys(currentEntityIndexMap)) {
          if (currentEntityIndexMap[entity] > oldIndex) {
            currentEntityIndexMap[entity]--;
          }
        }
        setEntityIndexMap(currentEntityIndexMap);

        // If this was the selected entity, clear it
        if (selectedObject === entity) {
          setSelectedObject(undefined);
        }
      }
    });
  };

  const updatePropHandler = (prop, value) => {
    supabase.rpc("update_entity_property", {
      site: currentSite,
      entityindex: entityIndexMap[selectedObject],
      property: prop,
      value: value
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        if (prop === "parent") {
          let currentEntityParentMap = {...entityParentMap};
          // Remove this entity from its old parent's children
          let parentEntity = entityPropertyMap[selectedObject]["parent"];
          if (currentEntityParentMap[parentEntity]) {
            currentEntityParentMap[parentEntity] =
              currentEntityParentMap[parentEntity].filter(function(entity) {
                return entity !== selectedObject;
              });
          }

          // Add this entity to its new parent's children
          if (!(value in currentEntityParentMap)) {
            currentEntityParentMap[value] = [];
          }
          currentEntityParentMap[value].push(selectedObject);
          setEntityParentMap(currentEntityParentMap);
        }

        // Update this entity's property in the property map
        let currentEntityPropertyMap = {...entityPropertyMap};
        currentEntityPropertyMap[selectedObject][prop] = value;
        setEntityPropertyMap(currentEntityPropertyMap);
      }
    });
  };

  const removePropHandler = (prop) => {
    supabase.rpc("remove_entity_property", {
      site: currentSite,
      entityindex: entityIndexMap[selectedObject],
      property: prop,
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        if (prop === "parent") {
          let parentID = entityPropertyMap[selectedObject]["parent"];
          // Remove this entity from its parent's children
          let currentEntityParentMap = {...entityParentMap};
          currentEntityParentMap[parentID] =
            currentEntityParentMap[parentID].filter(function(entity) {
              return entity !== selectedObject;
            });

          // Add this entity to its new parent's children
          if (!(undefined in currentEntityParentMap)) {
            currentEntityParentMap[undefined] = [];
          }
          currentEntityParentMap[undefined].push(selectedObject);
          setEntityParentMap(currentEntityParentMap);
        }

        // Remove this prop from this entity's property map
        let currentEntityPropertyMap = {...entityPropertyMap};
        delete currentEntityPropertyMap[selectedObject][prop];
        setEntityPropertyMap(currentEntityPropertyMap);
      }
    });
  };

  const updateOverlayElementHandler = (value, targetSite) => {
    const site = targetSite ?? currentSite;
    supabase.rpc("update_overlay_elements", {
      site: site,
      value: value
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        setOverlayElements(value);
      }
    });
  };

  const updateCSSHandler = (value, targetSite) => {
    const site = targetSite ?? currentSite;
    supabase.rpc("update_css", {
      site: site,
      value: value
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        setCSSProps(value);
      }
    });
  };

  const addScriptHandler = async (props, afterFunc, targetSite) => {
    const site = targetSite ?? currentSite;
    return supabase.rpc("add_script", {
      site: site,
      props: props
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // id is only set on the server
        props["id"] = result.data;

        // Add this script to the list
        let currentScripts = [...scripts];
        currentScripts.push(props);
        setScripts(currentScripts);

        // Set this script to the selected script
        setSelectedObject(result.data);

        if (afterFunc) {
          afterFunc(result.data);
        }
      }
    });
  };

  const updateScriptPropHandler = (prop, value) => {
    const scriptIndex = scripts.findIndex((el) => el.id === selectedObject);
    supabase.rpc("update_script_property", {
      site: currentSite,
      scriptindex: scriptIndex,
      property: prop,
      value: value,
      forceupdate: "false",
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // Update this script's property in the list
        let currentScripts = [...scripts];
        currentScripts[scriptIndex][prop] = value;
        setScripts(currentScripts);
      }
    });
  };

  const addAppHandler = async (props, afterFunc, targetSite) => {
    const site = targetSite ?? currentSite;
    return supabase.rpc("add_app", {
      site: site,
      props: props
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // id is only set on the server
        props["id"] = result.data;

        // Add this app to the list
        let currentApps = [...apps];
        currentApps.push(props);
        setApps(currentApps);

        // Set this app to the selected app
        setSelectedObject(result.data);

        if (afterFunc) {
          afterFunc(result.data);
        }
      }
    });
  };

  const updateAppPropHandler = (prop, value) => {
    const appIndex = apps.findIndex((el) => el.id === selectedObject);
    supabase.rpc("update_app_property", {
      site: currentSite,
      appindex: appIndex,
      property: prop,
      value: value
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // Update this app's property in the list
        let currentApps = [...apps];
        currentApps[appIndex][prop] = value;
        setApps(currentApps);
      }
    });
  };

  const publishSite = async () => {
    const NUM_ITEMS_PER_REQUEST = 50;
    {
      let page = 0;
      let data = [];
      do {
        data = (await supabase.storage.from("storage").list("sites/" + currentSite + "/public/", {
          limit: NUM_ITEMS_PER_REQUEST,
          offset: page * NUM_ITEMS_PER_REQUEST
        })).data;
        if (data && data.length > 0) {
          const assets = data.map((asset) => {
            return "sites/" + currentSite + "/public/" + asset.name;
          });
          await supabase.storage.from("storage").remove(assets);
          page++;
        }
      } while (data && data.length > 0);
    }

    {
      let page = 0;
      let data = [];
      let promises = [];
      do {
        data = (await supabase.storage.from("storage").list("sites/" + currentSite + "/private/", {
          limit: NUM_ITEMS_PER_REQUEST,
          offset: page * NUM_ITEMS_PER_REQUEST
        })).data;
        if (data && data.length > 0) {
          promises.push(...data.map((asset) => {
            return supabase.storage.from("storage")
                    .copy("sites/" + currentSite + "/private/" + asset.name,
                          "sites/" + currentSite + "/public/" + asset.name)
          }));
          page++;
        }
      } while (data && data.length > 0);

      let results = await Promise.allSettled(promises);
      for (let result of results) {
        if (result?.value?.error) {
          console.log(result.value.error);
        }
      }
    }

    let data = {
      // TODO: handle version properly
      version: 1
    };

    // Replace asset URLS in scene and entities
    if (scenePropertyMap && Object.values(scenePropertyMap).length > 0) {
      let scenePropertyMapString = JSON.stringify(scenePropertyMap);
      scenePropertyMapString = scenePropertyMapString.replaceAll(currentSite + "/private", currentSite + "/public");
      data.scene = JSON.parse(scenePropertyMapString);
    }

    if (entityPropertyMap && Object.values(entityPropertyMap).length > 0) {
      let entityPropertyMapString = JSON.stringify(entityPropertyMap);
      entityPropertyMapString = entityPropertyMapString.replaceAll(currentSite + "/private", currentSite + "/public");
      data.entities = Object.values(JSON.parse(entityPropertyMapString));
    }

    // Minify HTML, CSS, + JS + replace asset URLs
    // TODO: actually minify
    if (overlayElements && overlayElements.length > 0) {
      data.overlay = overlayElements.replaceAll(currentSite + "/private", currentSite + "/public");
    }

    if (cssProps && cssProps.length > 0) {
      data.css = cssProps.replaceAll(currentSite + "/private", currentSite + "/public");
    }

    if (scripts && scripts.length > 0) {
      let minifiedScripts = [];
      for (const script of scripts) {
        let minifiedScript = {...script};
        if (minifiedScript.script && minifiedScript.script.length > 0) {
          minifiedScript.script = minifiedScript.script.replaceAll(currentSite + "/private", currentSite + "/public");
        }
        minifiedScripts.push(minifiedScript);
      }
      data.scripts = minifiedScripts;
    }

    supabase.from("public").upsert({
      id: currentSite,
      data: data
    }).then((result) => {
      if (!result.error) {
        window.open(window.location.origin + "/view/" + currentSite, "_blank");
      }
    });
  };

  const fetchData = async () => {
    let currentEmail = undefined;
    {
      const { data } = await supabase.auth.getUser();
      setUser(data);
      currentEmail = data?.user?.email;
    }

    let lastVisitedSite = undefined;
    {
      let userData = await getUserDataAndRefreshOwnedItems();
      lastVisitedSite = userData?.last_visited_site;
    }

    {
      let siteData = await refreshSites();
      if (siteData) {
        if (!currentSite) {
          let found = false;
          let siteIndex = 0;
          if (lastVisitedSite) {
            for (let site of siteData) {
              if (site.id === lastVisitedSite) {
                found = true;
                break;
              }
              siteIndex++;
            }
          }

          if (found) {
            onCurrentSiteChange(lastVisitedSite, currentEmail, siteData, siteIndex);
          } else if (siteData.length > 0) {
            onCurrentSiteChange(siteData[0].id, currentEmail, siteData, 0);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!setup) {
      fetchData();

      setSetup(true);
      replaceHTMLIncludes();
      makeElementsDraggable();
    }
  }, []);

  const siteModes = ["New Project", "Edit Project"];
  const marketplaceModes = ["Marketplace", "Inventory", "Listings"];
  const itemListModes = ["Scripts", "Assets", "Apps"];

  return (
    <>
      <div
        onDrop={(e) => {
          dropAssetHandler(e, addAssetAndEntityHandler);
          // Allow the frame to receive pointerEvents again
          editorViewFrameRef.current.style.removeProperty("pointer-events");
        }}
        onDragLeave={(e) => {
          // Allow the frame to receive pointerEvents again
          editorViewFrameRef.current.style.removeProperty("pointer-events");
        }}
        onDragEnter={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        style={{
          width: "100vw",
          height: "100vh"
        }}
      >
        <iframe
          id="editorView"
          key={scriptUpdateCounter}
          className={styles.unselectable}
          title="Gydence 3D Editor View"
          src={window.location.origin + "/editorView" + window.location.search}
          allow="geolocation; microphone; camera; midi; encrypted-media; xr-spatial-tracking; fullscreen"
          allowFullScreen=""
          sandbox="allow-scripts allow-modals allow-forms allow-same-origin allow-top-navigation-by-user-activation allow-downloads"
          onLoad={handleIFrameLoad}
          ref={editorViewFrameRef}
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            zIndex: 0
          }}
        />

        <div
          id="mainPanel"
          className={["draggable", styles.topLeft, styles.block, styles.ui].join(" ")}
          style={{ zIndex: 2 }}
        >
          <label className={styles.unselectable} htmlFor="siteSelection">Current Project:</label>
          <select
            name="siteSelection"
            id="siteSelection"
            className={styles.unselectable}
            value={currentSite}
            onChange={ (e) => { onCurrentSiteChange(e.target.value) } }
          >
            {sites.map((site) => (
              <option key={site.id} className={styles.unselectable} value={site.id}>{site.name}</option>
            ))}
          </select>
          <button
            className={styles.unselectable}
            style={{
              fontSize: "11.5px"
            }}
            onClick={() => setEditorMode("Edit Project")}
          >
            ⚙
          </button>
          <button
            className={styles.unselectable}
            onClick={() => setEditorMode("New Project")}
          >
            +
          </button>
          <div style={{ paddingTop: "5px" }}>
            <span
              className={styles.unselectable}
              style={{
                padding: "2px 10px",
                backgroundColor: ("Scene" === selectedObject) ? "#dddddd" : undefined,
                borderRadius: ("Scene" === selectedObject) ? "10px" : undefined
              }}
              onClick={() => setSelectedObject((selectedObject === "Scene") ? undefined : "Scene")}
            >
              Scene
            </span>
            <span
              className={styles.unselectable}
              style={{
                padding: "2px 10px",
                backgroundColor: ("2D UI" === selectedObject) ? "#dddddd" : undefined,
                borderRadius: ("2D UI" === selectedObject) ? "10px" : undefined
              }}
              onClick={() => setSelectedObject((selectedObject === "2D UI") ? undefined : "2D UI")}
            >
              2D UI
            </span>
          </div>
          <EntityList
            entity={undefined}
            entityPropertyMap={entityPropertyMap}
            entityParentMap={entityParentMap}
            selectedEntity={selectedObject}
            selectHandler={setSelectedObject}
            deleteEntityHandler={deleteEntityHandler}
          />
        </div>

        <div
          id="menuPanel"
          style={{
            width: "100vw",
            zIndex: 1,
            pointerEvents: "none"
          }}
          className={[styles.topMiddle, styles.block].join(" ")}
        >
          <div
            className={[styles.block, styles.ui].join(" ")}
            style={{
              width: "fit-content",
              margin: "0 auto",
              pointerEvents: "auto"
            }}
          >
            <EntityMenu
              editorMode={editorMode}
              setEditorModeHandler={setEditorMode}
              addEntityHandler={addEntityHandler}
            />
          </div>
        </div>

        <div
          id="subPanel"
          className={[styles.topRight, styles.block].join(" ")}
          style={{ zIndex: 1 }}
        >
          {onlineUsers?.length > 0 ?
            <p
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              style={{ float: "left" }}
              title={onlineUsers.join("\n")}
            >
              {onlineUsers.length} other user{onlineUsers.length > 1 ? "s" : ""} editing.
            </p>
            : <></>
          }
          {(user?.user?.email) ?
            <button
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              style={{ marginLeft: "0px" }}
              onClick={() => { supabase.auth.signOut(); }}
            >
              {user.user.email} / Log Out</button>
            : <></>
          }
          {(entityPropertyMap?.[selectedObject] || selectedObject === "Scene") ?
            <div className={[styles.block, styles.ui].join(" ")}>
              <EntityPropertyList
                key={selectedObject}
                props={selectedObject === "Scene" ? scenePropertyMap : entityPropertyMap[selectedObject]}
                updatePropHandler={selectedObject === "Scene" ? updateScenePropHandler : updatePropHandler}
                removePropHandler={selectedObject === "Scene" ? removeScenePropHandler : removePropHandler}
                isScene={selectedObject === "Scene"}
                editorViewFrame={editorViewFrameRef.current}
              />
            </div>
            : (selectedObject === "2D UI") ?
              <div className={[styles.block, styles.ui].join(" ")}>
                <UIEditor
                  overlayElements={overlayElements}
                  cssProps={cssProps}
                  updateOverlayElementHandler={updateOverlayElementHandler}
                  updateCSSHandler={updateCSSHandler}
                />
              </div>
            : (assets.find((asset) => asset.id === selectedObject)) ?
              <div className={[styles.block, styles.ui].join(" ")}>
                <AssetPropEditor
                  key={selectedObject}
                  props={assets.find((asset) => asset.id === selectedObject)}
                  updateAssetNameHandler={updateAssetNameHandler}
                  addEntityFromAssetHandler={addEntityFromAssetHandler}
                  getPublicURL={getPublicURL}
                />
              </div>
            : (ownedAssets.find((asset) => asset.id === selectedObject)) ?
              <div className={[styles.block, styles.ui].join(" ")}>
                <AssetPropEditor
                  key={selectedObject}
                  props={ownedAssets.find((asset) => asset.id === selectedObject)}
                  updateAssetNameHandler={updateAssetNameHandler}
                  addEntityFromAssetHandler={addEntityFromAssetHandler}
                  getPublicURL={getPublicURL}
                  privateURL={true}
                  goToListingHandler={(id) => {
                    setEditorMode("Marketplace");
                    setTargetListing(id);
                  }}
                />
              </div>
            : scripts.find((script) => script.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")}>
                <ScriptPropEditor
                  key={selectedObject}
                  props={scripts.find((script) => script.id === selectedObject)}
                  updatePropHandler={updateScriptPropHandler}
                />
              </div>
            : ownedScripts.find((script) => script.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")}>
                <OwnedScriptViewer
                  key={selectedObject}
                  props={ownedScripts.find((script) => script.id === selectedObject)}
                  addScriptHandler={addScriptHandler}
                  goToListingHandler={(id) => {
                    setEditorMode("Marketplace");
                    setTargetListing(id);
                  }}
                />
              </div>
              : apps.find((app) => app.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")}>
                <AppPropEditor
                  key={selectedObject}
                  props={apps.find((app) => app.id === selectedObject)}
                  updatePropHandler={updateAppPropHandler}
                  appAPI={appAPI}
                />
              </div>
              : ownedApps.find((app) => app.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")}>
                <AppPropEditor
                  key={selectedObject}
                  props={ownedApps.find((app) => app.id === selectedObject)}
                  addAppHandler={addAppHandler}
                  goToListingHandler={(id) => {
                    setEditorMode("Marketplace");
                    setTargetListing(id);
                  }}
                  appAPI={appAPI}
                />
              </div>
              : <></>
          }
        </div>

        <div
          id="publishPanel"
          className={[styles.bottomRight, styles.block].join(" ")}
          style={{ zIndex: 1 }}
        >
          <button
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              style={{ float: "left" }}
              onClick={() => {
                window.open(window.location.origin + "/preview/" + currentSite, "_blank");
              }}
            >
              Preview
            </button>
          <button
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              style={{ marginLeft: "0px" }}
              onClick={publishSite}
            >
              Publish
            </button>
        </div>

        {itemListModes.indexOf(editorMode) !== -1 ?
          <div
            id="itemListPanel"
            className={[styles.bottomLeft, styles.block, styles.unselectable, styles.ui].join(" ")}
            style={{ zIndex: 2 }}
          >
            <ItemListPanel
              key={editorMode + "ItemListPanel"}
              currentSite={currentSite}
              editorMode={editorMode}
              selectedObject={selectedObject}
              setSelectedObject={setSelectedObject}
              items={editorMode === "Scripts" ? scripts : editorMode === "Assets" ? assets : apps}
              ownedItems={editorMode === "Scripts" ? ownedScripts : editorMode === "Assets" ? ownedAssets : ownedApps}
              addHandler={editorMode === "Scripts" ? addScriptHandler : editorMode === "Assets" ? addAssetHandler : addAppHandler}
              onAddHandler={editorMode === "Scripts" ? setScripts : editorMode === "Assets" ? getAssets : setApps}
              dropHandler={editorMode === "Assets" ? dropAssetHandler : undefined}
              setOffsetHandler={editorMode === "Assets" ? setAssetsOffset : undefined}
            />
          </div>
          : <></>
        }

        {siteModes.indexOf(editorMode) !== -1 ?
          <div
            className={styles.centered}
            style={{
              zIndex: 3
            }}
            onClick={() => setEditorMode("Entities")}
          >
            <EditSiteUI
              newSite={editorMode === "New Project"}
              sites={sites}
              currentSite={currentSite}
              siteName={siteName}
              isCreator={user?.user?.email === siteCreator}
              siteEditors={siteEditors}
              templates={ownedTemplates}
              environment={environment}
              environments={ownedEnvironments}
              getSitesHandler={refreshSites}
              setCurrentSiteHandler={onCurrentSiteChange}
              updateSiteNameHandler={updateSiteNameHandler}
              updateSiteEditorsHandler={updateSiteEditorsHandler}
              setEditorModeHandler={setEditorMode}
              setSelectedObjectHandler={setSelectedObject}
              addEntityHandler={addEntityHandler}
              updateScenePropHandler={updateScenePropHandler}
              setEnvironmentHandler={setEnvironmentHandler}
              updateOverlayElementHandler={updateOverlayElementHandler}
              updateCSSHandler={updateCSSHandler}
              addScriptHandler={addScriptHandler}
              addAppHandler={addAppHandler}
              setTargetListing={setTargetListing}
            />
          </div>
          : <></>
        }

        {marketplaceModes.indexOf(editorMode) !== -1 ?
          <div
            className={styles.centered}
            style={{
              zIndex: 3
            }}
            onClick={() => {
              setEditorMode("Entities");
              setTargetListing(undefined);
            }}
          >
            <Marketplace
              mode={editorMode}
              startingSortMode={editorMode === "Inventory" ? "owned" :
                                editorMode === "Listings" ? "mine" : undefined}
              ownedItems={ownedItems}
              ownedListings={ownedListings}
              user={user}
              setEditorModeHandler={setEditorMode}
              targetListing={targetListing}
              setTargetListingHandler={setTargetListing}
              refreshOwnedItemsHandler={getUserDataAndRefreshOwnedItems}
            />
          </div>
          : <></>
        }

        {editorMode === "Upload" ?
          <div
            className={styles.centered}
            style={{
              zIndex: 3
            }}
            onClick={() => {
              setEditorMode("Entities");
              setTargetListing(undefined);
            }}
          >
            <UploadManager
              user={user}
              currentSite={currentSite}
              setEditorModeHandler={setEditorMode}
              setTargetListingHandler={setTargetListing}
              refreshOwnedItemsHandler={getUserDataAndRefreshOwnedItems}
              scenePropertyMap={scenePropertyMap}
              entityPropertyMap={entityPropertyMap}
              entityParentMap={entityParentMap}
              overlayElements={overlayElements}
              cssProps={cssProps}
              scripts={scripts}
              apps={apps}
              getPublicURL={getPublicURL}
            />
          </div>
          : <></>
        }
      </div>
    </>
  )
}

export default function Editor() {
  return (
    <EnforceSignedIn>
      <EnforceOnboarded>
        <EditorMain />
      </EnforceOnboarded>
    </EnforceSignedIn>
  )
}