import { useState } from "react";

import styles from "./index.module.css";

export default function EntityList({ entity, entityPropertyMap, entityParentMap, selectedEntity, selectHandler, deleteEntityHandler }) {
  const [isExpandedMap, setIsExpandedMap] = useState({});
  const expand = (id) => {
    let currIsExpandedMap = {...isExpandedMap};
    if (!(id in currIsExpandedMap)) {
      currIsExpandedMap[id] = true;
    } else {
      currIsExpandedMap[id] = !currIsExpandedMap[id];
    }
    setIsExpandedMap(currIsExpandedMap);
  };

  return (
    <>
      {entityParentMap?.[entity]?.map((child) => {
        return (
          <div
            key={["div_", child].join("")}
            style={{
              paddingLeft: entity ? 10 : 0,
              textAlign: "left"
            }}>
            {(entityParentMap?.[child]?.length > 0) ?
              <span
                key={["span_", child].join("")}
                className={styles.unselectable}
                onClick={() => expand(child)}>{isExpandedMap[child] ? "v" : ">"} </span>
              : <span
                  key={["span_", child].join("")}
                  className={styles.unselectable}
                  style={{paddingRight: "14px"}}
                ></span>
            }
            <span
              className={styles.unselectable}
              style={{
                padding: "2px 10px",
                backgroundColor: (child === selectedEntity) ? "#dddddd" : undefined,
                borderRadius: (child === selectedEntity) ? "10px" : undefined
              }}
              onClick={() => selectHandler((selectedEntity === child) ? undefined : child)}
            >
              {entityPropertyMap?.[child]?.name ?? child}
            </span>
            <button
                className={styles.unselectable}
                key={["button_", child].join("")}
                onClick={() => deleteEntityHandler(child) }>-</button>
            {isExpandedMap[child] ? (
              <EntityList
                key={child}
                entity={child}
                entityPropertyMap={entityPropertyMap}
                entityParentMap={entityParentMap}
                selectedEntity={selectedEntity}
                selectHandler={selectHandler}
                deleteEntityHandler={deleteEntityHandler}
              />
              )
              : <></>
            }
          </div>
        )
      })}
    </>
  );
}