import { useEffect, useState, useRef } from "react";

import { supabase } from "../pages/signin";

import styles from "../pages/index.module.css"

export const NUM_ITEMS_PER_PAGE = 10;

export function PageControls({ hasMoreItems, page, setPage }) {
  return (
    <div
      className={[styles.bottomMiddle, styles.block].join(" ")}
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%"
        }}
      >
        {page > 0 ?
          <button onClick={() => setPage(page - 1)}>{"<"}</button>
          : <></>
        }
        <span>Page {page + 1}</span>
        {hasMoreItems ?
          <button
            style={{
              position: "absolute",
              right: "0px"
            }}
            onClick={() => setPage(page + 1)}
          >
            {">"}
          </button>
          : <></>
        }
      </div>
    </div>
  )
}

function ItemList({ items, isPaged, page, setPageHandler, selectedItem, selectHandler,
    deleteItemHandler, getItemText }) {
  const isDeletable = deleteItemHandler;

  const hasMoreItems = isPaged ? items.length > NUM_ITEMS_PER_PAGE : ((page + 1) * NUM_ITEMS_PER_PAGE < items.length);

  return (
    <div
      style={{
        textAlign: "left"
      }}
    >
      {items ? [...Array(Math.min(Math.max(isPaged ? items.length : (items.length - page * NUM_ITEMS_PER_PAGE), 0), NUM_ITEMS_PER_PAGE))].map((_, i) => {
          if ((isPaged ? i : (page * NUM_ITEMS_PER_PAGE + i)) >= items.length) {
            return;
          }

          const item = items[isPaged ? i : (page * NUM_ITEMS_PER_PAGE + i)];
          return <div key={["div_", item.id].join("")}>
            <span
              key={item.id}
              style={{
                backgroundColor: (item.id === selectedItem) ? "#dddddd" : undefined,
                borderRadius: (item.id === selectedItem) ? "10px" : undefined
              }}
              onClick={() => selectHandler(selectedItem === item.id ? undefined : item.id)}
            >
              {getItemText(item)}
            </span>
            {isDeletable ?
              <button
                key={["button_", item.id].join("")}
                onClick={() => deleteItemHandler(item.name)}
              >
                -
              </button>
              : <></>
            }
            <br key={["br_", item.id].join("")} />
          </div>
        })
        : <></>
      }

      {hasMoreItems || page > 0 ?
        <PageControls
          hasMoreItems={hasMoreItems}
          page={page}
          setPage={setPageHandler}
        />
        : <></>
      }
    </div>
  )
}

export function ItemListPanel({ currentSite, editorMode, selectedObject, setSelectedObject,
  items, ownedItems, addHandler, onAddHandler, dropHandler, setOffsetHandler }) {
  const modes = ["Site", "Owned"];
  const [mode, setMode] = useState(modes[0]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [mode]);

  useEffect(() => {
    if (setOffsetHandler) {
      setOffsetHandler(page * NUM_ITEMS_PER_PAGE);
    }
  }, [page]);

  const deleteItemHandler = (item) => {
    const itemIndex = items.findIndex((el) => el.id === item);
    supabase.rpc(editorMode === "Scripts" ? "delete_script" : "delete_app", {
      site: currentSite,
      scriptindex: editorMode === "Scripts" ? itemIndex : undefined,
      appindex: editorMode === "Apps" ? itemIndex : undefined,
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        // Remove this item from the list
        let currentItems = [...items];
        currentItems.splice(itemIndex, 1);
        onAddHandler(currentItems);

        // If this was the selected item, clear it
        if (selectedObject === item) {
          setSelectedObject(undefined);
        }
      }
    });
  };

  const deleteAssetHandler = async (fileName) => {
    const { error } = await supabase.storage.from("storage")
      .remove(["sites/" + currentSite + "/private/" + fileName]);

    if (!error) {
      onAddHandler(currentSite);
    }
  };

  const uploadAssetInputRef = useRef(undefined);

  const isPaged = setOffsetHandler;
  const deleteHandler = editorMode === "Assets" ? deleteAssetHandler : deleteItemHandler;
  const getItemText = editorMode === "Assets" ?
    (item) => {return item.name}
    : (item) => {
      return (item.name?.length > 0) ? item.name :
        (item.url?.length > 0) ? item.url : item.id;
    };

  return (
    <div
      style={{
        position: "relative",
        width: "400px",
        height: "400px"
      }}
      onDrop={dropHandler ? (e) => dropHandler(e, addHandler) : undefined}
      onDragEnter={dropHandler ? (e) => e.preventDefault() : undefined}
      onDragOver={dropHandler ? (e) => e.preventDefault() : undefined}
    >
      {modes.length > 1 ? modes.map((modeValue) => {
          return <span
            key={modeValue}
            className={styles.unselectable}
            style={{
              padding: "2px 10px",
              backgroundColor: mode === modeValue ? "#dddddd" : undefined,
              borderRadius: mode === modeValue ? "10px" : undefined
            }}
            onClick={() => setMode(modeValue)}
          >
            {modeValue}
          </span>
        })
        : <></>
      }
      <br />
      <br />

      <div
        style={{
          position: "relative",
          height: "300px"
        }}
      >
        <ItemList
          items={mode === "Site" ? items : ownedItems}
          isPaged={mode === "Site" ? isPaged : false}
          page={page}
          setPageHandler={setPage}
          selectedItem={selectedObject}
          selectHandler={setSelectedObject}
          deleteItemHandler={mode === "Site" ? deleteHandler : undefined}
          getItemText={getItemText}
        />
      </div>

      {editorMode === "Scripts" ?
        <div
          className={[styles.bottomMiddle, styles.block].join(" ")}
          style={{ width: "100%" }}
        >
          <button
            className={styles.unselectable}
            onClick={() => addHandler({
              name: "",
              url: ""
            })}
          >
            Add Script URL
          </button>
          <button
            className={styles.unselectable}
            onClick={() => addHandler({
              name: "",
              script: "",
              module: false
            })}
          >
            Add Script Block
          </button>
        </div>
        : editorMode === "Assets" ?
          <div
            className={[styles.bottomMiddle, styles.block].join(" ")}
            style={{ width: "100%" }}
          >
            <label>
              <button
                className={styles.unselectable}
                onClick={() => uploadAssetInputRef.current.click()}
              >
                Upload Asset
              </button>
              <input
                className={styles.unselectable}
                ref={uploadAssetInputRef}
                type="file"
                onInput={(e) => addHandler(e.target.files[0])}
                style={{
                  display: "none"
              }} />
            </label>
          </div>
          : <div
              className={[styles.bottomMiddle, styles.block].join(" ")}
              style={{ width: "100%" }}
            >
              <button
                className={styles.unselectable}
                onClick={() => addHandler({
                  name: "",
                  html: "",
                  css: "",
                  script: ""
                })}
              >
                Add App
              </button>
            </div>
      }
    </div>
  )
}