import "aframe";

class FontLoader extends THREE.Loader {
  constructor(manager) {
    super(manager);
  }

  load(url, onLoad, onProgress, onError) {
    const scope = this;

    const loader = new THREE.FileLoader(this.manager);
    loader.setPath(this.path);
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);
    loader.load(
      url,
      function (text) {
        const font = scope.parse(JSON.parse(text));

        if (onLoad) {
          onLoad(font);
        }
      },
      onProgress,
      onError
    );
  }

  parse(json) {
    return new Font(json);
  }
}

class Font {
  constructor(data) {
    this.isFont = true;
    this.type = "Font";
    this.data = data;
  }

  generateShapes(text, size = 100) {
    const shapes = [];
    const paths = createPaths(text, size, this.data);

    for (let p = 0, pl = paths.length; p < pl; p++) {
      shapes.push(...paths[p].toShapes());
    }

    return shapes;
  }
}

function createPaths(text, size, data) {
  const chars = Array.from(text);
  const scale = size / data.resolution;
  const line_height =
    (data.boundingBox.yMax - data.boundingBox.yMin + data.underlineThickness) *
    scale;

  const paths = [];

  let offsetX = 0,
    offsetY = 0;

  for (let i = 0; i < chars.length; i++) {
    const char = chars[i];

    if (char === "\n") {
      offsetX = 0;
      offsetY -= line_height;
    } else {
      const ret = createPath(char, scale, offsetX, offsetY, data);
      offsetX += ret.offsetX;
      paths.push(ret.path);
    }
  }

  return paths;
}

function createPath(char, scale, offsetX, offsetY, data) {
  const glyph = data.glyphs[char] || data.glyphs["?"];

  if (!glyph) {
    console.error(
      "THREE.Font: character \"" +
        char +
        "\" does not exists in font family " +
        data.familyName +
        "."
    );

    return;
  }

  const path = new THREE.ShapePath();

  let x, y, cpx, cpy, cpx1, cpy1, cpx2, cpy2;

  if (glyph.o) {
    const outline =
      glyph._cachedOutline || (glyph._cachedOutline = glyph.o.split(" "));

    for (let i = 0, l = outline.length; i < l; ) {
      const action = outline[i++];

      switch (action) {
        case "m": // moveTo
          x = outline[i++] * scale + offsetX;
          y = outline[i++] * scale + offsetY;

          path.moveTo(x, y);

          break;

        case "l": // lineTo
          x = outline[i++] * scale + offsetX;
          y = outline[i++] * scale + offsetY;

          path.lineTo(x, y);

          break;

        case "q": // quadraticCurveTo
          cpx = outline[i++] * scale + offsetX;
          cpy = outline[i++] * scale + offsetY;
          cpx1 = outline[i++] * scale + offsetX;
          cpy1 = outline[i++] * scale + offsetY;

          path.quadraticCurveTo(cpx1, cpy1, cpx, cpy);

          break;

        case "b": // bezierCurveTo
          cpx = outline[i++] * scale + offsetX;
          cpy = outline[i++] * scale + offsetY;
          cpx1 = outline[i++] * scale + offsetX;
          cpy1 = outline[i++] * scale + offsetY;
          cpx2 = outline[i++] * scale + offsetX;
          cpy2 = outline[i++] * scale + offsetY;

          path.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, cpx, cpy);

          break;
      }
    }
  }

  return { offsetX: glyph.ha * scale, path: path };
}

class TextGeometry extends THREE.ExtrudeGeometry {
  constructor(text, parameters = {}) {
    const font = parameters.font;

    if (font === undefined) {
      super(); // generate default extrude geometry
    } else {
      const shapes = font.generateShapes(text, parameters.size);

      if (parameters.depth === undefined) {
        parameters.depth = 0.05;
      }
      if (parameters.bevelThickness === undefined) {
        parameters.bevelThickness = 10;
      }
      if (parameters.bevelSize === undefined) {
        parameters.bevelSize = 8;
      }
      if (parameters.bevelEnabled === undefined) {
        parameters.bevelEnabled = false;
      }

      super(shapes, parameters);
    }

    this.type = "TextGeometry";
  }
}

var fontLoader = new FontLoader();

AFRAME.registerComponent("text3d", {
  schema: {
    bevelEnabled: { default: true },
    bevelSize: { default: 0.01, min: 0 },
    bevelThickness: { default: 0.01, min: 0 },
    curveSegments: { default: 12, min: 0 },
    font: {
      type: "asset",
      default:
        "https://rawgit.com/ngokevin/kframe/master/components/text-geometry/lib/helvetiker_regular.typeface.json",
    },
    depth: { default: 0.1, min: 0 },
    size: { default: 0.5, min: 0 },
    value: { default: "" },
    color: { default: "#FFF" }
  },

  update: function (oldData) {
    var self = this;

    if (!self.mesh) {
      self.mesh = new THREE.Mesh();
      self.el.object3D.add(self.mesh);
    }

    if (oldData.color !== this.data.color) {
      self.mesh.material = new THREE.MeshStandardMaterial({
        color: this.data.color,
        emissive: "rgb(30, 3, 7)",
        roughness: 0.3,
        metalness: 0.8
      });
    }

    if (self.data.font.constructor === String) {
      fontLoader.load(self.data.font, function (response) {
        var textData = AFRAME.utils.clone(self.data);
        textData.font = response;
        self.mesh.geometry = new TextGeometry(self.data.value, textData);

        if (self.mesh.geometry) {
          self.mesh.geometry.computeBoundingBox();
          let halfSize = new THREE.Vector3();
          self.mesh.geometry.boundingBox.getCenter(halfSize);
          self.mesh.geometry.translate(-halfSize.x, -halfSize.y, -halfSize.z);

          const event = new CustomEvent("model-loaded", {});
          self.el.dispatchEvent(event);
        }
      });
    } else if (self.data.font.constructor === Object) {
      self.mesh.geometry = new TextGeometry(self.data.value, self.data);

      if (self.mesh.geometry) {
        self.mesh.geometry.computeBoundingBox();
        let halfSize = new THREE.Vector3();
        self.mesh.geometry.boundingBox.getCenter(halfSize);
        self.mesh.geometry.translate(-halfSize.x, -halfSize.y, -halfSize.z);

        const event = new CustomEvent("model-loaded", {});
        self.el.dispatchEvent(event);
      }
    } else {
      console.error("Must provide `font` (typeface.json) to text component.");
    }
  },
});

AFRAME.registerComponent("timer", {
  schema: {
    time: { default: 5.00 },
  },

  update: function () {
    let seconds = this.data.time * 60;
    this.minutes = Math.floor(this.data.time);
    this.seconds = seconds - this.minutes * 60.0;
  },

  tick: function (t, dt) {
    if (this.seconds === undefined) {
      return;
    }

    let minutes = this.minutes;
    let seconds = this.seconds;

    if (seconds >= 0) {
      seconds -= dt / 1000.0;
    }

    if (seconds < 0.0 && minutes > 0) {
      seconds += 60;
      minutes = Math.max(0, minutes - 1);
    }

    let secondsInt = Math.floor(seconds);

    if (secondsInt >= 0 && secondsInt !== Math.floor(this.seconds)) {
      this.el.setAttribute("text3d", {
        value:
          minutes.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ":" +
          secondsInt.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
      });
    }

    this.minutes = minutes;
    this.seconds = seconds;
  },
});
