// Animations
let onLoadAnimations = [];
let onTargetFoundAnimations = [];
let onTargetLostAnimations = [];
let onMouseOverAnimations = [];
let onMouseLeaveAnimations = [];
let onClickAnimations = [];

let animID = 0;
function playAnimation(animationProperties, selector) {
  let entity = document.querySelector(
    "#" + (animationProperties.target ? animationProperties.target : selector)
  );
  if (entity) {
    let animToPlayAfter = animID;
    let maxAfterTime = 0;
    if (animationProperties.data) {
      for (const data of animationProperties.data) {
        let timeAfter = 0;
        if (data.delay) {
          timeAfter += data.delay;
        }
        if (data.dur) {
          timeAfter += data.dur;
        }
        if (timeAfter > maxAfterTime) {
          animToPlayAfter = animID;
          maxAfterTime = timeAfter;
        }
        let animationName = "animation__" + animID;
        entity.setAttribute(animationName, data);
        entity.addEventListener("animationcomplete__" + animID, function () {
          entity.removeAttribute(animationName);
        });
        animID++;
      }
    }

    // Play any animations that start at the same time
    if (animationProperties.withAnimations) {
      for (const withAnimation of animationProperties.withAnimations) {
        playAnimation(withAnimation, withAnimation.entity);
      }
    }

    // Play any animations that start after
    if (animationProperties.afterAnimations) {
      for (const afterAnimation of animationProperties.afterAnimations) {
        entity.addEventListener(
          "animationcomplete__" + animToPlayAfter,
          function () {
            playAnimation(afterAnimation, afterAnimation.entity);
          }
        );
      }
    }
  }
}

function animationHelper(animation, eventName) {
  const addListener = function (entity, entityName) {
    entity.addEventListener(eventName, (event) => {
      if (document.isEditting) {
        return;
      }

      if (!animation.propogate) {
        event.stopPropagation();
      }
      playAnimation(animation, entityName);
    });
  };

  for (const entityName of animation.entity) {
    const entity = document.querySelector("#" + entityName);
    if (entity) {
      addListener(entity, entityName);
    } else {
      // HACK: not all injected DOM elements will have loaded in time.  wait a second for them
      setTimeout(function () {
        const entity = document.querySelector("#" + entityName);
        if (entity) {
          addListener(entity, entityName);
        }
      }, 1000);
    }
  }
}

function playInitialAnimations() {
  for (const animation of onLoadAnimations) {
    for (const entityName of animation.entity) {
      playAnimation(animation, entityName);
    }
  }
}

document.addEventListener("resetScene", function () {
  let entities = document.querySelectorAll("*");
  for (const entity of entities) {
    for (let i = 0; i < animID; i++) {
      let animationName = "animation__" + i;
      let animation = entity.getAttribute(animationName);
      if (animation) {
        let fromValue = animation.from;
        if (fromValue) {
          // TODO: this doesn't work with things like material.opacity
          entity.setAttribute(animation.property, fromValue);
        }
      }
      entity.removeAttribute(animationName);
    }
  }
  animID = 0;
  playInitialAnimations();
});

document.addEventListener("camerasConfigured", function () {
  playInitialAnimations();

  // Mouse/Controller events
  for (const animation of onMouseOverAnimations) {
    animationHelper(animation, "mouseenter");
  }

  for (const animation of onMouseLeaveAnimations) {
    animationHelper(animation, "mouseleave");
  }

  for (const animation of onClickAnimations) {
    animationHelper(animation, "click");
  }

  // Tracking
  for (const animation of onTargetFoundAnimations) {
    const target = document.querySelector("#tracker" + animation.tracker);
    if (target) {
      target.addEventListener("targetFound", (event) => {
        for (const entity of animation.entity) {
          playAnimation(animation, entity);
        }
      });
    }
  }

  for (const animation of onTargetLostAnimations) {
    const target = document.querySelector("#tracker" + animation.tracker);
    if (target) {
      target.addEventListener("targetLost", (event) => {
        for (const entity of animation.entity) {
          playAnimation(animation, entity);
        }
      });
    }
  }
});
