import { useState } from "react";

import styles from "./index.module.css";

export default function EntityMenu({ editorMode, setEditorModeHandler, addEntityHandler }) {
  let [currentMenu, setCurrentMenu] = useState(undefined);
  let [counters, setCounters] = useState({});

  let menuOptions = [
    {
      name: "Layout",
    },
    {
      name: "Elements",
    },
    {
      name: "Scripts",
      func: (wasSelected) => { setEditorModeHandler(wasSelected ? "Entities" : "Scripts"); }
    },
    {
      name: "Assets",
      func: (wasSelected) => { setEditorModeHandler(wasSelected ? "Entities" : "Assets"); }
    },
    {
      name: "Apps",
      func: (wasSelected) => { setEditorModeHandler(wasSelected ? "Entities" : "Apps"); }
    },
    {
      name: "Marketplace",
    }
  ];

  let updateCounter = (menuAndSubMenu) => {
    // TODO: start count at highest existing element
    let currCounters = {...counters};
    let toReturn = currCounters[menuAndSubMenu] ? ++currCounters[menuAndSubMenu] : 1;
    currCounters[menuAndSubMenu] = toReturn;
    setCounters(currCounters);
    return toReturn;
  };

  let subMenuOptions = {
    "Layout": [
      {
        name: "Tri-Fold",
        func: (menuAndSubMenu) => {
          let newCount = updateCounter(menuAndSubMenu);

          addEntityHandler([{
              position: "0 1.6 -1",
              scale: "1 1 1",
              rotation: "0 0 0",
              container: "size:3 1 0.25",
              name: "Tri-Fold Container " + newCount,
            },
            {
              position: "-1 0 0.1",
              scale: "1 1 1",
              rotation: "0 30 0",
              container: "size:1 1 0.1",
              parent: -1,
              name: "Left Container " + newCount,
            },
            {
              position: "0 0 0",
              scale: "1 1 1",
              rotation: "0 0 0",
              container: "size:1 1 0.1",
              parent: -2,
              name: "Center Container " + newCount,
            },
            {
              position: "1 0 0.1",
              scale: "1 1 1",
              rotation: "0 -30 0",
              container: "size:1 1 0.1",
              parent: -3,
              name: "Right Container " + newCount,
            }
          ]);
        }
      }
    ],
    "Elements": [
      {
        name: "Text",
        func: (menuAndSubMenu) => {
          let newCount = updateCounter(menuAndSubMenu);

          addEntityHandler([{
            position: "0 0 -1",
            scale: "4 4 4",
            rotation: "0 0 0",
            text: "value:I'm a text entity!;align:center;color:pink;",
            name: "Text " + newCount,
          }]);
        }
      },
      {
        name: "Button",
      },
      {
        name: "Shape",
        func: (menuAndSubMenu) => {
          let newCount = updateCounter(menuAndSubMenu);

          addEntityHandler([{
            position: "0 0 -1",
            scale: "1 1 1",
            rotation: "0 0 0",
            geometry: "primitive:box",
            material: "color:pink",
            name: "Shape " + newCount,
          }]);
        }
      },
      {
        name: "Model",
      },
      {
        name: "Image",
        func: (menuAndSubMenu) => {
          let newCount = updateCounter(menuAndSubMenu);

          addEntityHandler([{
            position: "0 0 -1",
            scale: "1 1 1",
            rotation: "0 0 0",
            geometry: "primitive:plane",
            material: "src:" + process.env.PUBLIC_URL + "/assets/imageEntity.jpeg",
            name: "Image " + newCount,
          }]);
        }
      },
      {
        name: "Video",
      },
      {
        name: "HTML",
      },
      {
        name: "Container",
        func: (menuAndSubMenu) => {
          let newCount = updateCounter(menuAndSubMenu);

          addEntityHandler([{
            position: "0 0 -1",
            scale: "1 1 1",
            rotation: "0 0 0",
            container: "size:1 1 1",
            name: "Container " + newCount,
          }]);
        }
      }
    ],
    "Scripts": [
    ],
    "Assets": [
    ],
    "Apps": [
    ],
    "Marketplace": [
      {
        name: "Browse",
        func: () => { setEditorModeHandler("Marketplace"); }
      },
      {
        name: "Inventory",
        func: () => { setEditorModeHandler("Inventory"); }
      },
      {
        name: "My Listings",
        func: () => { setEditorModeHandler("Listings"); }
      },
      {
        name: "Upload",
        func: () => { setEditorModeHandler("Upload"); }
      }
    ],
  };

  return (
    <div>
      {menuOptions.map((option) => {
        return <span
          key={option.name}
          value={option.name}
          className={styles.unselectable}
          style={{
            padding: "2px 10px",
            backgroundColor: (currentMenu === option.name || editorMode === option.name) ? "#dddddd" : undefined,
            borderRadius: (currentMenu === option.name || editorMode === option.name) ? "10px" : undefined,
            color: (subMenuOptions[option.name]?.length === 0 && !option.func) ? "#AAAAAA" : undefined,
          }}
          onClick={() => {
            if (option.func) {
              option.func(editorMode === option.name);
            } else {
              setCurrentMenu(currentMenu === option.name ? undefined : option.name);
            }
          }}
        >{option.name}</span>
      })}
      {(currentMenu && subMenuOptions[currentMenu]?.length > 0) ?
        <div style={{paddingTop: "10px"}}>
          {subMenuOptions[currentMenu].map((option) => {
            return <span
              key={option.name}
              value={option.name}
              className={styles.unselectable}
              style={{
                padding: "0 10px",
                color: (!option.func) ? "#AAAAAA" : undefined,
              }}
              onClick={() => {
                if (option.func) {
                  option.func(currentMenu + option.name);
                }
              }}
            >{option.name}</span>
          })}
        </div>
          : <></>
      }
    </div>
  )
}