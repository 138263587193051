import "aframe";

const ENABLE_CLIPPING = true;

AFRAME.registerComponent("list", {
  schema: {
    step: {
      type: "vec3",
    },
    numVisible: {
      default: 1,
    }
  },

  updateVisible: function (entity) {
    if (ENABLE_CLIPPING) {
      entity.visible = Math.abs(entity.position.x / this.data.step.x) < 0.5 * this.data.numVisible + 1;
    }
  },

  updateClipping: function () {
    if (ENABLE_CLIPPING) {
      this.el.object3D.updateMatrixWorld();
      this.plane1 = this.localPlane1.clone();
      this.plane1.applyMatrix4(this.el.object3D.matrixWorld);
      this.plane2 = this.localPlane2.clone();
      this.plane2.applyMatrix4(this.el.object3D.matrixWorld);
      const children = this.el.object3D.children;
      for (const child of children) {
        child.el.components.material.material.clippingPlanes = [
          this.plane1,
          this.plane2,
        ];
      }
    }
  },

  scroll: function (delta) {
    let children = this.el.object3D.children;
    for (const child of children) {
      this.vector.copy(this.data.step);
      this.vector.multiplyScalar(delta / 50.0);
      child.position.add(this.vector);

      let factor = child.position.x / this.data.step.x;
      const halfLength = 0.5 * children.length;
      if (factor > halfLength) {
        factor = factor % halfLength;
        this.vector.copy(this.data.step);
        this.vector.multiplyScalar(factor);
        this.vector.sub(this.fullStep);
        child.position.copy(this.vector);
      } else if (factor < -halfLength) {
        factor = -factor % halfLength;
        this.vector.copy(this.data.step);
        this.vector.multiplyScalar(-factor);
        this.vector.add(this.fullStep);
        child.position.copy(this.vector);
      }

      this.updateVisible(child);
    }
  },

  init: function () {
    // If we have any lists, we need clipping
    // TODO: should we set this back to false at some later time?
    if (ENABLE_CLIPPING) {
      this.el.sceneEl.renderer.localClippingEnabled = true;

      let step = new THREE.Vector3();
      step.copy(this.data.step);
      const stepLength = this.data.numVisible * step.length();
      step.normalize();

      this.localPlane1 = new THREE.Plane(step, 0.5 * stepLength);
      this.localPlane2 = this.localPlane1.clone();
      this.localPlane2.negate();
      this.localPlane2.constant = this.localPlane1.constant;
    }

    const children = this.el.object3D.children;

    this.vector = new THREE.Vector3();
    this.fullStep = new THREE.Vector3();
    this.fullStep.copy(this.data.step);
    this.fullStep.multiplyScalar(children.length);

    let index = 0;
    for (const child of children) {
      child.position.copy(this.data.step);
      child.position.multiplyScalar(index);

      if (index > 0.5 * children.length) {
        child.position.sub(this.fullStep);
      }

      this.updateVisible(child);

      if (ENABLE_CLIPPING) {
        child.el.components.material.material.clipShadows = true;
      }
      index++;
    }
    this.fullStep.multiplyScalar(0.5);

    this.updateClipping();

    // Setup scrolling
    const self = this;
    self.scrolling = false;
    self.prevX = null;
    self.delta = 0;

    const startScrolling = function (event) {
      self.scrolling = true;
    };
    const stopScrolling = function (event) {
      self.scrolling = false;
      self.prevX = null;
    };

    document.addEventListener("mousedown", startScrolling);
    document.addEventListener("mouseup", stopScrolling);
    document.addEventListener("touchstart", startScrolling);
    document.addEventListener("touchend", stopScrolling);

    document.addEventListener("mousemove", function (event) {
      if (self.scrolling) {
        if (self.prevX) {
          self.delta += event.clientX - self.prevX;
        }
        self.prevX = event.clientX;
      }
    });
    document.addEventListener("touchmove", function (event) {
      if (self.scrolling) {
        if (self.prevX) {
          // TODO: handle multiple touches
          self.delta += event.changedTouches[0].clientX - self.prevX;
        }
        self.prevX = event.changedTouches[0].clientX;
      }
    });
  },

  tick: function (dt) {
    if (this.delta !== 0) {
      this.scroll(this.delta);
    }
    this.delta = 0;

    this.updateClipping();
  },
});
