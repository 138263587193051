import "aframe";

// TODO: convert to rematerial
AFRAME.registerComponent("stencil-surface", {
  schema: {
    mask: { default: 1 },
    order: { default: 1 },
  },

  init: function () {
    let self = this;
    this.el.addEventListener("model-loaded", function () { self.updateMaterials(self); });
    this.el.addEventListener("gltf-loaded", function () { self.updateMaterials(self); });
    self.updateMaterials(self);
  },

  updateMaterials: function (self) {
    self.el.object3D.traverse(function (node) {
      // FIXME: the ordering is not guarenteed so this can overwrite the children's stencil-member
      // node.stencilMember is a hack to get around this
      if (!node.stencilMember) {
        node.renderOrder = self.data.order;
        if (node.material) {
          node.material.colorWrite = false;
          node.material.depthWrite = false;
          node.material.stencilWrite = true;
          node.material.stencilRef = self.data.mask;
          node.material.stencilFuncMask = 0xFF;
          node.material.stencilFunc= THREE.AlwaysStencilFunc;
          node.material.stencilFail= THREE.ReplaceStencilOp;
          node.material.stencilZFail= THREE.ReplaceStencilOp;
          node.material.stencilZPass= THREE.ReplaceStencilOp;
        }
      }
    });
  },
});

AFRAME.registerComponent("stencil-member", {
  schema: {
    mask: { default: 1 },
    order: { default: 2 },
  },

  init: function () {
    let self = this;
    self.el.addEventListener("model-loaded", function () { self.updateMaterials(self); });
    self.el.addEventListener("gltf-loaded", function () { self.updateMaterials(self); });
    self.updateMaterials(self);
  },

  updateMaterials: function (self) {
    self.el.object3D.traverse(function (node) {
      node.renderOrder = self.data.order;
      if (node.material) {
        node.material.colorWrite = true;
        node.material.depthWrite = true;
        node.material.stencilWrite = true;
        node.material.stencilRef = self.data.mask;
        node.material.stencilFuncMask = 0xFF;
        node.material.stencilFunc = THREE.EqualStencilFunc;
        node.material.stencilFail = THREE.KeepStencilOp;
        node.material.stencilZFail = THREE.KeepStencilOp;
        node.material.stencilZPass = THREE.ReplaceStencilOp;
      }
      node.stencilMember = true;
    });
  },
});
