import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { supabase } from "./signin";
import GridList from "../components/gridList";

import styles from "./index.module.css";
import { PageControls } from "../components/itemListEditor";
import { ControlledInput } from "../components/controlledInput";

const NUM_LISTINGS_PER_PAGE = 12;
const NUM_COLUMNS = 4;
const sortingOptions = ["featured", "popular", "recent", "owned", "mine"];
const filterOptions = ["template", "script", "asset", "app", "environment"];

export default function Marketplace({ mode, startingSortMode, ownedItems, ownedListings,
    user, setEditorModeHandler, targetListing, setTargetListingHandler, refreshOwnedItemsHandler }) {
  const [page, setPage] = useState(0);
  const [sortMode, setSortMode] = useState(startingSortMode ?? sortingOptions[0]);
  const [filters, setFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState([]);

  const [selectedListing, setSelectedListing] = useState(targetListing ?? undefined);
  const [selectedListingData, setSelectedListingData] = useState(undefined);
  const [selectedVersion, setSelectedVersion] = useState(undefined);
  const [selectedVersionData, setSelectedVersionData] = useState(undefined);

  const purchaseItem = (listing, item) => {
    supabase.rpc("purchase_item", {
      listing: listing,
      item: item
    }).then(async (result) => {
      console.log(result);
      if (!result.error) {
        refreshOwnedItemsHandler();
      }
    });
  };

  useEffect(() => {
    setPage(0);
  }, [mode, sortMode, filters, searchText]);

  useEffect(() => {
    if (selectedListing) {
      const fetchData = async () => {
        const { data } = await supabase.from("marketplace_listings").select("*")
          .eq("id", selectedListing).limit(1);
        setSelectedListingData(data?.length > 0 ? data?.[0] : undefined);

        const ownedSelectedListing = ownedItems ? data?.[0]?.versions?.find((version) => ownedItems.find((item) => item === version)) : undefined;
        setSelectedVersion(ownedSelectedListing ?? data?.[0]?.versions?.slice(-1)[0]);
      };
      fetchData();
    } else {
      setSelectedVersion(undefined);
      setSelectedListingData(undefined);
    }
  }, [selectedListing]);

  useEffect(() => {
    if (selectedVersion) {
      const fetchData = async () => {
        const { data } = await supabase.from("marketplace_items").select("*")
          .eq("under_review", false).eq("id", selectedVersion).limit(1);
          setSelectedVersionData(data?.length > 0 ? data[0] : undefined);
      };
      fetchData();
    } else {
      setSelectedVersionData(undefined);
    }
  }, [selectedVersion]);

  useEffect(() => {
    const fetchData = async () => {
      let query = supabase.from("marketplace_listings").select("*");

      // TODO: featured, popular
      // Filters
      switch (sortMode) {
        case "recent":
          break;
        case "owned":
          query = query.in("id", ownedListings);
          break;
        case "mine":
          query = query.eq("creator", user?.user?.id);
          break;
        default:
          break;
      }

      if (sortMode !== "mine") {
        query = query.eq("under_review", false).eq("listed", true);
      }

      if (filters.length > 0) {
        query = query.in("type", filters);
      }

      if (searchText.length > 0) {
        query = query.textSearch("search_index", searchText, {
          type: "websearch",
          config: "english"
        });
      }

      // Modifiers (must come after filters)
      query = query.range(page * NUM_LISTINGS_PER_PAGE, page * NUM_LISTINGS_PER_PAGE + NUM_LISTINGS_PER_PAGE);

      switch (sortMode) {
        case "recent":
        case "mine":
          query = query.order("created_at", { ascending: false });
          break;
        case "owned":
          // TODO: sort by purchase time?
          break;
        default:
          break;
      }

      const { data } = await query;
      if (data) {
        setItems(data)
      } else {
        setItems([]);
      }
    }
    fetchData();
  }, [mode, page, sortMode, filters, searchText]);

  return (
    <div
      className={styles.ui}
      style={{
        width: "700px",
        height: "600px",
        color: "#CCCCCC",
        background: "rgba(50, 50, 50, 0.7)",
        overflow: "auto",
        position: "relative"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className={[styles.topRight, styles.unselectable].join(" ")}
        onClick={() => {
          setEditorModeHandler("Entities");
          setTargetListingHandler(undefined);
        }}
      >
        x
      </button>

      {selectedListingData ?
        <>
          <button
            className={[styles.topLeft, styles.unselectable].join(" ")}
            onClick={() => setSelectedListing(undefined)}
          >
            {"<"}
          </button>
          <h1>{selectedListingData.name}</h1>
          {(selectedVersionData?.name && selectedVersionData.name !== selectedListingData.name) ?
            <h2>{selectedVersionData.name}</h2>
            : <></>
          }
          {selectedListingData.tags?.length > 0 ?
            <p>Tags: {selectedListingData.tags}</p>
            : <></>
          }
          {selectedListingData.versions?.length > 1 ?
            <>
              <label className={styles.unselectable} htmlFor="version">Version: </label>
              <select
                id="version"
                defaultValue={selectedVersion}
                onChange={(e) => setSelectedVersion(e.target.value) }
              >
                {selectedListingData.versions.map((version, index) => {
                  return (
                    <option
                      key={version}
                      className={styles.unselectable} value={version}
                    >
                      v{index + 1}
                    </option>
                  )
                })}
              </select>
            </>
            : <></>
          }
          <br />
          {selectedVersionData?.description?.length > 0 ?
            <div
              style={{
                height: "300px",
                overflow: "auto"
              }}
            >
              <ReactMarkdown>{selectedVersionData.description}</ReactMarkdown>
            </div>
            : <></>
          }
          {ownedItems?.find((item) => { return item === selectedVersion} ) ?
            <button
              disabled
            >
              Owned!
            </button>
            : <button
              onClick={() => { purchaseItem(selectedListing, selectedVersion); }}
            >
              {/* TODO: add paid  */}
              {ownedListings.find((listing) => { return listing === selectedListing} ) ?
                "Switch Versions" : "Purchase: " + "Free"}
            </button>
          }
        </> :
        <>
          <p className={styles.unselectable}>{mode}</p>
          <label className={styles.unselectable} htmlFor="sort">Sort By: </label>
          <select
            id="sort"
            defaultValue={startingSortMode ?? sortingOptions[0]}
            onChange={(e) => setSortMode(e.target.value) }
          >
            {sortingOptions.map((option) => {
              return (
                <option
                  key={option}
                  className={styles.unselectable}
                  value={option}
                >
                  {option}
                </option>
              )
            })}
          </select>
          <br />
          <br />
          <label style={{ paddingRight: "5px" }}>Filters:</label>
          {filterOptions.map((option) => {
            return (
              <label
                key={["label_", option].join(" ")}
              >
                <input
                  key={["input_", option].join(" ")}
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      let currFilters = [...filters]
                      currFilters.push(option);
                      setFilters(currFilters);
                    } else {
                      const index = filters.indexOf(option);
                      if (index !== -1) {
                        let currFilters = [...filters]
                        currFilters.splice(index, 1);
                        setFilters(currFilters);
                      }
                    }
                  }}
                ></input>
                <span
                  key={["span_", option].join(" ")}
                  className={styles.unselectable}
                  style={{
                    paddingRight: "5px"
                  }}
                >
                  {option}s
                </span>
              </label>
            )
          })}
          <br />
          <br />
          <label className={styles.unselectable} htmlFor="search">Search: </label>
          <ControlledInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <br />
          <br />
          <GridList
            selected={undefined}
            list={items.length > NUM_LISTINGS_PER_PAGE ? items.slice(0, NUM_LISTINGS_PER_PAGE) : items}
            numColumns={NUM_COLUMNS}
            onSelectHandler={(id) => { setSelectedListing(id); }}
            getGridItem={(item) => {
              let owned = ownedListings.indexOf(item.id) !== -1;
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {item.name}

                  <div
                    className={styles.bottomRight}
                  >
                    {owned ? "Owned" : "Free"}
                  </div>
                </div>
              )
            }}
            style={{
              height: "350px",
              overflow: "auto"
            }}
          />
          <PageControls
            hasMoreItems={items.length > NUM_LISTINGS_PER_PAGE}
            page={page}
            setPage={setPage}
          />
        </>
      }
    </div>
  )
}